import React from 'react';
import {Stack} from '@mui/material';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import { toast } from 'react-toastify';
import './App.css';
import {LETTER_CORRECT, LETTER_INCORRECT, LETTER_ELSEWHERE} from './Utils';

class Keyboard extends React.Component {

  getLetterRows = () =>
    <React.Fragment>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center">
        {this.getLetterButtons("QWERTYUIOP")}
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center">
        {this.getLetterButtons("ASDFGHJKL")}
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center">
        <EnterButton
          onClick={this.props.onEnterPressed}
          glowing={this.props.enterGlowing} />
        {this.getLetterButtons("ZXCVBNM")}
        <BackspaceButton onClick={this.props.onBackspacePressed} />
      </Stack>
    </React.Fragment>

  getLetterButtons = (letters) => [...letters].map((letter) =>
    <LetterButton
      key={`kb_${letter}`}
      onClick={() => this.props.onLetterPressed(letter)}
      letter={letter}
      letterStyleMap={this.props.letterStyleMap} />
    );

  onKeyDown = (event) => {
    if (toast.isActive('username-toast')) {
      return false
    }

    let key = event.key.toUpperCase()
    if (key.length === 1 && key >= 'A' && key <= 'Z') {
      this.props.onLetterPressed(key)
    } else if (event.code === 'Enter') {
      this.props.onEnterPressed()
    } else if (event.code === 'Backspace') {
      this.props.onBackspacePressed()
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown);
  }

  render() {
    return (
      <Stack direction="column" style={{width: "100%"}} >
        {this.getLetterRows()}
      </Stack>
    );
  }
}

function KeyboardButton(props) {

  const rippleRef = React.useRef(null);
  const onRippleStart = (e) => {
    rippleRef.current.start(e);
  };
  const onRippleStop = (e) => {
    rippleRef.current.stop(e);
  };

  let extraClassName = props.glowing ? " Keyboard-button-glowing" : ""

  return (
    <button
      onClick={props.onClick}
      onMouseDown={onRippleStart}
      onMouseUp={onRippleStop}
      className={`Keyboard-button${extraClassName}`}
      style={props.style}>
      {props.children}
      <TouchRipple ref={rippleRef} center={false} />
    </button>
  );
}

function LetterButton(props) {

  var backgroundColor = '#36333b'
  var textColor = '#E6E1E5'
  if (props.letterStyleMap) {
    let letterStyle = props.letterStyleMap.get(props.letter)
    switch (letterStyle) {
      case LETTER_CORRECT:
        backgroundColor = '#559e5d'
        textColor = '#1C1B1F'
        break;
      case LETTER_INCORRECT:
        backgroundColor = '#bd5951'
        textColor = '#1C1B1F'
        break;
      case LETTER_ELSEWHERE:
        backgroundColor = '#b3ae4b'
        textColor = '#1C1B1F'
        break;
      default:
        break;
    }
  }

  const style = {
    backgroundColor: backgroundColor,
    color: textColor,
    padding: '0px',
    width: '9vw',
    maxWidth: '44px',
  }
  return KeyboardButton({
    onClick: props.onClick,
    style: style,
    children: props.letter,
  });
}

function EnterButton(props) {
  const style = {
    backgroundColor: '#36333b',
    color: '#E6E1E5',
  }
  return KeyboardButton({
    onClick: props.onClick,
    style: style,
    children: 'ENTER',
    glowing: props.glowing,
  });
}

function BackspaceButton(props) {
  const style = {
    backgroundColor: '#36333b',
    color: '#E6E1E5',
    width: '12vw',
    maxWidth: '60px',
  }
  return KeyboardButton({
    onClick: props.onClick,
    style: style,
    children: <BackspaceOutlinedIcon />,
  });
}

export default Keyboard;
