import React from 'react';
import {Stack, Typography, TextField, Button} from '@mui/material';
import { toast } from 'react-toastify';
import * as StorageUtils from './StorageUtils';
import AppLogo from './AppLogo.js';

class UsernameSetup extends React.Component {

  savedUsername = StorageUtils.getUsername()

  onKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.saveUsername()
      event.preventDefault();
    }
  }

  saveUsername = () => {
    let username = document.getElementById('username-textfield').value
    StorageUtils.saveUsername(username)
    toast.dismiss()
  }

  render() {
    let action = this.savedUsername.length > 0 ? "Update" : "Enter"
    let message = `${action} your username below, which will only be used for clarification when sharing links and results.`

    return (
      <div className="Container-grow">

        <AppLogo text="Username" />

        <span style={{ height: 16}} />

        <Typography
          style={{ fontSize: 18 }}
          component="div">
          {message}
        </Typography>

        <span style={{ height: 32}} />

        <Stack direction="row">
          <TextField
            id="username-textfield"
            label="Username"
            variant="outlined"
            defaultValue={this.savedUsername}
            onKeyPress={this.onKeyPress}
            inputProps={{ autocomplete: 'off'}}
            autoFocus />

          <span style={{ width: 16}} />

          <Button
            onClick={this.saveUsername}
            variant="contained">
            SAVE
          </Button>
        </Stack>

        <span style={{ height: 32}} />
      </div>
    );
  }
}

export default UsernameSetup;