import React from 'react';
import {AppBar, Toolbar, Button, IconButton, Typography} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShuffleOutlinedIcon from '@mui/icons-material/ShuffleOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { toast } from 'react-toastify';
import { getToastContainer, calculateLetterTileSize, truncateString, ALLOWED_WORD_LENGTH, ALLOWED_GUESSES, LETTER_CORRECT, LETTER_INCORRECT, LETTER_ELSEWHERE } from './Utils.js';
import LetterTile from './LetterTile.js';
import Keyboard from './Keyboard.js';
import Settings from './Settings.js';
import Instructions from './Instructions.js';
import {isValidWord, getRandomWordButtons} from './WordList.js';
import * as StorageUtils from './StorageUtils';
import AppLogo from './AppLogo.js';

const STATUS_PLAYING = 0
const STATUS_WON = 1
const STATUS_LOST = 2

class Game extends React.Component {

  state = {
    guesses: [],
    guessesKey: [],
    guessesKeyMap: new Map(),
    currentGuess: "",
    invalidGuess: false,
    status: STATUS_PLAYING,
    repeatedLetterHintEnabled: StorageUtils.isRepeatedLetterHintEnabled(),
    windowWidth: window.innerWidth,
  };

  clearState = () => {
    this.setState({
      guesses: [],
      guessesKey: [],
      guessesKeyMap: new Map(),
      currentGuess: "",
      status: STATUS_PLAYING,
    });
  }

  handleCancel = () => {
    this.clearState()
    this.props.onCancel()
  }

  handleShare = () => {
    let shareLink = window.location.href;

    if (navigator.share) {
      navigator.share({
        title: 'WordMee',
        url: shareLink,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => {
          console.log('Error sharing', error)
        });
    } else {
      this.fallbackShare(shareLink)
    }
  }

  fallbackShare = (shareLink) => {
    navigator.clipboard.writeText(shareLink);
    this.showCopyClipboardToast()
  }

  handleInfo = () => {
    toast.dismiss()
    toast.info(<Instructions />, {
      toastId: "instructions-toast",
      position: "bottom-center",
      autoClose: false,
      icon: false,
    })
  }

  handleSettings = () => {
    toast.dismiss()
    toast.info(
      <Settings
        onRepeatedLetterHintChange={this.onRepeatedLetterHintChange} />, {
      toastId: "settings-toast",
      position: "bottom-center",
      autoClose: false,
      icon: false,
    })
  }

  shareResults = () => {
    let numGuesses = this.state.guesses.length
    let key = this.props.creatorName ?
      this.props.creatorName :
      truncateString(encodeURIComponent(this.props.encodedSecretWord), 5)
    let title = `WordMee: ${key} (${numGuesses}/${ALLOWED_GUESSES})`
    let results = this.state.guessesKey.join("\n")
    results = results.replaceAll(LETTER_CORRECT, '\u{1F7E2}')
    results = results.replaceAll(LETTER_INCORRECT, '\u{26AA}')
    results = results.replaceAll(LETTER_ELSEWHERE, '\u{1F7E1}')

    if (navigator.share) {
      navigator.share({
        title: title,
        text: title + "\n\n" + results,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => {
          console.log('Error sharing', error)
        });
    } else {
      this.fallbackShareResults(title, results)
    }
  }

  fallbackShareResults = (title, results) => {
    navigator.clipboard.writeText(title + "\n\n" + results)
    this.showCopyClipboardToast()
  }

  showCopyClipboardToast = () => {
    toast.success("Copied to clipboard!", {
      position: "top-center",
      autoClose: 1000,
    })
  }

  onRepeatedLetterHintChange = (enabled) => {
    this.setState({
      repeatedLetterHintEnabled: enabled
    })
  }

  onLetterPressed = (letter) => {
    if (this.state.status !== STATUS_PLAYING) {
      return
    }
    let currentGuess = this.state.currentGuess
    if (currentGuess.length < this.props.secretWord.length) {
      this.setState({
        currentGuess: currentGuess + letter,
        invalidGuess: false,
      })
    }
  }

  handleRandomGameClick = () => {
    toast.dismiss()
    toast.info(getRandomWordButtons(this.onRandomWord), {
      position: "bottom-center",
      autoClose: false,
      icon: false,
    })
  }

  onRandomWord = (randomWord) => {
    this.clearState()
    toast.dismiss()
    this.props.onRandomGameClicked(randomWord)
  }

  getNewGameButtons = () =>
    <React.Fragment>
      <Button
        onClick={this.handleCancel}
        startIcon={<CreateOutlinedIcon />}
        variant="outlined"
        size="large"
        style={{ padding: 12 }}
        fullWidth>
        MAKE YOUR OWN WORD
      </Button>

      <span style={{ height: 16}} />

      <Button
        onClick={this.handleRandomGameClick}
        startIcon={<ShuffleOutlinedIcon />}
        variant="outlined"
        size="large"
        style={{ padding: 12 }}
        fullWidth>
        PLAY A RANDOM WORD
      </Button>
    </React.Fragment>

  wonMessage = () => (
    <div className='Toast-message-container'>

      <AppLogo text="Victory!" />

      <span style={{ height: 16}} />

      <Button
        onClick={this.shareResults}
        startIcon={navigator.share ? <ShareOutlinedIcon /> : <ContentCopyOutlinedIcon />}
        variant="outlined"
        size="large"
        style={{ padding: 12 }}
        fullWidth>
        SHARE YOUR RESULTS
      </Button>

      <span style={{ height: 16}} />

      {this.getNewGameButtons()}
    </div>
  )

  // restart, play a random word, make your own word, exit
  lostMessage = () => (
    <div className='Toast-message-container'>

      <AppLogo text="Game over!" />

      <Typography
        id='game-over-message'
        onClick={this.handleGameOverClick}
        variant="h5"
        style={{ fontWeight: 600 }}
        component="div">
        Tap HERE to reveal the secret word.
      </Typography>

      <span style={{ height: 32}} />

      <Button
        onClick={this.restartGame}
        startIcon={<ReplayOutlinedIcon />}
        variant="outlined"
        size="large"
        style={{ padding: 12 }}
        fullWidth>
        RESTART GAME
      </Button>

      <span style={{ height: 16}} />

      {this.getNewGameButtons()}
    </div>
  )

  handleGameOverClick = () => {
    document.getElementById("game-over-message")
      .textContent = `The word was ${this.props.secretWord}!`
  }

  restartGame = () => {
    toast.dismiss()
    this.clearState()
  }

  onEnterPressed = () => {
    if (this.state.status !== STATUS_PLAYING) {
      return
    }
    this.processGuess(this.state.currentGuess, () => {
      // Update saved state after updating in-game state.
      let gameState = StorageUtils.getGameState()
      if (this.state.status === STATUS_PLAYING) {
        gameState[this.props.secretWord] = {
          guesses: this.state.guesses
        }
      } else {
        delete gameState[this.props.secretWord]
      }
      StorageUtils.saveGameState(gameState)

      // Update saved general and users statistics.
      var completeNumGuesses
      if (this.state.status === STATUS_LOST) {
        completeNumGuesses = -1
      }
      if (this.state.status === STATUS_WON) {
        completeNumGuesses = this.state.guesses.length
      }
      if (completeNumGuesses) {
        StorageUtils.saveGameStatsGeneral(completeNumGuesses)
        let creatorName = this.props.creatorName
        if (creatorName) {
          let numIndex = creatorName.lastIndexOf('#');
          if (numIndex > 0) {
            let creatorUsername = creatorName.substr(0, numIndex - 1)
            StorageUtils.saveGameStatsUsers(creatorUsername, completeNumGuesses)
          }
        }
      }
    })
  }

  processGuess = (guess, stateCallback) => {
    let secretWord = this.props.secretWord
    let isGuessValid = secretWord.length !== ALLOWED_WORD_LENGTH || isValidWord(guess)
    if (guess.length !== secretWord.length || !isGuessValid) {
      toast.error("Invalid word! Try again.", {
        position: "top-center",
        autoClose: 1000,
      })
      this.setState({ invalidGuess: true })
    } else {
      var status = this.state.status
      if (guess === secretWord) {
        status = STATUS_WON
        toast.success(this.wonMessage, {
          position: "bottom-center",
          autoClose: false,
          icon: false,
        })
      } else if (this.state.guesses.length >= ALLOWED_GUESSES - 1) {
        status = STATUS_LOST
        toast.error(this.lostMessage, {
          position: "bottom-center",
          autoClose: false,
          icon: false,
        })
      }

      var guessKey = guess
      var secretKey = secretWord
      let elsewhereIndexes = []
      let guessesKeyMap = this.state.guessesKeyMap

      for (var i = 0; i < guessKey.length; i++) {
        let guessLetter = guessKey[i]
        if (guessLetter === secretWord[i]) {
          guessKey = this.replaceAt(guessKey, i, LETTER_CORRECT)
          secretKey = this.replaceAt(secretKey, i, LETTER_CORRECT)
          guessesKeyMap.set(guessLetter, LETTER_CORRECT)
        } else if (!secretWord.includes(guessLetter)) {
          guessKey = this.replaceAt(guessKey, i, LETTER_INCORRECT)
          guessesKeyMap.set(guessLetter, LETTER_INCORRECT)
        } else {
          elsewhereIndexes.push(i)
        }
      };

      elsewhereIndexes.forEach((i) => {
        let guessLetter = guessKey[i]
        let secretIndex = secretKey.indexOf(guessLetter)
        if (secretIndex >= 0) {
          guessKey = this.replaceAt(guessKey, i, LETTER_ELSEWHERE)
          secretKey = this.replaceAt(secretKey, secretIndex, LETTER_ELSEWHERE)
          if (!guessesKeyMap.get(guessLetter)) {
            guessesKeyMap.set(guessLetter, LETTER_ELSEWHERE)
          }
        } else {
          guessKey = this.replaceAt(guessKey, i, LETTER_INCORRECT)
          if (!guessesKeyMap.get(guessLetter)) {
            guessesKeyMap.set(guessLetter, LETTER_INCORRECT)
          }
        }
      })

      this.setState({
        guesses: [...this.state.guesses, guess],
        guessesKey: [...this.state.guessesKey, guessKey],
        guessesKeyMap: guessesKeyMap,
        currentGuess: "",
        invalidGuess: false,
        status: status,
      }, stateCallback);
    }
  }

  replaceAt = (str, index, replacement) => {
      return str.substr(0, index) + replacement + str.substr(index + replacement.length);
  }

  onBackspacePressed = () => {
    if (this.state.status !== STATUS_PLAYING) {
      return
    }
    let currentGuess = this.state.currentGuess
    this.setState({
      currentGuess: currentGuess.substring(0, currentGuess.length - 1),
      invalidGuess: false,
    })
  }

  getLetterTileRow = (row) => {
    let numLetterTiles = this.props.secretWord.length
    let container = document.getElementById("letter-table-container")
    let containerHeight = container ? container.offsetHeight : null
    let tileSize = calculateLetterTileSize(this.state.windowWidth, containerHeight, numLetterTiles)
    return Array.from(Array(numLetterTiles)).map((_, column) =>
      <LetterTile
        key={`lt_${row}${column}`}
        row={row}
        column={column}
        size={tileSize}
        currentGuess={this.state.currentGuess}
        guesses={this.state.guesses}
        guessesKey={this.state.guessesKey}
        secretWord={this.props.secretWord}
        invalidGuess={this.state.invalidGuess}
        repeatedLetterHintEnabled={this.state.repeatedLetterHintEnabled} />);
  }

  getLetterTileRows = () =>
    Array.from(Array(ALLOWED_GUESSES)).map((_, row) =>
      <div key={`ltr_${row}`} className="Letter-row">{this.getLetterTileRow(row)}</div>);

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  }

  restoreGuess = (guesses, index) => {
    let guess = guesses[index]
    this.processGuess(guess, () => {
      let nextIndex = index + 1
      if (nextIndex < guesses.length) {
        this.restoreGuess(guesses, nextIndex)
      }
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    let thisGameState = StorageUtils.getGameState()[this.props.secretWord]
    if (thisGameState) {
      let guesses = thisGameState.guesses
      if (guesses && guesses.length !== 0) {
        // Recursively restore all guesses via state callbacks.
        this.restoreGuess(guesses, 0)
      }
    }

    // Force rerender to resize grid according to available height.
    this.forceUpdate()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    return (
      <React.Fragment>

        <AppBar position="fixed">
          <Toolbar style={{ justifyContent: 'center' }}>
            <IconButton
              style={{ marginRight: 'auto' }}
              onClick={this.handleCancel}
              size="large"
              edge="start"
              color="inherit"
              aria-label="cancel">
              <CloseOutlinedIcon />
            </IconButton>

            <Typography
              variant="h5"
              style={{ position: 'absolute', fontWeight: 600 }}
              component="div"
              sx={{
                flexGrow: 1,
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}>
              WordMee
            </Typography>

            <IconButton
              onClick={this.handleShare}
              size="large"
              edge="end"
              color="inherit"
              aria-label="share">
              {navigator.share ? <ShareOutlinedIcon /> : <ContentCopyOutlinedIcon />}
            </IconButton>

            <span style={{ width: 4}} />

            <IconButton
              onClick={this.handleInfo}
              size="large"
              edge="end"
              color="inherit"
              aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>

            <span style={{ width: 4}} />

            <IconButton
              onClick={this.handleSettings}
              size="large"
              edge="end"
              color="inherit"
              aria-label="settings">
              <SettingsOutlinedIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {/* Used for spacing. */}
        <Toolbar />

        {getToastContainer()}

        <div id="letter-table-container" className="Container-grow">
          <div className="Letter-table">
            {this.getLetterTileRows()}
          </div>
        </div>

        <Keyboard
          letterStyleMap={this.state.guessesKeyMap}
          onLetterPressed={this.onLetterPressed}
          onEnterPressed={this.onEnterPressed}
          onBackspacePressed={this.onBackspacePressed} />

        <span style={{ height: 8}} />
      </React.Fragment>
    );
  }
}

export default Game;
