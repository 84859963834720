import AppLogoSvg from './logo.svg';
import {Stack, Typography} from '@mui/material';

export default function AppLogo(props) {
  return (
    <Stack direction="column" alignItems="center">
      <img src={AppLogoSvg} alt="App Logo" style={{
        width: 72,
        height: 72,
      }} />

      <Typography
        variant="h5"
        style={{ fontWeight: 600 }}
        component="div">
        {props.text}
      </Typography>

      <span style={{ height: 16 }} />
    </Stack>
  );
}
