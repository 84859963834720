
const KEY_USERNAME = 'UsernameIdentifier'
const KEY_CREATED_GAME_COUNT = 'CreatedGameCount'
const KEY_GAME_STATE = 'GameState'
const KEY_GAME_STATS_GENERAL = 'GameStatsGeneral'
const KEY_GAME_STATS_USERS = 'GameStatsUsers'
const KEY_REPEATED_LETTER_HINT = 'RepeatedLetterHintEnabled'
const KEY_CUSTOM_WORD_LENGTH = 'CustomWordLengthEnabled'

export function getUsername() {
  return localStorage.getItem(KEY_USERNAME) || ""
}

export function saveUsername(username) {
  localStorage.setItem(KEY_USERNAME, username)
}

export function getCreatedGameCountOr1() {
  return parseInt(localStorage.getItem(KEY_CREATED_GAME_COUNT) || '1')
}

export function saveCreatedGameCount(count) {
  localStorage.setItem(KEY_CREATED_GAME_COUNT, count)
}

export function getGameState() {
  return JSON.parse(localStorage.getItem(KEY_GAME_STATE) || '{}')
}

export function saveGameState(state) {
  localStorage.setItem(KEY_GAME_STATE, JSON.stringify(state))
}

export function getGameStatsGeneral() {
  return JSON.parse(localStorage.getItem(KEY_GAME_STATS_GENERAL) || JSON.stringify(createDefaultGameStats()))
}

export function saveGameStatsGeneral(numGuesses) {
  let gameStatsGeneral = getGameStatsGeneral()
  updateStats(gameStatsGeneral, numGuesses)
  localStorage.setItem(KEY_GAME_STATS_GENERAL, JSON.stringify(gameStatsGeneral))
}

export function getGameStatsUsers() {
  return JSON.parse(localStorage.getItem(KEY_GAME_STATS_USERS) || '{}')
}

export function saveGameStatsUsers(username, numGuesses) {
  let gameStatsUsersAll = getGameStatsUsers()
  let gameStatsUser = gameStatsUsersAll[username] || createDefaultGameStats()
  updateStats(gameStatsUser, numGuesses)
  gameStatsUsersAll[username] = gameStatsUser
  localStorage.setItem(KEY_GAME_STATS_USERS, JSON.stringify(gameStatsUsersAll))
}

function createDefaultGameStats() {
  return {
    totalCount: 0,
    stumpCount: 0,
    oneGuessCount: 0,
    twoGuessCount: 0,
    threeGuessCount: 0,
    fourGuessCount: 0,
    fiveGuessCount: 0,
    sixGuessCount: 0,
  }
}

function updateStats(stats, numGuesses) {
  stats.totalCount += 1

  switch(numGuesses) {
    case -1:
      stats.stumpCount += 1
      break;
    case 1:
      stats.oneGuessCount += 1
      break;
    case 2:
      stats.twoGuessCount += 1
      break;
    case 3:
      stats.threeGuessCount += 1
      break;
    case 4:
      stats.fourGuessCount += 1
      break;
    case 5:
      stats.fiveGuessCount += 1
      break;
    case 6:
      stats.sixGuessCount += 1
      break;
    default:
      // Ignore...
  }
}

export function isRepeatedLetterHintEnabled() {
  return isBooleanEnabled(KEY_REPEATED_LETTER_HINT, 'true')
}

export function saveRepeatedLetterHintEnabled(enabled) {
  saveBooleanEnabled(KEY_REPEATED_LETTER_HINT, enabled)
}

export function isCustomWordLengthEnabled() {
  return isBooleanEnabled(KEY_CUSTOM_WORD_LENGTH, 'false')
}

export function saveCustomWordLengthEnabled(enabled) {
  saveBooleanEnabled(KEY_CUSTOM_WORD_LENGTH, enabled)
}

function isBooleanEnabled(key, defaultValue) {
  let enabled = localStorage.getItem(key) || defaultValue
  return enabled === 'true';
}

export function saveBooleanEnabled(key, enabled) {
  localStorage.setItem(key, enabled)
}
