import React from 'react';
import {Stack, AppBar, Toolbar, Button, IconButton, Typography } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShuffleOutlinedIcon from '@mui/icons-material/ShuffleOutlined';
import { toast } from 'react-toastify';
import { getToastContainer, calculateLetterTileSize, ALLOWED_WORD_LENGTH } from './Utils.js'
import Keyboard from './Keyboard.js';
import Settings from './Settings.js';
import {isValidWord, getRandomWordButtons} from './WordList.js';
import * as StorageUtils from './StorageUtils';
import AppLogo from './logo.svg';

class Setup extends React.Component {

  state = {
    secretWord: "",
    invalidWord: false,
    customWordLengthEnabled: StorageUtils.isCustomWordLengthEnabled(),
    windowWidth: window.innerWidth,
  };

  handleRandomGameClick = () => {
    toast.dismiss()
    toast.info(getRandomWordButtons(this.onRandomWord), {
      toastId: "random-game-toast",
      position: "bottom-center",
      autoClose: false,
      icon: false,
    })
  }

  onRandomWord = (randomWord) => {
    this.props.onSetupComplete(randomWord, false)
  }

  handleSettings = () => {
    toast.dismiss()
    toast.info(
      <Settings
        onCustomWordLengthChange={this.onCustomWordLengthChange} />, {
      toastId: "settings-toast",
      position: "bottom-center",
      autoClose: false,
      icon: false,
    })
  }

  onCustomWordLengthChange = (enabled) => {
    this.setState({
      customWordLengthEnabled: enabled,
      secretWord: "",
    })
  }

  onLetterPressed = (letter) => {
    let secretWord = this.state.secretWord
    if (secretWord.length < ALLOWED_WORD_LENGTH || this.state.customWordLengthEnabled) {
      this.setState({
        secretWord: secretWord + letter,
        invalidWord: false,
      })
    }
  }

  onEnterPressed = () => {
    let secretWord = this.state.secretWord
    var isSecretWordValid
    if (secretWord.length === ALLOWED_WORD_LENGTH) {
      isSecretWordValid = isValidWord(secretWord)
    } else if (this.state.customWordLengthEnabled) {
      isSecretWordValid = secretWord.length > 0
    } else {
      isSecretWordValid = false
    }
    if (isSecretWordValid) {
      this.setState({
        secretWord: "",
        invalidWord: false
      })
      this.props.onSetupComplete(secretWord, true)
    } else {
      toast.error("Invalid word! Try again.", {
        position: "top-center",
        autoClose: 1000,
      })
      this.setState({ invalidWord: true })
    }
  }

  onBackspacePressed = () => {
    let secretWord = this.state.secretWord
    this.setState({
      secretWord: secretWord.substring(0, secretWord.length - 1),
      invalidWord: false,
    })
  }

  getLetterTiles = (row) => {
    var numLetterTiles
    if (this.state.customWordLengthEnabled) {
      numLetterTiles = Math.max(this.state.secretWord.length, 1)
    } else {
      numLetterTiles = ALLOWED_WORD_LENGTH
    }
    let tileSize = calculateLetterTileSize(this.state.windowWidth, null, numLetterTiles)
    return Array.from(Array(numLetterTiles)).map((_, column) =>
      <LetterTile
        key={`slt_${column}`}
        size={tileSize}
        letter={this.state.secretWord[column]}
        invalidWord={this.state.invalidWord} />
    );
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    return (
      <React.Fragment>

        <AppBar position="fixed">
          <Toolbar style={{ justifyContent: 'center' }} disableGutters>
            <img src={AppLogo} alt="App Logo" style={{
              width: '56px',
              height: '56px',
              marginLeft: 4,
            }} />

            <Stack style={{ position: 'absolute', left: '48px', right: '48px' }}>
              <Typography
                variant="h5"
                style={{ fontWeight: 600 }}
                component="div">
                WordMee
              </Typography>

              <Typography
                style={{ fontWeight: 600, color: '#aaaaaa' }}
                component="div"
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                }}>
                Stump your friends!
              </Typography>
            </Stack>

            <IconButton
              onClick={this.handleSettings}
              size="large"
              edge="end"
              color="inherit"
              aria-label="settings"
              style={{ marginLeft: 'auto', marginRight: 4 }}>
              <SettingsOutlinedIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {getToastContainer()}

        <span style={{ height: 32}} />

        <div className="Container-grow">
          <h3 style={{ paddingLeft: 32, paddingRight: 32 }}>
            Type in your secret word and then press ENTER to share:
          </h3>

          <span style={{ height: 16}} />

          <div className="Letter-row">
            {this.getLetterTiles()}
          </div>

          <span style={{ height: 8}} />

          <h4>OR</h4>

          <span style={{ height: 8}} />

          <Button
            startIcon={<ShuffleOutlinedIcon />}
            onClick={this.handleRandomGameClick}
            variant="outlined"
            size="large">
            Play a random word
          </Button>
        </div>

        <Keyboard
          onLetterPressed={this.onLetterPressed}
          onEnterPressed={this.onEnterPressed}
          onBackspacePressed={this.onBackspacePressed}
          enterGlowing={
            this.state.customWordLengthEnabled ?
              this.state.secretWord.length > 0 :
              this.state.secretWord.length === ALLOWED_WORD_LENGTH
          } />

        <span style={{ height: 8}} />
      </React.Fragment>
    );
  }
}

class LetterTile extends React.Component {

  getLetterClass = (letter) => {
    return letter ? (this.props.invalidWord ? 'Letter-invalid' : 'Letter-guess') : 'Letter-empty';
  };

  render() {
    var letter = this.props.letter
    let size = this.props.size
    return (
      <span
        className={'Letter ' + this.getLetterClass(letter)}
        style={{width: size, height: size}}>
        {letter}
      </span>
    );
  }
}

export default Setup;
