import React from 'react';
import { Link } from 'react-router-dom'
import {Stack, Typography, List, ListItem, ListItemIcon, ListItemText, Switch} from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import { toast } from 'react-toastify';
import * as StorageUtils from './StorageUtils';
import AppLogo from './AppLogo.js';
import UsernameSetup from './UsernameSetup.js';
import Statistics from './Statistics.js';

class Settings extends React.Component {

  savedUsername = StorageUtils.getUsername()

  state = {
    repeatedLetterHintEnabled: StorageUtils.isRepeatedLetterHintEnabled(),
    customWordLengthEnabled: StorageUtils.isCustomWordLengthEnabled(),
  }

  handleUsernameClick = () => {
    toast.dismiss()
    toast.info(<UsernameSetup />, {
      toastId: "username-toast",
      position: "bottom-center",
      autoClose: false,
      icon: false,
    })
  }

  handleStatsClick = () => {
    toast.dismiss()
    toast.info(<Statistics />, {
      toastId: "stats-toast",
      position: "bottom-center",
      autoClose: false,
      icon: false,
    })
  }

  handleRepeatedLetterHintChange = (checked) => {
    StorageUtils.saveRepeatedLetterHintEnabled(checked)
    this.setState({
      repeatedLetterHintEnabled: checked
    });
    if (this.props.onRepeatedLetterHintChange) {
      this.props.onRepeatedLetterHintChange(checked)
    }
  }

  handleCustomWordLengthChange = (checked) => {
    StorageUtils.saveCustomWordLengthEnabled(checked)
    this.setState({
      customWordLengthEnabled: checked
    });
    if (this.props.onCustomWordLengthChange) {
      this.props.onCustomWordLengthChange(checked)
    }
  }

  render() {
    return (
      <Stack direction="column" alignItems="center">

        <AppLogo text="Settings" />

        <List>
          <ListItem button onClick={this.handleUsernameClick}>
            <ListItemIcon>
              <AccountCircleOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary={this.savedUsername ? `Username: ${this.savedUsername}` : "Tap to enter your username"}
              secondary="Your username is only used for clarification when sharing links and results." />
          </ListItem>

          <ListItem button onClick={this.handleStatsClick}>
            <ListItemIcon>
              <InsertChartOutlinedRoundedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Statistics"
              secondary="View your overall game statistics and statistics for games sent by your friends!" />
          </ListItem>

          <ListItem button onClick={() => this.handleRepeatedLetterHintChange(!this.state.repeatedLetterHintEnabled)}>
            <ListItemIcon>
              <LooksTwoOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Repeated letter hint"
              secondary="If enabled, you will get a hint when a letter is repeated in the word." />
            <Switch
              edge="end"
              onChange={(event) => this.handleRepeatedLetterHintChange(event.target.checked)}
              checked={this.state.repeatedLetterHintEnabled} />
          </ListItem>

          <ListItem button onClick={() => this.handleCustomWordLengthChange(!this.state.repeatedLetterHintEnabled)}>
            <ListItemIcon>
              <MoreHorizOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Custom word length (experimental)"
              secondary="If enabled, you will be able to enter and share a secret word of any length." />
            <Switch
              edge="end"
              onChange={(event) => this.handleCustomWordLengthChange(event.target.checked)}
              checked={this.state.customWordLengthEnabled} />
          </ListItem>

          <ListItem button onClick={() => window.open('mailto:support@wordmee.com')}>
            <ListItemIcon>
              <FeedbackOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Send feedback and report issues"
              secondary="support@wordmee.com" />
          </ListItem>
        </List>

        <span style={{ height: 32}} />

        <Link
          to="/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          color="inherit">
          <Typography
            style={{ fontSize: 16 }}
            component="div">
            Privacy Policy
          </Typography>
        </Link>

        <Typography
          style={{ fontSize: 16 }}
          component="div">
          © Copyright 2022, WordMee
        </Typography>
      </Stack>
    );
  }
}

export default Settings;