import React from 'react';
import {count, LETTER_CORRECT, LETTER_INCORRECT, LETTER_ELSEWHERE} from './Utils';

class LetterTile extends React.Component {

  getLetter = () => {
    if (this.props.row < this.props.guesses.length) {
      let guess = this.props.guesses[this.props.row]
      return guess[this.props.column]
    }
    return ""
  };

  getLetterClass = () => {
    if (this.props.row < this.props.guessesKey.length) {
      let guessKey = this.props.guessesKey[this.props.row]
      let guessKeyValue = guessKey[this.props.column]
      switch (guessKeyValue) {
        case LETTER_CORRECT:
          return 'Letter-correct'
        case LETTER_INCORRECT:
          return 'Letter-incorrect'
        case LETTER_ELSEWHERE:
          return 'Letter-elsewhere'
        default:
          throw new Error('Unkown letter type: ' + guessKeyValue);
      }
    } else {
      return 'Letter-empty'
    }
  };

  render() {
    let currentGuess = this.props.currentGuess
    var letter
    var letterClass
    var letterCount = 0
    var letterCountVisibility = 'hidden'

    if (this.props.sample) {
      letter = this.props.sample.letter
      letterClass = this.props.sample.letterClass
    } else if (this.props.column < currentGuess.length && this.props.row === this.props.guesses.length) {
      letter = currentGuess[this.props.column]
      letterClass = this.props.invalidGuess ? 'Letter-invalid' : 'Letter-guess'
    } else {
      letter = this.getLetter()
      letterClass = this.getLetterClass(letter)
      letterCount = count(this.props.secretWord, letter)
      letterCountVisibility =
        (this.props.repeatedLetterHintEnabled && letterCount > 1) ? 'visible' : 'hidden'
    }

    let size = this.props.size

    return (
      <div style={{position: 'relative'}}>
        <span
          className={'Letter ' + letterClass}
          style={{width: size, height: size}}>
          {letter}
        </span>

        <span
          className='Letter-count-hint'
          style={{visibility: letterCountVisibility}}>
          {letterCount}
        </span>
      </div>
    );
  }
}

export default LetterTile;