import React from 'react';
import {Stack, Typography} from '@mui/material';
import * as StorageUtils from './StorageUtils';
import AppLogo from './AppLogo.js';

class Statistics extends React.Component {

  gameStatsGeneral = StorageUtils.getGameStatsGeneral()
  gameStatsUsers = StorageUtils.getGameStatsUsers()

  getStatsDisplayUsers = () => {
    return Object.entries(this.gameStatsUsers)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map((it) => this.getStatsDisplay(it[0], it[1]))
  }

  getStatsDisplay = (title, stats) => {
    return (
      <Stack direction="column" key={title}>
        <Typography
          variant="h5"
          component="div"
          style={{ textDecoration: "underline" }}>
          {title}
        </Typography>

        <span style={{ height: 4}} />

        <Typography
          variant="h5"
          component="div">
          {`📈 Total games: ${stats.totalCount}`}
        </Typography>

        <Typography
          variant="h5"
          component="div">
          {`🏆 Wins: ${stats.totalCount - stats.stumpCount}`}
        </Typography>

        <Typography
          variant="h5"
          component="div">
          {`😡 Stumps: ${stats.stumpCount}`}
        </Typography>

        <Typography
          variant="h5"
          component="div">
          {`🤔 Guesses:`}
        </Typography>

        <span style={{ height: 4}} />

        <table>
          <tbody>
            <tr>
              <th>One</th>
              <th>Two</th>
              <th>Three</th>
              <th>Four</th>
              <th>Five</th>
              <th>Six</th>
            </tr>
            <tr>
              <td>{stats.oneGuessCount}</td>
              <td>{stats.twoGuessCount}</td>
              <td>{stats.threeGuessCount}</td>
              <td>{stats.fourGuessCount}</td>
              <td>{stats.fiveGuessCount}</td>
              <td>{stats.sixGuessCount}</td>
            </tr>
          </tbody>
        </table>

        <span style={{ height: 16}} />
      </Stack>
    );
  }

  render() {
    return (
      <div className="Container-grow">

        <AppLogo text="Statistics" />

        <Stack direction="column" style={{width: "100%", textAlign: "start"}}>

          {this.getStatsDisplay("Overall", this.gameStatsGeneral)}

          {this.getStatsDisplayUsers()}

        </Stack>
      </div>
    );
  }
}

export default Statistics;