import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const LETTER_CORRECT = '0'
export const LETTER_INCORRECT = '1'
export const LETTER_ELSEWHERE = '2'

export const ALLOWED_WORD_LENGTH = 5
export const ALLOWED_GUESSES = 6

export function getToastContainer() {
  const toastStyle = {
    backgroundColor: "#272727",
    maxHeight: '75vh',
    overflowY: 'auto',
  }
  return (
    <ToastContainer
      theme="dark"
      toastStyle={toastStyle}
      closeOnClick={false}
      hideProgressBar={true}
      draggable={false} />
  )
}

export function count(str, stringsearch) {
  var count = 0
  for(var i=0; i < str.length; i++) {
    if (stringsearch === str[i]) {
      count ++
    }
  }
  return count
}

export function truncateString(str, n) {
  if (str.length > n) {
    return str.substring(0, n) + "…";
  } else {
    return str;
  }
}

export function calculateLetterTileSize(windowWidth, containerHeight, numLetterTiles) {
  // Subtract extra space due to margins and border
  let marginAndBorderSize = 8
  let preferredWidth = (windowWidth - marginAndBorderSize * numLetterTiles) / numLetterTiles
  if (containerHeight) {
    let preferredHeight = (containerHeight - marginAndBorderSize * ALLOWED_GUESSES) / ALLOWED_GUESSES
    return Math.min(preferredWidth, preferredHeight)
  } else {
    return preferredWidth
  }
}

export function encodeSecretWord(secretWord) {
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let charactersLength = characters.length

  let secretWordBase64 = btoa(secretWord)

  var result = ''
  for (var i = 0; i < secretWordBase64.length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    result += secretWordBase64.charAt(i)
  }
  return result
}

export function decodeSecretWord(secretWord, legacy) {
  if (legacy) {
    return atob(secretWord)
  }
  var secretWordBase64 = ''
  for (var i = 1; i < secretWord.length; i+=2) {
    secretWordBase64 += secretWord.charAt(i)
  }
  return atob(secretWordBase64)
}
