import React from 'react';
import { Routes, Route, useParams, useSearchParams, useNavigate } from "react-router-dom";
import {ThemeProvider} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import green from '@mui/material/colors/green';
import './App.css';
import { encodeSecretWord, decodeSecretWord } from './Utils.js';
import * as StorageUtils from './StorageUtils';
import Setup from './Setup.js';
import Game from './Game.js';
import PrivacyPolicy from './PrivacyPolicy.js';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: green,
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route exact path="/" element={<SetupRoute />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyRoute />} />
            <Route path="/g/:secretWord" element={<GameRouteNew />} />
            <Route path="/:secretWord" element={<GameRouteLegacy />} />
          </Routes>
        </header>
      </div>
    </ThemeProvider>
  );
}

function onSetupComplete(secretWord, custom, navigate) {
  var urlPath = "/g/" + encodeURIComponent(encodeSecretWord(secretWord))
  if (custom) {
    let savedUsername = StorageUtils.getUsername()
    var shareText = ""
    if (savedUsername) {
      let gameCount = StorageUtils.getCreatedGameCountOr1()
      let nameParam = `${savedUsername} #${gameCount}`
      shareText += `WordMee: ${nameParam}\n`
      const params = new URLSearchParams({
        name: nameParam,
      });
      urlPath += "?" + params.toString()
      StorageUtils.saveCreatedGameCount(gameCount + 1)
    }

    if (navigator.share) {
      shareText += window.location.origin + urlPath
      navigator.share({
        title: 'WordMee',
        text: shareText,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => {
          console.log('Error sharing', error)
        });
    }
  }
  navigate(urlPath)
};

function cancelGame(navigate) {
  navigate("/")
}

function SetupRoute() {
  let navigate = useNavigate()
  return (
    <Setup
      onSetupComplete={(secretWord, custom) => onSetupComplete(secretWord, custom, navigate)} />
  )
}

function GameRouteLegacy() {
  return GameRoute(true)
}

function GameRouteNew() {
  return GameRoute(false)
}

function GameRoute(legacy) {
  let navigate = useNavigate()

  let { secretWord } = useParams()
  let decodedSecretWord = decodeSecretWord(secretWord, legacy).toUpperCase()

  let [searchParams] = useSearchParams();
  const creatorName = searchParams.get('name')

  return (
    <Game
      secretWord={decodedSecretWord}
      encodedSecretWord={secretWord}
      creatorName={creatorName}
      onCancel={() => cancelGame(navigate)}
      onRandomGameClicked={(randomWord) => onSetupComplete(randomWord, false, navigate)} />
)}

function PrivacyPolicyRoute() {
  return <PrivacyPolicy />
}