import {Stack, Button} from '@mui/material';
import AppLogo from './AppLogo.js'

export const DIFFICULTY_ANY = Number.MAX_SAFE_INTEGER
export const DIFFICULTY_EASY = 2000
export const DIFFICULTY_MEDIUM = 200
export const DIFFICULTY_HARD = 1
export const DIFFICULTY_VERY_HARD = 0

const DIFFICULTY_MAX_MAP = new Map()
DIFFICULTY_MAX_MAP.set(DIFFICULTY_ANY, DIFFICULTY_ANY)
DIFFICULTY_MAX_MAP.set(DIFFICULTY_EASY, DIFFICULTY_ANY)
DIFFICULTY_MAX_MAP.set(DIFFICULTY_MEDIUM, DIFFICULTY_EASY)
DIFFICULTY_MAX_MAP.set(DIFFICULTY_HARD, DIFFICULTY_MEDIUM)
DIFFICULTY_MAX_MAP.set(DIFFICULTY_VERY_HARD, DIFFICULTY_HARD)

export const isValidWord = (word) => {
  return WORD_MAP.has(word.toLowerCase())
}

export const getRandomWord = (difficulty) => {
  var minFreq = difficulty === DIFFICULTY_ANY ? 0 : difficulty
  var maxFreq = DIFFICULTY_MAX_MAP.get(difficulty)

  let words = WORD_LIST.filter(
    ([word, freq]) => freq >= minFreq && freq < maxFreq
  );

  var index = Math.floor(Math.random() * words.length)
  return words[index][0].toUpperCase()
}

export const getRandomWordButtons = (onRandomWord) => (
  <div className='Toast-message-container'>
    <AppLogo text="Select difficulty" />

    <span style={{ height: 16}} />

    <Button
      onClick={() => onRandomWord(getRandomWord(DIFFICULTY_ANY))}
      variant="outlined"
      size="large">
      ANY
    </Button>

    <span style={{ height: 16}} />

    <Stack direction="row" spacing={1.5}>
      <Button
        onClick={() => onRandomWord(getRandomWord(DIFFICULTY_EASY))}
        variant="outlined"
        size="large">
        EASY
      </Button>
      <Button
        onClick={() => onRandomWord(getRandomWord(DIFFICULTY_MEDIUM))}
        variant="outlined"
        size="large">
        MEDIUM
      </Button>
    </Stack>

    <span style={{ height: 16}} />

    <Stack direction="row" spacing={1.5}>
      <Button
        onClick={() => onRandomWord(getRandomWord(DIFFICULTY_HARD))}
        variant="outlined"
        size="large">
        HARD
      </Button>
      <Button
        onClick={() => onRandomWord(getRandomWord(DIFFICULTY_VERY_HARD))}
        variant="outlined"
        size="large">
        VERY HARD
      </Button>
    </Stack>
  </div>
)

const WORD_LIST = [
  ['aahed', 0],
  ['aalii', 4],
  ['aargh', 12],
  ['abaca', 48],
  ['abaci', 28],
  ['aback', 29],
  ['abaft', 20],
  ['abaka', 13],
  ['abamp', 0],
  ['abase', 3],
  ['abash', 4],
  ['abate', 317],
  ['abaya', 143],
  ['abbas', 3965],
  ['abbes', 82],
  ['abbey', 18762],
  ['abbot', 5214],
  ['abeam', 36],
  ['abele', 163],
  ['abets', 12],
  ['abhor', 17],
  ['abide', 419],
  ['abled', 40],
  ['abler', 24],
  ['ables', 30],
  ['abmho', 3],
  ['abode', 676],
  ['abohm', 10],
  ['aboil', 0],
  ['aboma', 3],
  ['aboon', 6],
  ['abort', 285],
  ['about', 336251],
  ['above', 54332],
  ['abris', 14],
  ['abuse', 9054],
  ['abuts', 375],
  ['abuzz', 6],
  ['abyes', 0],
  ['abysm', 0],
  ['abyss', 733],
  ['acari', 88],
  ['acerb', 0],
  ['aceta', 0],
  ['ached', 2],
  ['aches', 90],
  ['achoo', 3],
  ['acids', 4610],
  ['acidy', 2],
  ['acing', 4],
  ['acini', 21],
  ['ackee', 30],
  ['acmes', 0],
  ['acmic', 0],
  ['acned', 2],
  ['acnes', 29],
  ['acock', 6],
  ['acold', 0],
  ['acorn', 1185],
  ['acred', 2],
  ['acres', 32972],
  ['acrid', 70],
  ['acted', 12228],
  ['actin', 633],
  ['actor', 111855],
  ['acute', 4507],
  ['acyls', 0],
  ['adage', 149],
  ['adapt', 1876],
  ['addax', 60],
  ['added', 57981],
  ['adder', 433],
  ['addle', 22],
  ['adeem', 13],
  ['adept', 642],
  ['adieu', 192],
  ['adios', 214],
  ['adits', 41],
  ['adman', 14],
  ['admen', 4],
  ['admit', 1069],
  ['admix', 0],
  ['adobe', 2776],
  ['adobo', 40],
  ['adopt', 2889],
  ['adore', 186],
  ['adorn', 305],
  ['adown', 0],
  ['adoze', 0],
  ['adult', 27412],
  ['adunc', 0],
  ['adust', 4],
  ['adyta', 4],
  ['adzed', 4],
  ['adzes', 32],
  ['aecia', 11],
  ['aedes', 502],
  ['aegis', 1087],
  ['aeons', 61],
  ['aerie', 48],
  ['afars', 59],
  ['affix', 192],
  ['afire', 98],
  ['afoot', 70],
  ['afore', 24],
  ['afoul', 128],
  ['afrit', 6],
  ['after', 694687],
  ['again', 79056],
  ['agama', 316],
  ['agape', 204],
  ['agars', 12],
  ['agate', 386],
  ['agave', 562],
  ['agaze', 0],
  ['agene', 6],
  ['agent', 29260],
  ['agers', 21],
  ['agger', 112],
  ['aggie', 379],
  ['aggro', 60],
  ['aghas', 11],
  ['agile', 1071],
  ['aging', 3342],
  ['agios', 1243],
  ['agism', 2],
  ['agist', 4],
  ['agita', 4],
  ['aglee', 0],
  ['aglet', 8],
  ['agley', 3],
  ['aglow', 27],
  ['agmas', 0],
  ['agone', 19],
  ['agons', 0],
  ['agony', 582],
  ['agora', 501],
  ['agree', 3204],
  ['agria', 43],
  ['agues', 6],
  ['ahead', 12570],
  ['ahing', 4],
  ['ahold', 72],
  ['ahull', 3],
  ['aided', 3368],
  ['aider', 8],
  ['aides', 389],
  ['ailed', 7],
  ['aimed', 11068],
  ['aimer', 96],
  ['aioli', 14],
  ['aired', 49122],
  ['airer', 8],
  ['airns', 0],
  ['airth', 54],
  ['airts', 5],
  ['aisle', 753],
  ['aitch', 46],
  ['aiver', 0],
  ['ajiva', 15],
  ['ajuga', 109],
  ['akees', 0],
  ['akela', 57],
  ['akene', 4],
  ['alack', 6],
  ['alamo', 1282],
  ['aland', 271],
  ['alane', 32],
  ['alang', 101],
  ['alans', 168],
  ['alant', 2],
  ['alarm', 1933],
  ['alary', 18],
  ['alate', 17],
  ['albas', 31],
  ['album', 539086],
  ['alcid', 17],
  ['alder', 1238],
  ['aldol', 133],
  ['alecs', 2],
  ['alefs', 0],
  ['aleph', 379],
  ['alert', 2340],
  ['alfas', 5],
  ['algae', 3633],
  ['algal', 400],
  ['algas', 9],
  ['algid', 0],
  ['algin', 4],
  ['algor', 9],
  ['algum', 4],
  ['alias', 4263],
  ['alibi', 344],
  ['alien', 6051],
  ['alifs', 0],
  ['align', 807],
  ['alike', 2684],
  ['aline', 538],
  ['alist', 3],
  ['alive', 8238],
  ['aliya', 172],
  ['alkie', 0],
  ['alkyd', 25],
  ['alkyl', 581],
  ['allay', 40],
  ['allee', 237],
  ['alley', 3678],
  ['allod', 17],
  ['allot', 65],
  ['allow', 22387],
  ['alloy', 2053],
  ['allyl', 181],
  ['almah', 32],
  ['almas', 344],
  ['almeh', 20],
  ['almes', 0],
  ['almud', 6],
  ['almug', 2],
  ['aloes', 61],
  ['aloft', 288],
  ['aloha', 836],
  ['aloin', 4],
  ['alone', 13661],
  ['along', 175769],
  ['aloof', 116],
  ['aloud', 821],
  ['alpha', 11462],
  ['altar', 3567],
  ['alter', 3202],
  ['altho', 3],
  ['altos', 619],
  ['alula', 70],
  ['alums', 50],
  ['alway', 35],
  ['amahs', 0],
  ['amain', 8],
  ['amass', 204],
  ['amaze', 96],
  ['amber', 3487],
  ['ambit', 162],
  ['amble', 94],
  ['ambos', 37],
  ['ambry', 10],
  ['ameba', 20],
  ['ameer', 281],
  ['amend', 951],
  ['amens', 2],
  ['ament', 110],
  ['amias', 15],
  ['amice', 39],
  ['amici', 257],
  ['amide', 419],
  ['amido', 16],
  ['amids', 0],
  ['amies', 50],
  ['amiga', 2248],
  ['amigo', 364],
  ['amine', 982],
  ['amino', 4101],
  ['amins', 0],
  ['amirs', 69],
  ['amiss', 47],
  ['amity', 596],
  ['ammos', 47],
  ['amnia', 5],
  ['amnic', 0],
  ['amnio', 2],
  ['amoks', 0],
  ['amole', 35],
  ['among', 131695],
  ['amort', 11],
  ['amour', 615],
  ['amped', 61],
  ['ample', 815],
  ['amply', 71],
  ['ampul', 0],
  ['amuck', 26],
  ['amuse', 179],
  ['amyls', 0],
  ['ancho', 40],
  ['ancon', 84],
  ['andro', 79],
  ['anear', 3],
  ['anele', 4],
  ['anent', 15],
  ['angas', 207],
  ['angel', 13433],
  ['anger', 2094],
  ['angle', 8409],
  ['anglo', 1071],
  ['angry', 2505],
  ['angst', 385],
  ['anile', 5],
  ['anils', 0],
  ['anima', 383],
  ['anime', 16186],
  ['animi', 35],
  ['anion', 992],
  ['anise', 255],
  ['ankhs', 0],
  ['ankle', 1296],
  ['ankus', 2],
  ['anlas', 0],
  ['annal', 65],
  ['annas', 95],
  ['annex', 2566],
  ['annoy', 63],
  ['annul', 76],
  ['anoas', 0],
  ['anode', 342],
  ['anole', 340],
  ['anomy', 5],
  ['ansae', 9],
  ['antae', 20],
  ['antas', 115],
  ['anted', 5],
  ['antes', 219],
  ['antic', 127],
  ['antis', 74],
  ['antra', 17],
  ['antre', 0],
  ['antsy', 8],
  ['anvil', 600],
  ['anyon', 23],
  ['aorta', 431],
  ['apace', 34],
  ['apart', 13467],
  ['apeak', 0],
  ['apeek', 0],
  ['apers', 8],
  ['apery', 2],
  ['aphid', 499],
  ['aphis', 99],
  ['apian', 21],
  ['aping', 10],
  ['apish', 0],
  ['apnea', 323],
  ['apods', 0],
  ['aport', 2],
  ['appal', 6],
  ['appel', 615],
  ['apple', 13541],
  ['apply', 7438],
  ['apres', 39],
  ['apron', 477],
  ['apses', 107],
  ['apsis', 12],
  ['apter', 63],
  ['aptly', 313],
  ['aquae', 137],
  ['aquas', 21],
  ['araks', 46],
  ['arame', 49],
  ['arbor', 2611],
  ['arced', 15],
  ['arcus', 179],
  ['ardeb', 0],
  ['ardor', 59],
  ['areae', 9],
  ['areal', 208],
  ['areas', 88946],
  ['areca', 162],
  ['areic', 0],
  ['arena', 33381],
  ['arene', 157],
  ['arepa', 16],
  ['arete', 105],
  ['argal', 17],
  ['argil', 3],
  ['argle', 0],
  ['argol', 16],
  ['argon', 363],
  ['argot', 72],
  ['argue', 2687],
  ['argus', 1377],
  ['arhat', 62],
  ['arias', 1682],
  ['ariel', 2683],
  ['arils', 25],
  ['arise', 3268],
  ['arles', 729],
  ['armed', 25769],
  ['armer', 39],
  ['armet', 34],
  ['armor', 2556],
  ['aroid', 29],
  ['aroma', 774],
  ['arose', 3952],
  ['arpen', 0],
  ['arras', 709],
  ['array', 7298],
  ['arris', 56],
  ['arrow', 4480],
  ['arses', 24],
  ['arsis', 38],
  ['arson', 1109],
  ['artal', 28],
  ['artel', 64],
  ['artsy', 40],
  ['arums', 6],
  ['arval', 59],
  ['arvos', 0],
  ['aryls', 0],
  ['asana', 188],
  ['ascot', 1174],
  ['ascus', 54],
  ['asdic', 26],
  ['ashed', 2],
  ['ashen', 103],
  ['ashes', 3585],
  ['aside', 6224],
  ['asked', 12899],
  ['asker', 537],
  ['askew', 393],
  ['askoi', 4],
  ['askos', 17],
  ['aspen', 2110],
  ['asper', 305],
  ['aspic', 60],
  ['aspis', 97],
  ['assai', 71],
  ['assay', 927],
  ['asses', 117],
  ['asset', 5292],
  ['aster', 1803],
  ['astir', 29],
  ['asyla', 6],
  ['ataps', 0],
  ['ataxy', 0],
  ['atilt', 0],
  ['atlas', 6231],
  ['atman', 203],
  ['atmas', 2],
  ['atoll', 3385],
  ['atoms', 4468],
  ['atomy', 6],
  ['atone', 82],
  ['atony', 15],
  ['atopy', 28],
  ['atria', 262],
  ['atrip', 5],
  ['attar', 170],
  ['attic', 1865],
  ['audad', 0],
  ['audio', 17897],
  ['audit', 2550],
  ['auger', 929],
  ['aught', 23],
  ['augur', 118],
  ['aulic', 32],
  ['aunts', 278],
  ['aunty', 161],
  ['aurae', 7],
  ['aural', 302],
  ['aurar', 0],
  ['auras', 29],
  ['aurei', 8],
  ['aures', 36],
  ['auric', 83],
  ['auris', 53],
  ['aurum', 94],
  ['autos', 152],
  ['auxin', 76],
  ['avail', 377],
  ['avant', 1001],
  ['avast', 79],
  ['avens', 78],
  ['avers', 57],
  ['avert', 233],
  ['avgas', 41],
  ['avian', 893],
  ['avion', 159],
  ['aviso', 168],
  ['avoid', 11066],
  ['avows', 3],
  ['await', 327],
  ['awake', 1014],
  ['award', 189544],
  ['aware', 3165],
  ['awash', 226],
  ['awful', 470],
  ['awing', 9],
  ['awned', 24],
  ['awoke', 94],
  ['awols', 0],
  ['axels', 3],
  ['axial', 819],
  ['axile', 6],
  ['axils', 481],
  ['axing', 50],
  ['axiom', 1054],
  ['axion', 93],
  ['axite', 0],
  ['axled', 3],
  ['axles', 812],
  ['axman', 21],
  ['axmen', 0],
  ['axone', 6],
  ['axons', 354],
  ['ayahs', 8],
  ['ayins', 0],
  ['azans', 0],
  ['azide', 161],
  ['azido', 5],
  ['azine', 24],
  ['azlon', 4],
  ['azoic', 21],
  ['azole', 18],
  ['azons', 0],
  ['azote', 13],
  ['azoth', 17],
  ['azuki', 80],
  ['azure', 1020],
  ['baaed', 0],
  ['baals', 13],
  ['babas', 31],
  ['babel', 581],
  ['babes', 527],
  ['babka', 59],
  ['baboo', 68],
  ['babul', 136],
  ['babus', 9],
  ['bacca', 29],
  ['backs', 1802],
  ['bacon', 3476],
  ['baddy', 7],
  ['badge', 3573],
  ['badly', 3186],
  ['baffs', 0],
  ['baffy', 3],
  ['bagel', 220],
  ['baggy', 154],
  ['bahts', 2],
  ['bails', 57],
  ['bairn', 18],
  ['baith', 11],
  ['baits', 87],
  ['baiza', 32],
  ['baize', 35],
  ['baked', 1589],
  ['baker', 15406],
  ['bakes', 53],
  ['balas', 87],
  ['balds', 36],
  ['baldy', 444],
  ['baled', 22],
  ['baler', 103],
  ['bales', 444],
  ['balks', 53],
  ['balky', 3],
  ['balls', 5167],
  ['bally', 409],
  ['balms', 13],
  ['balmy', 165],
  ['balsa', 191],
  ['banal', 133],
  ['banco', 1863],
  ['banda', 2176],
  ['bands', 25658],
  ['bandy', 2862],
  ['baned', 0],
  ['banes', 71],
  ['bangs', 397],
  ['banjo', 2273],
  ['banks', 22733],
  ['banns', 31],
  ['banty', 17],
  ['barbe', 277],
  ['barbs', 152],
  ['barca', 397],
  ['barde', 51],
  ['bards', 345],
  ['bared', 41],
  ['barer', 59],
  ['bares', 77],
  ['barfs', 0],
  ['barge', 1868],
  ['baric', 16],
  ['barks', 341],
  ['barky', 3],
  ['barms', 0],
  ['barmy', 59],
  ['barns', 1305],
  ['barny', 46],
  ['baron', 34397],
  ['barre', 912],
  ['barye', 28],
  ['basal', 6011],
  ['based', 269562],
  ['baser', 33],
  ['bases', 7863],
  ['basic', 23686],
  ['basil', 3849],
  ['basin', 24191],
  ['basis', 29626],
  ['basks', 4],
  ['bassi', 397],
  ['basso', 710],
  ['bassy', 32],
  ['baste', 17],
  ['basts', 0],
  ['batch', 2460],
  ['bated', 5],
  ['bates', 7637],
  ['bathe', 291],
  ['baths', 1835],
  ['batik', 216],
  ['baton', 3335],
  ['batts', 111],
  ['battu', 22],
  ['batty', 305],
  ['bauds', 10],
  ['baulk', 16],
  ['bawds', 2],
  ['bawdy', 163],
  ['bawls', 7],
  ['bawty', 0],
  ['bayed', 21],
  ['bayou', 1792],
  ['bazar', 1595],
  ['bazoo', 5],
  ['beach', 52958],
  ['beads', 1098],
  ['beady', 72],
  ['beaks', 182],
  ['beaky', 50],
  ['beams', 1805],
  ['beamy', 3],
  ['beano', 417],
  ['beans', 3037],
  ['beard', 2836],
  ['bears', 16189],
  ['beast', 4172],
  ['beats', 3453],
  ['beaus', 5],
  ['beaut', 16],
  ['beaux', 819],
  ['bebop', 647],
  ['becap', 0],
  ['becks', 113],
  ['bedel', 93],
  ['bedew', 0],
  ['bedim', 0],
  ['beech', 2483],
  ['beedi', 49],
  ['beefs', 16],
  ['beefy', 32],
  ['beeps', 54],
  ['beers', 1808],
  ['beery', 412],
  ['beets', 270],
  ['befit', 4],
  ['befog', 0],
  ['began', 151978],
  ['begat', 52],
  ['beget', 37],
  ['begin', 11952],
  ['begot', 30],
  ['begum', 1090],
  ['begun', 9011],
  ['beige', 628],
  ['beigy', 0],
  ['being', 262925],
  ['belay', 110],
  ['belch', 34],
  ['belga', 34],
  ['belie', 21],
  ['belle', 5554],
  ['bells', 3762],
  ['belly', 2094],
  ['belon', 128],
  ['below', 40960],
  ['belts', 1287],
  ['bemas', 0],
  ['bemix', 0],
  ['bench', 5377],
  ['bends', 612],
  ['bendy', 37],
  ['benes', 160],
  ['benne', 42],
  ['benni', 80],
  ['benny', 3630],
  ['bento', 583],
  ['bents', 39],
  ['beret', 317],
  ['bergs', 55],
  ['berks', 1026],
  ['berme', 3],
  ['berms', 40],
  ['berry', 6464],
  ['berth', 2442],
  ['beryl', 825],
  ['beses', 0],
  ['beset', 350],
  ['besom', 16],
  ['besot', 0],
  ['bests', 1062],
  ['betas', 39],
  ['betel', 184],
  ['beths', 17],
  ['beton', 73],
  ['betta', 291],
  ['bevel', 183],
  ['bevor', 14],
  ['bewig', 0],
  ['bezel', 87],
  ['bezil', 0],
  ['bhang', 39],
  ['bhoot', 79],
  ['bhuts', 0],
  ['biali', 10],
  ['bialy', 57],
  ['bibbs', 16],
  ['bible', 16063],
  ['bicep', 48],
  ['bices', 0],
  ['biddy', 125],
  ['bided', 9],
  ['bider', 9],
  ['bides', 3],
  ['bidet', 37],
  ['bidis', 5],
  ['bield', 8],
  ['biers', 18],
  ['biffs', 0],
  ['biffy', 155],
  ['bifid', 82],
  ['biggy', 24],
  ['bight', 1022],
  ['bigly', 6],
  ['bigos', 5],
  ['bigot', 252],
  ['bijou', 229],
  ['biked', 14],
  ['biker', 731],
  ['bikes', 1689],
  ['bikie', 23],
  ['bilbo', 127],
  ['bilby', 71],
  ['biles', 100],
  ['bilge', 215],
  ['bilgy', 0],
  ['bilks', 0],
  ['bills', 6217],
  ['billy', 17520],
  ['bimah', 62],
  ['bimas', 2],
  ['binal', 0],
  ['bindi', 153],
  ['binds', 1778],
  ['biner', 12],
  ['bines', 18],
  ['binge', 281],
  ['bingo', 1003],
  ['binit', 2],
  ['bints', 0],
  ['biogs', 0],
  ['biome', 567],
  ['biont', 0],
  ['biota', 372],
  ['biped', 40],
  ['bipod', 80],
  ['birch', 3399],
  ['birds', 20903],
  ['birks', 212],
  ['birle', 0],
  ['birls', 0],
  ['biros', 17],
  ['birrs', 0],
  ['birse', 33],
  ['birth', 21889],
  ['bises', 2],
  ['bisks', 0],
  ['bison', 1888],
  ['biter', 65],
  ['bites', 987],
  ['bitsy', 78],
  ['bitts', 14],
  ['bitty', 106],
  ['bizes', 0],
  ['blabs', 0],
  ['black', 121935],
  ['blade', 5215],
  ['blaff', 0],
  ['blahs', 3],
  ['blain', 314],
  ['blame', 1506],
  ['blams', 0],
  ['bland', 1352],
  ['blank', 2429],
  ['blare', 16],
  ['blase', 48],
  ['blast', 4106],
  ['blate', 2],
  ['blats', 2],
  ['blawn', 0],
  ['blaws', 0],
  ['blaze', 1691],
  ['bleak', 697],
  ['blear', 2],
  ['bleat', 6],
  ['blebs', 15],
  ['bleed', 634],
  ['bleep', 105],
  ['blend', 3360],
  ['blent', 0],
  ['bless', 866],
  ['blest', 63],
  ['blets', 0],
  ['blimp', 270],
  ['blimy', 0],
  ['blind', 9741],
  ['blini', 13],
  ['blink', 519],
  ['blips', 28],
  ['bliss', 2312],
  ['blite', 11],
  ['blitz', 1889],
  ['bloat', 46],
  ['blobs', 114],
  ['block', 31744],
  ['blocs', 207],
  ['blogs', 1705],
  ['bloke', 225],
  ['blond', 631],
  ['blood', 29135],
  ['bloom', 3378],
  ['bloop', 24],
  ['blots', 31],
  ['blown', 1441],
  ['blows', 915],
  ['blowy', 0],
  ['blubs', 0],
  ['blued', 53],
  ['bluer', 43],
  ['blues', 26252],
  ['bluet', 222],
  ['bluey', 131],
  ['bluff', 5200],
  ['blume', 666],
  ['blunt', 1748],
  ['blurb', 105],
  ['blurs', 86],
  ['blurt', 41],
  ['blush', 247],
  ['blype', 0],
  ['board', 99007],
  ['boars', 203],
  ['boart', 5],
  ['boast', 383],
  ['boats', 9885],
  ['bobby', 12097],
  ['bocce', 88],
  ['bocci', 35],
  ['boche', 16],
  ['bocks', 4],
  ['boded', 7],
  ['bodes', 8],
  ['boffo', 38],
  ['boffs', 2],
  ['bogan', 316],
  ['bogey', 229],
  ['boggy', 376],
  ['bogie', 387],
  ['bogle', 267],
  ['bogus', 314],
  ['bohea', 9],
  ['bohos', 0],
  ['boils', 142],
  ['boing', 188],
  ['boink', 11],
  ['boite', 63],
  ['bolar', 56],
  ['bolas', 103],
  ['bolds', 13],
  ['boles', 334],
  ['bolls', 17],
  ['bolos', 32],
  ['bolts', 712],
  ['bolus', 336],
  ['bombe', 93],
  ['bombs', 2948],
  ['bonds', 6032],
  ['boned', 47],
  ['bones', 6586],
  ['boney', 574],
  ['bongo', 775],
  ['bongs', 20],
  ['bonks', 2],
  ['bonne', 517],
  ['bonny', 499],
  ['bonus', 10315],
  ['bonze', 18],
  ['boobs', 72],
  ['booby', 249],
  ['boody', 52],
  ['booed', 129],
  ['boogy', 10],
  ['books', 94839],
  ['booms', 276],
  ['boomy', 2],
  ['boons', 46],
  ['boors', 11],
  ['boost', 2145],
  ['booth', 4281],
  ['boots', 2937],
  ['booty', 587],
  ['booze', 201],
  ['boozy', 22],
  ['boral', 67],
  ['boras', 56],
  ['borax', 220],
  ['bored', 659],
  ['borer', 866],
  ['bores', 213],
  ['boric', 82],
  ['borks', 0],
  ['borne', 3803],
  ['boron', 780],
  ['borts', 18],
  ['borty', 0],
  ['bortz', 41],
  ['bosks', 0],
  ['bosky', 2],
  ['bosom', 178],
  ['boson', 342],
  ['bossy', 159],
  ['bosun', 30],
  ['botas', 38],
  ['botch', 42],
  ['botel', 17],
  ['bothy', 106],
  ['botts', 89],
  ['bough', 199],
  ['boule', 225],
  ['bound', 7860],
  ['bourg', 274],
  ['bourn', 142],
  ['bouse', 40],
  ['bousy', 0],
  ['bouts', 1467],
  ['bovid', 44],
  ['bowed', 664],
  ['bowel', 873],
  ['bower', 1100],
  ['bowls', 4709],
  ['bowse', 0],
  ['boxed', 989],
  ['boxer', 17671],
  ['boxes', 3364],
  ['boyar', 327],
  ['boyla', 6],
  ['boyos', 0],
  ['bozos', 13],
  ['brace', 832],
  ['brach', 81],
  ['bract', 132],
  ['brads', 17],
  ['braes', 120],
  ['brags', 21],
  ['braid', 616],
  ['brail', 13],
  ['brain', 15320],
  ['brake', 1897],
  ['braky', 0],
  ['brand', 33224],
  ['brank', 16],
  ['brans', 13],
  ['brant', 1047],
  ['brash', 338],
  ['brass', 5792],
  ['brats', 137],
  ['brava', 679],
  ['brave', 3636],
  ['bravi', 24],
  ['bravo', 2845],
  ['brawl', 701],
  ['brawn', 177],
  ['braws', 0],
  ['braxy', 8],
  ['brays', 61],
  ['braza', 11],
  ['braze', 19],
  ['bread', 5722],
  ['break', 17531],
  ['bream', 528],
  ['brede', 128],
  ['breed', 8881],
  ['brees', 122],
  ['brens', 10],
  ['brent', 3933],
  ['breve', 289],
  ['brews', 193],
  ['briar', 401],
  ['bribe', 419],
  ['brick', 23304],
  ['bride', 3437],
  ['brief', 14641],
  ['brier', 1058],
  ['bries', 0],
  ['brigs', 78],
  ['brill', 845],
  ['brims', 40],
  ['brine', 547],
  ['bring', 14878],
  ['brink', 1080],
  ['brins', 3],
  ['briny', 41],
  ['brios', 0],
  ['brisk', 247],
  ['briss', 2],
  ['brith', 75],
  ['brits', 210],
  ['britt', 991],
  ['broad', 18593],
  ['brock', 2573],
  ['broil', 11],
  ['broke', 16002],
  ['brome', 405],
  ['bromo', 75],
  ['bronc', 105],
  ['brood', 923],
  ['brook', 11756],
  ['broom', 1228],
  ['broos', 22],
  ['brose', 170],
  ['brosy', 0],
  ['broth', 685],
  ['brown', 72118],
  ['brows', 49],
  ['brugh', 28],
  ['bruin', 305],
  ['bruit', 67],
  ['brume', 29],
  ['brung', 3],
  ['brunt', 380],
  ['brush', 3015],
  ['brusk', 2],
  ['brute', 402],
  ['bruts', 10],
  ['bubal', 12],
  ['bubba', 561],
  ['bubby', 30],
  ['bubus', 0],
  ['bucko', 48],
  ['bucks', 2822],
  ['buddy', 4281],
  ['budge', 472],
  ['buffi', 6],
  ['buffo', 78],
  ['buffs', 212],
  ['buffy', 1914],
  ['buggy', 539],
  ['bugle', 957],
  ['buhls', 0],
  ['buhrs', 2],
  ['build', 20341],
  ['built', 225728],
  ['bulbs', 929],
  ['bulge', 960],
  ['bulgy', 7],
  ['bulks', 10],
  ['bulky', 385],
  ['bulla', 303],
  ['bulls', 5264],
  ['bully', 758],
  ['bumfs', 0],
  ['bumph', 0],
  ['bumps', 742],
  ['bumpy', 224],
  ['bunas', 0],
  ['bunch', 1700],
  ['bunco', 30],
  ['bunds', 8],
  ['bundt', 41],
  ['bungs', 9],
  ['bunko', 384],
  ['bunks', 72],
  ['bunns', 5],
  ['bunny', 2505],
  ['bunts', 35],
  ['bunya', 83],
  ['buoys', 251],
  ['buppy', 0],
  ['buran', 149],
  ['buras', 32],
  ['burbs', 16],
  ['burds', 0],
  ['buret', 30],
  ['burgh', 1773],
  ['burgs', 10],
  ['burin', 192],
  ['burka', 93],
  ['burke', 6091],
  ['burls', 19],
  ['burly', 83],
  ['burns', 7676],
  ['burnt', 3217],
  ['burps', 9],
  ['burqa', 88],
  ['burro', 202],
  ['burrs', 41],
  ['burry', 157],
  ['bursa', 1020],
  ['burse', 63],
  ['burst', 1891],
  ['busby', 652],
  ['bused', 49],
  ['buses', 6956],
  ['bushy', 723],
  ['busks', 7],
  ['busts', 642],
  ['busty', 71],
  ['butch', 1492],
  ['buteo', 149],
  ['butes', 37],
  ['butle', 0],
  ['butte', 4340],
  ['butts', 676],
  ['butty', 30],
  ['butut', 2],
  ['butyl', 146],
  ['buxom', 30],
  ['buyer', 1679],
  ['bwana', 73],
  ['bylaw', 106],
  ['byres', 53],
  ['byrls', 0],
  ['byssi', 0],
  ['bytes', 782],
  ['byway', 1085],
  ['cabal', 353],
  ['cabby', 19],
  ['caber', 21],
  ['cabin', 5665],
  ['cable', 17869],
  ['cabob', 0],
  ['cacao', 487],
  ['cacas', 6],
  ['cache', 2080],
  ['cacti', 512],
  ['caddy', 263],
  ['cades', 59],
  ['cadet', 4030],
  ['cadge', 0],
  ['cadgy', 0],
  ['cadis', 6],
  ['cadre', 866],
  ['caeca', 58],
  ['cafes', 896],
  ['caffs', 0],
  ['caged', 254],
  ['cager', 16],
  ['cages', 442],
  ['cagey', 14],
  ['cahow', 16],
  ['caids', 4],
  ['cains', 60],
  ['caird', 197],
  ['cairn', 1174],
  ['cajon', 305],
  ['caked', 14],
  ['cakes', 1152],
  ['cakey', 6],
  ['calfs', 0],
  ['calif', 447],
  ['calix', 45],
  ['calks', 2],
  ['calla', 135],
  ['calls', 11284],
  ['calms', 27],
  ['calos', 6],
  ['calve', 22],
  ['calyx', 578],
  ['camas', 419],
  ['camel', 1737],
  ['cameo', 2890],
  ['cames', 19],
  ['camos', 3],
  ['campi', 264],
  ['campo', 3138],
  ['camps', 7546],
  ['campy', 127],
  ['canal', 25468],
  ['candy', 4881],
  ['caned', 20],
  ['caner', 77],
  ['canes', 343],
  ['canid', 113],
  ['canna', 325],
  ['canny', 108],
  ['canoe', 6926],
  ['canon', 9315],
  ['canso', 268],
  ['canst', 3],
  ['canto', 1052],
  ['cants', 12],
  ['canty', 190],
  ['caped', 44],
  ['caper', 427],
  ['capes', 362],
  ['caphs', 0],
  ['capiz', 193],
  ['capon', 238],
  ['capos', 56],
  ['caput', 273],
  ['carat', 242],
  ['carbo', 177],
  ['carbs', 15],
  ['cards', 11718],
  ['cared', 757],
  ['carer', 69],
  ['cares', 708],
  ['caret', 88],
  ['carex', 1283],
  ['cargo', 11819],
  ['carks', 0],
  ['carle', 437],
  ['carls', 38],
  ['carns', 53],
  ['carny', 43],
  ['carob', 78],
  ['carol', 6884],
  ['carom', 141],
  ['carpi', 391],
  ['carps', 121],
  ['carrs', 81],
  ['carry', 15036],
  ['carse', 179],
  ['carte', 910],
  ['carts', 598],
  ['carve', 359],
  ['casas', 1027],
  ['cased', 148],
  ['cases', 33777],
  ['casks', 157],
  ['casky', 0],
  ['caste', 3424],
  ['casts', 1202],
  ['casus', 101],
  ['catch', 5425],
  ['cater', 1386],
  ['cates', 354],
  ['catty', 49],
  ['cauld', 10],
  ['caulk', 43],
  ['cauls', 4],
  ['cause', 25498],
  ['caved', 47],
  ['caver', 55],
  ['caves', 5958],
  ['cavie', 2],
  ['cavil', 12],
  ['cawed', 0],
  ['cease', 1911],
  ['cebid', 2],
  ['cecal', 29],
  ['cecum', 79],
  ['cedar', 7661],
  ['ceded', 1682],
  ['ceder', 51],
  ['cedes', 18],
  ['cedis', 11],
  ['ceiba', 305],
  ['ceili', 47],
  ['ceils', 0],
  ['celeb', 58],
  ['cella', 199],
  ['celli', 137],
  ['cello', 3501],
  ['cells', 23852],
  ['celom', 0],
  ['celts', 468],
  ['cense', 10],
  ['cento', 197],
  ['cents', 1532],
  ['centu', 0],
  ['ceorl', 9],
  ['cepes', 12],
  ['cerci', 72],
  ['cered', 2],
  ['ceres', 962],
  ['ceria', 49],
  ['ceric', 16],
  ['ceros', 10],
  ['cesta', 196],
  ['cesti', 33],
  ['cetes', 4],
  ['chads', 51],
  ['chafe', 63],
  ['chaff', 176],
  ['chain', 25133],
  ['chair', 24368],
  ['chais', 22],
  ['chalk', 2500],
  ['champ', 1648],
  ['chams', 82],
  ['chang', 4553],
  ['chant', 1663],
  ['chaos', 3943],
  ['chape', 20],
  ['chaps', 116],
  ['chapt', 9],
  ['chard', 299],
  ['chare', 22],
  ['chark', 9],
  ['charm', 1694],
  ['charr', 62],
  ['chars', 55],
  ['chart', 82439],
  ['chary', 30],
  ['chase', 9479],
  ['chasm', 199],
  ['chats', 227],
  ['chaws', 0],
  ['chays', 0],
  ['cheap', 2695],
  ['cheat', 678],
  ['check', 4778],
  ['cheek', 1063],
  ['cheep', 38],
  ['cheer', 757],
  ['chefs', 1239],
  ['chela', 178],
  ['chemo', 58],
  ['chert', 274],
  ['chess', 20826],
  ['chest', 3503],
  ['cheth', 2],
  ['chevy', 812],
  ['chews', 69],
  ['chewy', 202],
  ['chiao', 178],
  ['chias', 4],
  ['chica', 422],
  ['chick', 1671],
  ['chico', 2744],
  ['chics', 9],
  ['chide', 11],
  ['chief', 90709],
  ['chiel', 14],
  ['child', 42473],
  ['chile', 22568],
  ['chili', 2081],
  ['chill', 992],
  ['chimb', 0],
  ['chime', 295],
  ['chimp', 139],
  ['china', 118347],
  ['chine', 341],
  ['chino', 696],
  ['chins', 41],
  ['chips', 2914],
  ['chirk', 163],
  ['chirm', 0],
  ['chiro', 48],
  ['chirp', 111],
  ['chirr', 0],
  ['chiru', 51],
  ['chits', 23],
  ['chive', 41],
  ['chivy', 0],
  ['chock', 82],
  ['choir', 10342],
  ['choke', 522],
  ['choky', 12],
  ['chola', 1085],
  ['cholo', 104],
  ['chomp', 51],
  ['chook', 30],
  ['chops', 216],
  ['chord', 2925],
  ['chore', 86],
  ['chose', 14384],
  ['chott', 81],
  ['chows', 5],
  ['chubs', 69],
  ['chuck', 7070],
  ['chufa', 6],
  ['chuff', 5],
  ['chugs', 5],
  ['chump', 65],
  ['chums', 90],
  ['chunk', 455],
  ['churl', 16],
  ['churn', 175],
  ['churr', 2],
  ['chute', 692],
  ['chyle', 28],
  ['chyme', 39],
  ['cibol', 0],
  ['cider', 1093],
  ['cigar', 1297],
  ['cilia', 983],
  ['cimex', 57],
  ['cinch', 38],
  ['cines', 77],
  ['cions', 0],
  ['circa', 5899],
  ['cires', 19],
  ['cirri', 76],
  ['cisco', 1563],
  ['cissy', 150],
  ['cists', 34],
  ['cited', 11603],
  ['citer', 10],
  ['cites', 1563],
  ['civet', 200],
  ['civic', 11944],
  ['civie', 0],
  ['civil', 110098],
  ['civvy', 8],
  ['clach', 68],
  ['clack', 225],
  ['clade', 3192],
  ['clads', 3],
  ['clags', 8],
  ['claim', 16106],
  ['clamp', 721],
  ['clams', 853],
  ['clang', 70],
  ['clank', 174],
  ['clans', 2512],
  ['claps', 77],
  ['clapt', 0],
  ['claro', 625],
  ['clary', 320],
  ['clash', 3060],
  ['clasp', 417],
  ['class', 101266],
  ['clast', 24],
  ['clave', 85],
  ['clavi', 19],
  ['claws', 887],
  ['clays', 503],
  ['clean', 8256],
  ['clear', 18871],
  ['cleat', 45],
  ['cleek', 8],
  ['clefs', 53],
  ['cleft', 768],
  ['clepe', 0],
  ['clept', 0],
  ['clerk', 8015],
  ['clews', 70],
  ['click', 2509],
  ['cliff', 7877],
  ['clift', 263],
  ['climb', 3788],
  ['clime', 25],
  ['cline', 1032],
  ['cling', 198],
  ['clink', 79],
  ['clips', 2334],
  ['clipt', 0],
  ['cloak', 767],
  ['clock', 7889],
  ['clods', 22],
  ['clogs', 109],
  ['clomb', 0],
  ['clomp', 0],
  ['clone', 1566],
  ['clonk', 16],
  ['clons', 0],
  ['cloot', 4],
  ['clops', 4],
  ['close', 61166],
  ['cloth', 3324],
  ['clots', 226],
  ['cloud', 10068],
  ['clour', 0],
  ['clout', 180],
  ['clove', 338],
  ['clown', 2610],
  ['cloys', 0],
  ['cloze', 9],
  ['clubs', 39407],
  ['cluck', 57],
  ['clued', 11],
  ['clues', 1035],
  ['clump', 479],
  ['clung', 76],
  ['clunk', 19],
  ['cnida', 4],
  ['coach', 130438],
  ['coact', 2],
  ['coala', 15],
  ['coals', 197],
  ['coaly', 8],
  ['coapt', 2],
  ['coast', 96970],
  ['coati', 49],
  ['coats', 1668],
  ['cobbs', 230],
  ['cobby', 75],
  ['cobia', 45],
  ['coble', 149],
  ['cobra', 1971],
  ['cocas', 6],
  ['cocci', 110],
  ['cocks', 445],
  ['cocky', 143],
  ['cocoa', 1586],
  ['cocos', 671],
  ['codas', 20],
  ['codec', 655],
  ['coded', 1230],
  ['coden', 37],
  ['coder', 139],
  ['codes', 9940],
  ['codex', 2923],
  ['codon', 260],
  ['coeds', 16],
  ['coffs', 236],
  ['cogon', 17],
  ['cohog', 0],
  ['cohos', 15],
  ['coifs', 0],
  ['coign', 4],
  ['coils', 518],
  ['coins', 7582],
  ['coirs', 0],
  ['coked', 4],
  ['cokes', 26],
  ['colas', 210],
  ['colby', 1241],
  ['colds', 65],
  ['coled', 0],
  ['coles', 1432],
  ['colic', 221],
  ['colin', 9888],
  ['colly', 37],
  ['colog', 6],
  ['colon', 1704],
  ['color', 26737],
  ['colts', 4392],
  ['colza', 20],
  ['comae', 79],
  ['comal', 162],
  ['comas', 165],
  ['combe', 849],
  ['combo', 1076],
  ['combs', 1062],
  ['comer', 330],
  ['comes', 28561],
  ['comet', 3800],
  ['comfy', 38],
  ['comic', 34405],
  ['comix', 418],
  ['comma', 539],
  ['commy', 3],
  ['compo', 56],
  ['comps', 50],
  ['compt', 5],
  ['comte', 2011],
  ['conch', 491],
  ['condo', 302],
  ['coned', 10],
  ['cones', 2122],
  ['coney', 962],
  ['conga', 245],
  ['conge', 3],
  ['congo', 18089],
  ['conic', 331],
  ['conin', 3],
  ['conks', 21],
  ['conky', 10],
  ['conns', 17],
  ['conte', 1017],
  ['conto', 20],
  ['conus', 2976],
  ['cooch', 389],
  ['cooed', 0],
  ['cooee', 50],
  ['cooer', 0],
  ['cooey', 16],
  ['coofs', 0],
  ['cooks', 890],
  ['cooky', 16],
  ['cools', 291],
  ['cooly', 17],
  ['coomb', 33],
  ['coons', 114],
  ['coops', 46],
  ['coopt', 2],
  ['coots', 118],
  ['copal', 81],
  ['copay', 6],
  ['coped', 55],
  ['copen', 15],
  ['coper', 22],
  ['copes', 99],
  ['copra', 141],
  ['copse', 270],
  ['coral', 7162],
  ['corby', 710],
  ['cords', 588],
  ['cored', 36],
  ['corer', 20],
  ['cores', 1270],
  ['corgi', 192],
  ['coria', 263],
  ['corks', 54],
  ['corky', 321],
  ['corms', 87],
  ['corns', 71],
  ['cornu', 173],
  ['corny', 113],
  ['corps', 34972],
  ['corse', 415],
  ['cosec', 4],
  ['coses', 0],
  ['coset', 57],
  ['cosey', 59],
  ['cosie', 0],
  ['costa', 31093],
  ['costs', 10449],
  ['cotan', 7],
  ['coted', 5],
  ['cotes', 194],
  ['cotta', 1179],
  ['couch', 1102],
  ['coude', 11],
  ['cough', 559],
  ['could', 75580],
  ['count', 28975],
  ['coupe', 3041],
  ['coups', 410],
  ['court', 126318],
  ['couth', 4],
  ['coved', 26],
  ['coven', 288],
  ['cover', 41479],
  ['coves', 252],
  ['covet', 38],
  ['covey', 215],
  ['covin', 20],
  ['cowed', 11],
  ['cower', 11],
  ['cowls', 21],
  ['cowry', 639],
  ['coxae', 43],
  ['coxal', 21],
  ['coxed', 2260],
  ['coxes', 13],
  ['coyed', 0],
  ['coyer', 15],
  ['coyly', 9],
  ['coypu', 14],
  ['cozen', 39],
  ['cozes', 5],
  ['cozey', 0],
  ['cozie', 4],
  ['craal', 3],
  ['crabs', 1626],
  ['crack', 1937],
  ['craft', 8738],
  ['crags', 527],
  ['crake', 312],
  ['cramp', 321],
  ['crams', 2],
  ['crane', 5074],
  ['crank', 678],
  ['crape', 28],
  ['craps', 97],
  ['crash', 11825],
  ['crass', 243],
  ['crate', 302],
  ['crave', 180],
  ['crawl', 907],
  ['craws', 2],
  ['craze', 575],
  ['crazy', 5777],
  ['creak', 32],
  ['cream', 8183],
  ['credo', 343],
  ['creds', 0],
  ['creed', 2142],
  ['creek', 75360],
  ['creel', 146],
  ['creep', 575],
  ['creme', 338],
  ['crepe', 86],
  ['crept', 93],
  ['crepy', 5],
  ['cress', 234],
  ['crest', 4631],
  ['crews', 3251],
  ['cribs', 211],
  ['crick', 360],
  ['cried', 477],
  ['crier', 144],
  ['cries', 553],
  ['crime', 31840],
  ['crimp', 98],
  ['cripe', 11],
  ['crisp', 1334],
  ['crits', 10],
  ['croak', 42],
  ['croci', 64],
  ['crock', 103],
  ['crocs', 56],
  ['croft', 1486],
  ['crone', 242],
  ['crony', 51],
  ['crook', 1447],
  ['croon', 59],
  ['crops', 6216],
  ['crore', 1479],
  ['cross', 55205],
  ['croup', 41],
  ['crowd', 6067],
  ['crown', 27810],
  ['crows', 1104],
  ['croze', 35],
  ['cruck', 53],
  ['crude', 2762],
  ['cruds', 0],
  ['cruel', 1834],
  ['cruet', 20],
  ['crumb', 414],
  ['crump', 476],
  ['cruor', 4],
  ['crura', 35],
  ['cruse', 111],
  ['crush', 1976],
  ['crust', 2123],
  ['crwth', 13],
  ['crypt', 1193],
  ['cubby', 71],
  ['cubeb', 20],
  ['cubed', 100],
  ['cuber', 26],
  ['cubes', 607],
  ['cubic', 4562],
  ['cubit', 64],
  ['cuddy', 184],
  ['cuffs', 178],
  ['cuifs', 0],
  ['cuing', 18],
  ['cuish', 0],
  ['cukes', 3],
  ['culch', 0],
  ['culet', 16],
  ['culex', 241],
  ['culls', 19],
  ['cully', 134],
  ['culms', 106],
  ['culpa', 122],
  ['culti', 4],
  ['cults', 711],
  ['cumin', 220],
  ['cupel', 8],
  ['cupid', 723],
  ['cuppa', 50],
  ['cuppy', 30],
  ['curbs', 66],
  ['curch', 0],
  ['curds', 97],
  ['curdy', 7],
  ['cured', 821],
  ['curer', 9],
  ['cures', 431],
  ['curet', 22],
  ['curfs', 0],
  ['curia', 787],
  ['curie', 661],
  ['curio', 145],
  ['curls', 212],
  ['curly', 998],
  ['curns', 0],
  ['currs', 0],
  ['curry', 2897],
  ['curse', 2909],
  ['curst', 3],
  ['curve', 6194],
  ['curvy', 94],
  ['cusec', 7],
  ['cushy', 14],
  ['cusks', 0],
  ['cusps', 199],
  ['cusso', 3],
  ['cutch', 84],
  ['cuter', 20],
  ['cutes', 0],
  ['cutey', 46],
  ['cutie', 381],
  ['cutin', 30],
  ['cutis', 147],
  ['cutty', 158],
  ['cutup', 4],
  ['cuvee', 21],
  ['cyano', 28],
  ['cyans', 0],
  ['cyber', 2545],
  ['cycad', 377],
  ['cycas', 345],
  ['cycle', 19805],
  ['cyclo', 78],
  ['cyder', 17],
  ['cylix', 4],
  ['cymae', 0],
  ['cymar', 4],
  ['cymas', 0],
  ['cymes', 96],
  ['cymol', 0],
  ['cynic', 236],
  ['cysts', 560],
  ['cyton', 2],
  ['czars', 70],
  ['daces', 6],
  ['dacha', 116],
  ['dadas', 11],
  ['daddy', 2652],
  ['dados', 33],
  ['daffs', 0],
  ['daffy', 429],
  ['dagga', 47],
  ['dagos', 4],
  ['dahls', 16],
  ['daily', 37747],
  ['dairy', 5694],
  ['daisy', 4844],
  ['dales', 824],
  ['dally', 240],
  ['daman', 464],
  ['damar', 102],
  ['dames', 696],
  ['damns', 6],
  ['damps', 25],
  ['dance', 52115],
  ['dandy', 920],
  ['dangs', 11],
  ['danio', 319],
  ['darbs', 0],
  ['dared', 261],
  ['darer', 0],
  ['dares', 226],
  ['daric', 18],
  ['darks', 16],
  ['darky', 9],
  ['darns', 2],
  ['darts', 4719],
  ['dashi', 110],
  ['dashy', 0],
  ['dated', 11813],
  ['dater', 67],
  ['dates', 21877],
  ['datos', 30],
  ['datto', 29],
  ['datum', 400],
  ['daube', 31],
  ['daubs', 3],
  ['dauby', 3],
  ['daunt', 51],
  ['dauts', 0],
  ['daven', 20],
  ['davit', 272],
  ['dawed', 0],
  ['dawen', 14],
  ['dawks', 0],
  ['dawns', 81],
  ['dawts', 0],
  ['dazed', 213],
  ['dazes', 0],
  ['deads', 15],
  ['deair', 0],
  ['deals', 8579],
  ['dealt', 3900],
  ['deans', 716],
  ['dears', 71],
  ['deary', 59],
  ['deash', 3],
  ['death', 151842],
  ['deave', 0],
  ['debag', 0],
  ['debar', 165],
  ['debit', 571],
  ['debts', 1848],
  ['debug', 208],
  ['debut', 125400],
  ['debye', 149],
  ['decaf', 5],
  ['decal', 106],
  ['decay', 3055],
  ['decks', 1130],
  ['decor', 579],
  ['decos', 6],
  ['decoy', 540],
  ['decry', 31],
  ['dedal', 6],
  ['deeds', 1979],
  ['deedy', 5],
  ['deems', 293],
  ['deeps', 39],
  ['deers', 90],
  ['deets', 22],
  ['defat', 0],
  ['defer', 175],
  ['defis', 3],
  ['defog', 8],
  ['degas', 259],
  ['degum', 0],
  ['deice', 0],
  ['deify', 10],
  ['deign', 4],
  ['deils', 0],
  ['deism', 128],
  ['deist', 77],
  ['deity', 5207],
  ['deked', 0],
  ['dekes', 6],
  ['dekko', 4],
  ['delay', 3989],
  ['deled', 0],
  ['deles', 9],
  ['delfs', 17],
  ['delft', 1107],
  ['delis', 59],
  ['dells', 210],
  ['delly', 19],
  ['delta', 15037],
  ['delts', 2],
  ['delve', 149],
  ['demes', 42],
  ['demic', 12],
  ['demit', 6],
  ['demob', 20],
  ['demon', 4169],
  ['demos', 2521],
  ['demur', 12],
  ['denar', 36],
  ['denes', 87],
  ['denim', 450],
  ['dense', 5643],
  ['dents', 94],
  ['deoxy', 38],
  ['depot', 9874],
  ['depth', 10712],
  ['derat', 0],
  ['deray', 47],
  ['derby', 15601],
  ['derma', 37],
  ['derms', 0],
  ['derry', 2940],
  ['desex', 0],
  ['desks', 298],
  ['deter', 591],
  ['detox', 122],
  ['deuce', 403],
  ['devas', 189],
  ['devel', 3],
  ['devil', 6507],
  ['devon', 10915],
  ['dewan', 1708],
  ['dewar', 543],
  ['dewax', 0],
  ['dewed', 0],
  ['dexes', 0],
  ['dexie', 0],
  ['dhaks', 0],
  ['dhals', 0],
  ['dhobi', 62],
  ['dhole', 63],
  ['dhoti', 43],
  ['dhows', 44],
  ['dhuti', 2],
  ['dials', 277],
  ['diary', 5147],
  ['diazo', 71],
  ['diced', 201],
  ['dicer', 62],
  ['dices', 19],
  ['dicey', 77],
  ['dicks', 341],
  ['dicky', 314],
  ['dicot', 84],
  ['dicta', 89],
  ['dicty', 5],
  ['didie', 3],
  ['didos', 3],
  ['didst', 30],
  ['diene', 76],
  ['diets', 569],
  ['diffs', 8],
  ['dight', 32],
  ['digit', 1389],
  ['diked', 20],
  ['diker', 12],
  ['dikes', 415],
  ['dikey', 0],
  ['dildo', 97],
  ['dills', 23],
  ['dilly', 104],
  ['dimer', 390],
  ['dimes', 197],
  ['dimly', 95],
  ['dinar', 466],
  ['dined', 96],
  ['diner', 1125],
  ['dines', 154],
  ['dinge', 32],
  ['dingo', 310],
  ['dings', 37],
  ['dingy', 144],
  ['dinky', 127],
  ['dinos', 334],
  ['dints', 2],
  ['diode', 634],
  ['diols', 45],
  ['dippy', 70],
  ['dipso', 2],
  ['diram', 2],
  ['direr', 0],
  ['dirge', 140],
  ['dirks', 120],
  ['dirls', 0],
  ['dirts', 0],
  ['disci', 3],
  ['disco', 4784],
  ['discs', 3362],
  ['dishy', 12],
  ['disks', 1279],
  ['disme', 7],
  ['ditas', 0],
  ['ditch', 1784],
  ['dites', 11],
  ['ditsy', 4],
  ['ditto', 157],
  ['ditty', 105],
  ['ditzy', 31],
  ['divan', 281],
  ['divas', 634],
  ['dived', 110],
  ['diver', 3058],
  ['dives', 918],
  ['divot', 10],
  ['divvy', 6],
  ['diwan', 566],
  ['dixit', 418],
  ['dizen', 2],
  ['dizzy', 1155],
  ['djinn', 118],
  ['djins', 0],
  ['doats', 11],
  ['dobby', 31],
  ['dobie', 363],
  ['dobla', 20],
  ['dobra', 426],
  ['dobro', 299],
  ['docks', 2162],
  ['dodge', 4151],
  ['dodgy', 100],
  ['dodos', 54],
  ['doers', 39],
  ['doest', 8],
  ['doeth', 3],
  ['doffs', 5],
  ['doges', 61],
  ['dogey', 0],
  ['doggo', 6],
  ['doggy', 158],
  ['dogie', 6],
  ['dogma', 554],
  ['doily', 13],
  ['doing', 11477],
  ['doits', 0],
  ['dojos', 39],
  ['dolce', 530],
  ['dolci', 58],
  ['doled', 13],
  ['doles', 54],
  ['dolls', 3039],
  ['dolly', 2037],
  ['dolma', 88],
  ['dolor', 99],
  ['dolts', 3],
  ['domal', 11],
  ['domed', 717],
  ['domes', 1185],
  ['domic', 7],
  ['donas', 21],
  ['donee', 26],
  ['donga', 244],
  ['dongs', 28],
  ['donna', 4090],
  ['donne', 518],
  ['donor', 2996],
  ['donsy', 0],
  ['donut', 300],
  ['doody', 136],
  ['dooly', 76],
  ['dooms', 62],
  ['doomy', 8],
  ['doors', 7480],
  ['doozy', 2],
  ['dopas', 0],
  ['doped', 179],
  ['doper', 8],
  ['dopes', 14],
  ['dopey', 34],
  ['dorks', 14],
  ['dorky', 10],
  ['dorms', 135],
  ['dormy', 10],
  ['dorps', 2],
  ['dorrs', 8],
  ['dorsa', 115],
  ['dorty', 0],
  ['dosed', 29],
  ['doser', 4],
  ['doses', 1154],
  ['dotal', 0],
  ['doted', 11],
  ['doter', 0],
  ['dotes', 9],
  ['dotty', 74],
  ['doubt', 3549],
  ['douce', 198],
  ['dough', 1393],
  ['doula', 39],
  ['douma', 106],
  ['doums', 0],
  ['doura', 28],
  ['douse', 28],
  ['doven', 9],
  ['doves', 659],
  ['dowdy', 121],
  ['dowed', 0],
  ['dowel', 67],
  ['dower', 246],
  ['dowie', 114],
  ['downs', 5635],
  ['downy', 431],
  ['dowry', 578],
  ['dowse', 133],
  ['doxie', 11],
  ['doyen', 249],
  ['doyly', 2],
  ['dozed', 6],
  ['dozen', 6131],
  ['dozer', 71],
  ['dozes', 0],
  ['drabs', 3],
  ['draff', 14],
  ['draft', 35872],
  ['drags', 110],
  ['drail', 0],
  ['drain', 2567],
  ['drake', 5356],
  ['drama', 73971],
  ['drams', 32],
  ['drank', 424],
  ['drape', 106],
  ['drats', 0],
  ['drave', 7],
  ['drawl', 42],
  ['drawn', 13209],
  ['draws', 5557],
  ['drays', 10],
  ['dread', 545],
  ['dream', 16443],
  ['drear', 6],
  ['dreck', 6],
  ['dreed', 0],
  ['drees', 132],
  ['dregs', 98],
  ['dreks', 0],
  ['dress', 5741],
  ['drest', 100],
  ['dribs', 2],
  ['dried', 2898],
  ['drier', 683],
  ['dries', 449],
  ['drift', 2319],
  ['drill', 2440],
  ['drily', 5],
  ['drink', 6481],
  ['drips', 83],
  ['dript', 0],
  ['drive', 26343],
  ['droid', 282],
  ['droit', 450],
  ['droll', 69],
  ['drone', 1868],
  ['drool', 29],
  ['droop', 173],
  ['drops', 2341],
  ['dropt', 10],
  ['dross', 37],
  ['drouk', 0],
  ['drove', 3992],
  ['drown', 373],
  ['drubs', 0],
  ['drugs', 11345],
  ['druid', 576],
  ['drums', 14627],
  ['drunk', 1826],
  ['drupe', 197],
  ['druse', 31],
  ['dryad', 142],
  ['dryer', 338],
  ['dryly', 14],
  ['duads', 0],
  ['duals', 73],
  ['ducal', 1039],
  ['ducat', 85],
  ['duces', 26],
  ['duchy', 8985],
  ['ducks', 3109],
  ['ducky', 173],
  ['ducts', 519],
  ['duddy', 101],
  ['duded', 0],
  ['dudes', 209],
  ['duels', 341],
  ['duets', 1394],
  ['duffs', 15],
  ['dufus', 9],
  ['duits', 10],
  ['duked', 4],
  ['dukes', 3915],
  ['dulia', 4],
  ['dulls', 10],
  ['dully', 20],
  ['dulse', 15],
  ['dumas', 1197],
  ['dumbs', 0],
  ['dumka', 133],
  ['dumky', 9],
  ['dumps', 243],
  ['dumpy', 47],
  ['dunam', 20],
  ['dunce', 35],
  ['dunch', 61],
  ['dunes', 3084],
  ['dungs', 13],
  ['dungy', 74],
  ['dunks', 67],
  ['dunts', 4],
  ['duomi', 9],
  ['duomo', 681],
  ['duped', 78],
  ['duper', 67],
  ['dupes', 32],
  ['duple', 107],
  ['dural', 110],
  ['duras', 303],
  ['dured', 0],
  ['dures', 0],
  ['durns', 0],
  ['duroc', 44],
  ['duros', 17],
  ['durra', 31],
  ['durrs', 0],
  ['durst', 268],
  ['durum', 86],
  ['dusks', 2],
  ['dusky', 1052],
  ['dusts', 44],
  ['dusty', 1393],
  ['dutch', 76935],
  ['duvet', 85],
  ['dwarf', 6966],
  ['dweeb', 15],
  ['dwell', 644],
  ['dwelt', 280],
  ['dwine', 0],
  ['dyads', 37],
  ['dyers', 138],
  ['dying', 5218],
  ['dyked', 16],
  ['dykes', 668],
  ['dykey', 0],
  ['dynel', 7],
  ['dynes', 52],
  ['eager', 891],
  ['eagle', 12528],
  ['eagre', 6],
  ['eared', 171],
  ['earls', 1967],
  ['early', 232821],
  ['earns', 519],
  ['earth', 38448],
  ['eased', 270],
  ['easel', 180],
  ['eases', 71],
  ['easts', 149],
  ['eaten', 3673],
  ['eater', 687],
  ['eaved', 5],
  ['eaves', 1345],
  ['ebbed', 31],
  ['ebbet', 0],
  ['ebons', 0],
  ['ebony', 834],
  ['ebook', 516],
  ['eched', 0],
  ['eches', 0],
  ['echos', 90],
  ['eclat', 10],
  ['ecrus', 0],
  ['edema', 421],
  ['edged', 2233],
  ['edger', 51],
  ['edges', 5312],
  ['edict', 1060],
  ['edify', 16],
  ['edile', 2],
  ['edits', 897],
  ['educe', 0],
  ['educt', 2],
  ['eerie', 430],
  ['egads', 0],
  ['egers', 5],
  ['egest', 0],
  ['eggar', 105],
  ['egged', 84],
  ['egger', 220],
  ['egret', 242],
  ['eider', 255],
  ['eidos', 293],
  ['eight', 71730],
  ['eikon', 25],
  ['eject', 224],
  ['eking', 18],
  ['elain', 7],
  ['eland', 181],
  ['elans', 4],
  ['elate', 3],
  ['elbow', 1519],
  ['elder', 13911],
  ['elect', 8856],
  ['elegy', 604],
  ['elemi', 9],
  ['elfin', 349],
  ['elide', 14],
  ['elint', 56],
  ['elite', 12313],
  ['eloin', 0],
  ['elope', 81],
  ['elude', 91],
  ['elute', 10],
  ['elver', 14],
  ['elves', 749],
  ['email', 3555],
  ['embar', 10],
  ['embay', 0],
  ['embed', 214],
  ['ember', 267],
  ['embow', 0],
  ['emcee', 446],
  ['emeer', 0],
  ['emend', 11],
  ['emery', 1782],
  ['emeus', 5],
  ['emirs', 176],
  ['emits', 518],
  ['emmer', 127],
  ['emmet', 751],
  ['emmys', 520],
  ['emote', 17],
  ['empty', 5500],
  ['emyde', 0],
  ['emyds', 0],
  ['enact', 588],
  ['enate', 2],
  ['ended', 45348],
  ['ender', 183],
  ['endow', 123],
  ['endue', 0],
  ['enema', 135],
  ['enemy', 13539],
  ['enjoy', 4345],
  ['ennui', 42],
  ['enoki', 82],
  ['enols', 16],
  ['enorm', 0],
  ['enows', 0],
  ['enrol', 86],
  ['ensky', 0],
  ['ensue', 125],
  ['enter', 13057],
  ['entia', 7],
  ['entry', 26032],
  ['enure', 0],
  ['envoi', 35],
  ['envoy', 2123],
  ['enzym', 0],
  ['eosin', 55],
  ['epact', 13],
  ['epees', 0],
  ['ephah', 13],
  ['ephas', 0],
  ['ephod', 14],
  ['ephor', 10],
  ['epics', 658],
  ['epoch', 2431],
  ['epode', 30],
  ['epoxy', 307],
  ['equal', 14997],
  ['equid', 59],
  ['equip', 556],
  ['erase', 341],
  ['ergot', 120],
  ['erica', 2361],
  ['ernes', 13],
  ['erode', 910],
  ['erose', 3],
  ['erred', 64],
  ['error', 7134],
  ['erses', 0],
  ['eruct', 0],
  ['erugo', 0],
  ['erupt', 168],
  ['ervil', 11],
  ['escar', 3],
  ['escot', 31],
  ['eskar', 4],
  ['esker', 102],
  ['esnes', 3],
  ['essay', 5224],
  ['esses', 31],
  ['ester', 2193],
  ['estop', 4],
  ['etape', 7],
  ['ether', 1343],
  ['ethic', 693],
  ['ethos', 780],
  ['ethyl', 836],
  ['etnas', 0],
  ['etude', 162],
  ['etuis', 0],
  ['etwee', 0],
  ['etyma', 9],
  ['euros', 1736],
  ['evade', 491],
  ['evens', 138],
  ['event', 144292],
  ['evert', 774],
  ['every', 68413],
  ['evict', 139],
  ['evils', 383],
  ['evite', 10],
  ['evoke', 630],
  ['ewers', 110],
  ['exact', 8041],
  ['exalt', 46],
  ['exams', 1656],
  ['excel', 1344],
  ['execs', 27],
  ['exert', 614],
  ['exile', 8293],
  ['exine', 13],
  ['exing', 0],
  ['exist', 19182],
  ['exits', 1289],
  ['exons', 358],
  ['expat', 155],
  ['expel', 522],
  ['expos', 1422],
  ['extol', 82],
  ['extra', 11668],
  ['exude', 69],
  ['exult', 15],
  ['exurb', 46],
  ['eyass', 0],
  ['eyers', 24],
  ['eying', 6],
  ['eyras', 0],
  ['eyres', 83],
  ['eyrie', 46],
  ['eyrir', 2],
  ['fable', 840],
  ['faced', 10540],
  ['facer', 33],
  ['faces', 7873],
  ['facet', 434],
  ['facia', 7],
  ['facts', 3940],
  ['faddy', 6],
  ['faded', 1353],
  ['fader', 174],
  ['fades', 371],
  ['fadge', 2],
  ['fados', 24],
  ['faena', 31],
  ['faery', 85],
  ['faggy', 2],
  ['fagin', 98],
  ['fagot', 44],
  ['fails', 1589],
  ['faint', 2404],
  ['fairs', 1975],
  ['fairy', 6001],
  ['faith', 15249],
  ['faked', 228],
  ['faker', 95],
  ['fakes', 140],
  ['fakey', 3],
  ['fakir', 285],
  ['falls', 33842],
  ['false', 0],
  ['famed', 3934],
  ['fames', 47],
  ['fancy', 1588],
  ['fanes', 15],
  ['fanga', 15],
  ['fangs', 223],
  ['fanny', 2172],
  ['fanon', 93],
  ['fanos', 5],
  ['fanum', 36],
  ['faqir', 190],
  ['farad', 32],
  ['farce', 813],
  ['farci', 2],
  ['farcy', 8],
  ['fards', 0],
  ['fared', 764],
  ['farer', 15],
  ['fares', 846],
  ['farle', 0],
  ['farls', 8],
  ['farms', 8299],
  ['faros', 34],
  ['farts', 33],
  ['fasts', 87],
  ['fatal', 3738],
  ['fated', 102],
  ['fates', 501],
  ['fatly', 0],
  ['fatso', 97],
  ['fatty', 2637],
  ['fatwa', 246],
  ['faugh', 14],
  ['fauld', 24],
  ['fault', 5376],
  ['fauna', 4639],
  ['fauns', 20],
  ['fauve', 77],
  ['favas', 5],
  ['faves', 17],
  ['favor', 7313],
  ['favus', 15],
  ['fawns', 27],
  ['fawny', 0],
  ['faxed', 22],
  ['faxes', 76],
  ['fayed', 70],
  ['fazed', 10],
  ['fazes', 3],
  ['fears', 1823],
  ['fease', 0],
  ['feast', 5176],
  ['feats', 654],
  ['feaze', 0],
  ['fecal', 358],
  ['feces', 787],
  ['fecks', 0],
  ['fedex', 645],
  ['feebs', 0],
  ['feeds', 4467],
  ['feels', 2049],
  ['feeze', 0],
  ['feign', 48],
  ['feint', 80],
  ['feist', 354],
  ['felid', 43],
  ['fella', 119],
  ['fells', 781],
  ['felly', 22],
  ['felon', 282],
  ['felts', 70],
  ['femes', 2],
  ['femme', 1246],
  ['femur', 531],
  ['fence', 2751],
  ['fends', 6],
  ['fenny', 95],
  ['feods', 0],
  ['feoff', 2],
  ['feral', 1176],
  ['feres', 50],
  ['feria', 313],
  ['ferly', 0],
  ['fermi', 662],
  ['ferns', 2053],
  ['ferny', 95],
  ['ferry', 15364],
  ['fesse', 22],
  ['fests', 41],
  ['fetal', 1115],
  ['fetas', 0],
  ['fetch', 429],
  ['feted', 52],
  ['fetes', 43],
  ['fetid', 49],
  ['fetor', 15],
  ['fetus', 899],
  ['feuar', 2],
  ['feuds', 403],
  ['feued', 5],
  ['fever', 5930],
  ['fewer', 5740],
  ['feyer', 33],
  ['feyly', 0],
  ['fezes', 0],
  ['fezzy', 0],
  ['fiars', 20],
  ['fiats', 28],
  ['fiber', 4265],
  ['fibre', 1774],
  ['fices', 0],
  ['fiche', 25],
  ['fichu', 8],
  ['ficin', 3],
  ['ficus', 886],
  ['fidge', 16],
  ['fidos', 0],
  ['fiefs', 301],
  ['field', 131772],
  ['fiend', 298],
  ['fiery', 839],
  ['fifed', 0],
  ['fifer', 194],
  ['fifes', 31],
  ['fifth', 58344],
  ['fifty', 7702],
  ['fight', 20256],
  ['filar', 19],
  ['filch', 7],
  ['filed', 6517],
  ['filer', 90],
  ['files', 8595],
  ['filet', 87],
  ['fille', 488],
  ['fillo', 13],
  ['fills', 940],
  ['filly', 1145],
  ['filmi', 73],
  ['films', 109308],
  ['filmy', 52],
  ['filos', 12],
  ['filth', 369],
  ['filum', 53],
  ['final', 182705],
  ['finca', 137],
  ['finch', 3184],
  ['finds', 6280],
  ['fined', 1286],
  ['finer', 681],
  ['fines', 1051],
  ['finis', 127],
  ['finks', 44],
  ['finny', 25],
  ['finos', 36],
  ['fiord', 219],
  ['fique', 13],
  ['fired', 8163],
  ['firer', 24],
  ['fires', 4047],
  ['firms', 6217],
  ['firns', 0],
  ['firry', 0],
  ['first', 1033698],
  ['firth', 2071],
  ['fiscs', 2],
  ['fishy', 77],
  ['fists', 292],
  ['fitch', 1081],
  ['fitly', 5],
  ['fiver', 33],
  ['fives', 293],
  ['fixed', 11680],
  ['fixer', 229],
  ['fixes', 361],
  ['fixit', 17],
  ['fizzy', 71],
  ['fjeld', 62],
  ['fjord', 3998],
  ['flabs', 0],
  ['flack', 478],
  ['flags', 4501],
  ['flail', 112],
  ['flair', 738],
  ['flake', 516],
  ['flaky', 170],
  ['flame', 3669],
  ['flams', 4],
  ['flamy', 0],
  ['flank', 2643],
  ['flans', 19],
  ['flaps', 458],
  ['flare', 722],
  ['flash', 7173],
  ['flask', 308],
  ['flats', 3733],
  ['flaws', 762],
  ['flawy', 0],
  ['flaxy', 3],
  ['flays', 3],
  ['fleam', 10],
  ['fleas', 276],
  ['fleck', 560],
  ['fleer', 47],
  ['flees', 220],
  ['fleet', 23194],
  ['flesh', 3124],
  ['flews', 2],
  ['fleys', 4],
  ['flick', 616],
  ['flics', 5],
  ['flied', 15],
  ['flier', 176],
  ['flies', 9834],
  ['fling', 231],
  ['flint', 3894],
  ['flips', 198],
  ['flirs', 0],
  ['flirt', 221],
  ['flite', 30],
  ['flits', 14],
  ['float', 1642],
  ['flock', 1081],
  ['flocs', 11],
  ['floes', 57],
  ['flogs', 2],
  ['flong', 10],
  ['flood', 9108],
  ['floor', 23077],
  ['flops', 137],
  ['flora', 8272],
  ['floss', 256],
  ['flota', 75],
  ['flour', 3776],
  ['flout', 14],
  ['flown', 3058],
  ['flows', 31514],
  ['flubs', 9],
  ['flued', 8],
  ['flues', 31],
  ['fluff', 134],
  ['fluid', 8132],
  ['fluke', 387],
  ['fluky', 0],
  ['flume', 388],
  ['flump', 5],
  ['flung', 144],
  ['flunk', 24],
  ['fluor', 88],
  ['flush', 777],
  ['flute', 4525],
  ['fluty', 4],
  ['fluyt', 36],
  ['flyby', 276],
  ['flyer', 1116],
  ['flyte', 52],
  ['foals', 335],
  ['foams', 170],
  ['foamy', 73],
  ['focal', 3005],
  ['focus', 29670],
  ['foehn', 21],
  ['fogey', 9],
  ['foggy', 394],
  ['fogie', 0],
  ['fohns', 0],
  ['foils', 164],
  ['foins', 0],
  ['foist', 11],
  ['folds', 1373],
  ['foley', 2573],
  ['folia', 131],
  ['folic', 106],
  ['folio', 929],
  ['folks', 677],
  ['folky', 51],
  ['folly', 1191],
  ['fonds', 232],
  ['fondu', 17],
  ['fonts', 1203],
  ['foods', 7272],
  ['fools', 1488],
  ['foots', 74],
  ['footy', 342],
  ['foram', 26],
  ['foray', 691],
  ['forbs', 66],
  ['forby', 9],
  ['force', 97308],
  ['fordo', 13],
  ['fords', 323],
  ['fores', 25],
  ['forge', 2516],
  ['forgo', 197],
  ['forks', 2726],
  ['forky', 2],
  ['forme', 98],
  ['forms', 50225],
  ['forte', 1232],
  ['forth', 4867],
  ['forts', 2736],
  ['forty', 7093],
  ['forum', 13902],
  ['fossa', 774],
  ['fosse', 570],
  ['fouls', 194],
  ['found', 269828],
  ['fount', 65],
  ['fours', 1471],
  ['fovea', 107],
  ['fowls', 36],
  ['foxed', 13],
  ['foxes', 1206],
  ['foyer', 374],
  ['frags', 9],
  ['frail', 253],
  ['frame', 18839],
  ['franc', 907],
  ['frank', 48470],
  ['fraps', 4],
  ['frass', 863],
  ['frats', 2],
  ['fraud', 5783],
  ['frays', 30],
  ['freak', 1393],
  ['freed', 2657],
  ['freer', 437],
  ['frees', 237],
  ['fremd', 28],
  ['frena', 12],
  ['frere', 263],
  ['fresh', 10355],
  ['frets', 225],
  ['friar', 1574],
  ['fried', 3003],
  ['frier', 56],
  ['fries', 971],
  ['frigs', 3],
  ['frill', 96],
  ['frise', 51],
  ['frisk', 211],
  ['frith', 832],
  ['frits', 439],
  ['fritt', 115],
  ['fritz', 5746],
  ['frizz', 14],
  ['frock', 119],
  ['froes', 5],
  ['frogs', 4324],
  ['frond', 200],
  ['frons', 88],
  ['front', 54174],
  ['frore', 4],
  ['frosh', 42],
  ['frost', 4361],
  ['froth', 115],
  ['frown', 37],
  ['frows', 0],
  ['froze', 185],
  ['frugs', 0],
  ['fruit', 17730],
  ['frump', 8],
  ['fryer', 353],
  ['fubar', 46],
  ['fubsy', 0],
  ['fucus', 77],
  ['fuddy', 14],
  ['fudge', 444],
  ['fuels', 1814],
  ['fugal', 49],
  ['fuggy', 0],
  ['fugio', 4],
  ['fugle', 7],
  ['fugue', 674],
  ['fugus', 4],
  ['fujis', 0],
  ['fulls', 12],
  ['fully', 20051],
  ['fumed', 28],
  ['fumer', 4],
  ['fumes', 252],
  ['fumet', 11],
  ['fundi', 27],
  ['funds', 15187],
  ['fungi', 8279],
  ['fungo', 14],
  ['funks', 11],
  ['funky', 1279],
  ['funny', 2940],
  ['furan', 96],
  ['furls', 0],
  ['furor', 95],
  ['furry', 559],
  ['furze', 112],
  ['furzy', 13],
  ['fused', 1707],
  ['fusee', 17],
  ['fusel', 26],
  ['fuses', 536],
  ['fusil', 86],
  ['fussy', 37],
  ['fusty', 0],
  ['futon', 56],
  ['fuzed', 17],
  ['fuzee', 0],
  ['fuzes', 86],
  ['fuzil', 0],
  ['fuzzy', 1301],
  ['fyces', 0],
  ['fykes', 3],
  ['fytte', 0],
  ['gabby', 426],
  ['gable', 6165],
  ['gaddi', 183],
  ['gadid', 22],
  ['gadis', 39],
  ['gadje', 4],
  ['gadjo', 14],
  ['gaffe', 45],
  ['gaffs', 11],
  ['gaged', 0],
  ['gager', 27],
  ['gages', 41],
  ['gaily', 39],
  ['gains', 2886],
  ['gaits', 102],
  ['galah', 42],
  ['galas', 207],
  ['galax', 92],
  ['galea', 228],
  ['gales', 246],
  ['galls', 444],
  ['gally', 46],
  ['galop', 92],
  ['gamas', 11],
  ['gamay', 69],
  ['gamba', 712],
  ['gambe', 10],
  ['gambs', 4],
  ['gamed', 8],
  ['gamer', 594],
  ['games', 246232],
  ['gamey', 21],
  ['gamic', 0],
  ['gamin', 33],
  ['gamma', 5090],
  ['gammy', 21],
  ['gamps', 0],
  ['gamut', 324],
  ['ganef', 5],
  ['ganev', 59],
  ['gangs', 1638],
  ['ganja', 460],
  ['ganof', 0],
  ['gaols', 43],
  ['gaped', 0],
  ['gaper', 26],
  ['gapes', 40],
  ['gappy', 8],
  ['garbs', 7],
  ['garda', 1059],
  ['garni', 81],
  ['garth', 1721],
  ['gases', 2734],
  ['gasps', 16],
  ['gassy', 28],
  ['gasts', 0],
  ['gated', 713],
  ['gater', 22],
  ['gates', 7940],
  ['gator', 767],
  ['gauds', 0],
  ['gaudy', 101],
  ['gauge', 8931],
  ['gault', 336],
  ['gaums', 0],
  ['gaunt', 476],
  ['gaurs', 13],
  ['gauss', 519],
  ['gauze', 146],
  ['gauzy', 14],
  ['gavel', 78],
  ['gavot', 3],
  ['gawks', 0],
  ['gawky', 3],
  ['gawps', 0],
  ['gawsy', 0],
  ['gayal', 7],
  ['gayer', 58],
  ['gayly', 3],
  ['gazar', 27],
  ['gazed', 25],
  ['gazer', 120],
  ['gazes', 69],
  ['gazoo', 51],
  ['gears', 1076],
  ['gecko', 5279],
  ['gecks', 4],
  ['geeks', 285],
  ['geeky', 52],
  ['geese', 1133],
  ['geest', 268],
  ['gelds', 0],
  ['gelee', 3],
  ['gelid', 6],
  ['gelts', 0],
  ['gemma', 949],
  ['gemmy', 15],
  ['gemot', 0],
  ['genes', 7336],
  ['genet', 387],
  ['genic', 30],
  ['genie', 1871],
  ['genii', 85],
  ['genip', 7],
  ['genoa', 4098],
  ['genom', 57],
  ['genre', 17599],
  ['genro', 28],
  ['gents', 93],
  ['genua', 37],
  ['genus', 164973],
  ['geode', 67],
  ['geoid', 85],
  ['gerah', 4],
  ['germs', 226],
  ['germy', 0],
  ['gesso', 71],
  ['geste', 225],
  ['gests', 2],
  ['getas', 2],
  ['getup', 30],
  ['geums', 0],
  ['ghast', 3],
  ['ghats', 1591],
  ['ghaut', 35],
  ['ghazi', 954],
  ['ghees', 0],
  ['ghost', 12730],
  ['ghoul', 354],
  ['ghyll', 41],
  ['giant', 16821],
  ['gibed', 0],
  ['giber', 6],
  ['gibes', 3],
  ['giddy', 113],
  ['gifts', 3011],
  ['gigas', 528],
  ['gighe', 0],
  ['gigot', 40],
  ['gigue', 59],
  ['gilds', 8],
  ['gills', 1225],
  ['gilly', 200],
  ['gilts', 32],
  ['gimel', 253],
  ['gimme', 761],
  ['gimps', 18],
  ['gimpy', 6],
  ['ginks', 0],
  ['ginny', 388],
  ['ginzo', 4],
  ['gipon', 0],
  ['gipsy', 405],
  ['girds', 0],
  ['girls', 35823],
  ['girly', 76],
  ['girns', 0],
  ['giron', 95],
  ['giros', 18],
  ['girsh', 11],
  ['girth', 294],
  ['girts', 15],
  ['gismo', 8],
  ['gists', 5],
  ['gites', 8],
  ['given', 110046],
  ['giver', 226],
  ['gives', 16736],
  ['gizmo', 72],
  ['glace', 299],
  ['glade', 515],
  ['glads', 2],
  ['glady', 25],
  ['glair', 7],
  ['glams', 4],
  ['gland', 1677],
  ['glans', 174],
  ['glare', 240],
  ['glary', 0],
  ['glass', 21507],
  ['glaze', 547],
  ['glazy', 0],
  ['gleam', 71],
  ['glean', 62],
  ['gleba', 84],
  ['glebe', 726],
  ['glede', 2],
  ['gleds', 0],
  ['gleed', 86],
  ['gleek', 10],
  ['glees', 47],
  ['gleet', 2],
  ['glens', 695],
  ['gleys', 0],
  ['glial', 215],
  ['glias', 2],
  ['glide', 806],
  ['gliff', 0],
  ['glime', 0],
  ['glims', 10],
  ['glint', 45],
  ['glitz', 68],
  ['gloam', 0],
  ['gloat', 8],
  ['globe', 12199],
  ['globs', 17],
  ['glogg', 4],
  ['gloms', 0],
  ['gloom', 243],
  ['glops', 0],
  ['glory', 6237],
  ['gloss', 529],
  ['glost', 6],
  ['glout', 0],
  ['glove', 1254],
  ['glows', 94],
  ['gloze', 0],
  ['glued', 317],
  ['gluer', 0],
  ['glues', 63],
  ['gluey', 23],
  ['glugs', 0],
  ['glume', 16],
  ['glums', 4],
  ['gluon', 64],
  ['glute', 3],
  ['gluts', 8],
  ['glyph', 297],
  ['gnarl', 3],
  ['gnarr', 19],
  ['gnars', 0],
  ['gnash', 27],
  ['gnats', 172],
  ['gnawn', 0],
  ['gnaws', 11],
  ['gnome', 1089],
  ['goads', 9],
  ['goals', 33491],
  ['goats', 1795],
  ['goban', 12],
  ['gobos', 4],
  ['godet', 43],
  ['godly', 142],
  ['goers', 156],
  ['gofer', 34],
  ['gogos', 25],
  ['going', 24694],
  ['golds', 1877],
  ['golem', 317],
  ['golfs', 10],
  ['golly', 59],
  ['gombo', 19],
  ['gomer', 268],
  ['gonad', 55],
  ['gonef', 0],
  ['goner', 33],
  ['gongs', 162],
  ['gonia', 50],
  ['gonif', 2],
  ['gonof', 0],
  ['gonzo', 438],
  ['goods', 16055],
  ['goody', 264],
  ['gooey', 42],
  ['goofs', 18],
  ['goofy', 405],
  ['gooks', 11],
  ['gooky', 0],
  ['goons', 77],
  ['goony', 2],
  ['goops', 22],
  ['goopy', 45],
  ['goose', 3104],
  ['goosy', 0],
  ['gopik', 0],
  ['goral', 69],
  ['gored', 50],
  ['gores', 131],
  ['gorge', 4820],
  ['gorms', 0],
  ['gorps', 11],
  ['gorse', 250],
  ['gorsy', 0],
  ['goths', 492],
  ['gouge', 119],
  ['gourd', 528],
  ['gouts', 6],
  ['gouty', 21],
  ['gowan', 158],
  ['gowds', 2],
  ['gowks', 0],
  ['gowns', 366],
  ['goxes', 0],
  ['goyim', 18],
  ['graal', 49],
  ['grabs', 235],
  ['grace', 12701],
  ['grade', 40272],
  ['grads', 78],
  ['graft', 539],
  ['grail', 707],
  ['grain', 6901],
  ['grama', 484],
  ['gramp', 4],
  ['grams', 1508],
  ['grana', 156],
  ['grand', 98206],
  ['grans', 25],
  ['grant', 26619],
  ['grape', 4908],
  ['graph', 7702],
  ['grapy', 2],
  ['grasp', 592],
  ['grass', 13010],
  ['grate', 166],
  ['grave', 7355],
  ['gravy', 518],
  ['grays', 1345],
  ['graze', 325],
  ['great', 139490],
  ['grebe', 294],
  ['greed', 809],
  ['greek', 100904],
  ['green', 80133],
  ['grees', 4],
  ['greet', 349],
  ['grego', 39],
  ['greys', 284],
  ['gride', 3],
  ['grids', 552],
  ['grief', 1168],
  ['griff', 291],
  ['grift', 32],
  ['grigs', 4],
  ['grill', 1433],
  ['grime', 597],
  ['grimy', 44],
  ['grind', 733],
  ['grins', 14],
  ['griot', 149],
  ['gripe', 49],
  ['grips', 415],
  ['gript', 0],
  ['gripy', 0],
  ['grist', 991],
  ['grith', 13],
  ['grits', 206],
  ['groan', 70],
  ['groat', 112],
  ['grody', 30],
  ['grogs', 17],
  ['groin', 367],
  ['groks', 0],
  ['groom', 1218],
  ['grope', 19],
  ['gross', 6987],
  ['grosz', 186],
  ['grots', 0],
  ['group', 319107],
  ['grout', 220],
  ['grove', 14195],
  ['growl', 123],
  ['grown', 13084],
  ['grows', 17925],
  ['grubs', 84],
  ['gruel', 82],
  ['grues', 13],
  ['gruff', 184],
  ['grume', 0],
  ['grump', 26],
  ['grunt', 274],
  ['guaco', 24],
  ['guano', 366],
  ['guans', 45],
  ['guard', 29016],
  ['guars', 0],
  ['guava', 308],
  ['gucks', 0],
  ['gudes', 0],
  ['guess', 1397],
  ['guest', 24984],
  ['guffs', 0],
  ['guide', 18001],
  ['guids', 3],
  ['guild', 8825],
  ['guile', 116],
  ['guilt', 1322],
  ['guiro', 15],
  ['guise', 1247],
  ['gulag', 488],
  ['gular', 64],
  ['gulch', 929],
  ['gules', 305],
  ['gulfs', 63],
  ['gulfy', 0],
  ['gulls', 674],
  ['gully', 1135],
  ['gulps', 6],
  ['gulpy', 0],
  ['gumbo', 206],
  ['gumma', 219],
  ['gummy', 149],
  ['gunks', 2],
  ['gunky', 8],
  ['gunny', 31],
  ['guppy', 228],
  ['gurge', 2],
  ['gurry', 22],
  ['gursh', 0],
  ['gurus', 499],
  ['gushy', 5],
  ['gussy', 9],
  ['gusto', 184],
  ['gusts', 275],
  ['gusty', 181],
  ['gutsy', 54],
  ['gutta', 151],
  ['gutty', 14],
  ['guyed', 462],
  ['guyot', 313],
  ['gwine', 19],
  ['gybed', 2],
  ['gybes', 0],
  ['gyoza', 12],
  ['gypsy', 2238],
  ['gyral', 9],
  ['gyred', 0],
  ['gyres', 49],
  ['gyron', 22],
  ['gyros', 54],
  ['gyrus', 384],
  ['gyved', 0],
  ['gyves', 8],
  ['haafs', 0],
  ['haars', 5],
  ['habit', 2576],
  ['habus', 9],
  ['hacek', 14],
  ['hacks', 258],
  ['hadal', 29],
  ['haded', 0],
  ['hades', 518],
  ['hadji', 222],
  ['hadst', 5],
  ['haems', 0],
  ['haets', 0],
  ['hafiz', 542],
  ['hafts', 4],
  ['hahas', 0],
  ['haika', 11],
  ['haiks', 0],
  ['haiku', 581],
  ['hails', 1196],
  ['haint', 6],
  ['hairs', 2848],
  ['hairy', 3914],
  ['hajes', 0],
  ['hajis', 0],
  ['hajji', 1265],
  ['hakes', 34],
  ['hakim', 927],
  ['hakus', 0],
  ['halal', 347],
  ['haled', 6],
  ['haler', 22],
  ['hales', 729],
  ['halid', 69],
  ['hallo', 158],
  ['halls', 4690],
  ['halma', 39],
  ['halms', 0],
  ['halon', 40],
  ['halos', 197],
  ['halts', 277],
  ['halva', 48],
  ['halve', 74],
  ['hamal', 76],
  ['hames', 117],
  ['hammy', 57],
  ['hamza', 875],
  ['hance', 140],
  ['hands', 15966],
  ['handy', 841],
  ['hangs', 982],
  ['hanks', 735],
  ['hanky', 70],
  ['hansa', 615],
  ['hanse', 66],
  ['hants', 553],
  ['haole', 40],
  ['hapax', 30],
  ['haply', 2],
  ['happy', 10758],
  ['hards', 26],
  ['hardy', 6370],
  ['hared', 8],
  ['harem', 703],
  ['hares', 300],
  ['harks', 39],
  ['harls', 0],
  ['harms', 514],
  ['harps', 581],
  ['harpy', 117],
  ['harry', 30760],
  ['harsh', 2968],
  ['harts', 175],
  ['hasps', 0],
  ['haste', 286],
  ['hasty', 344],
  ['hatch', 2720],
  ['hated', 671],
  ['hater', 109],
  ['hates', 412],
  ['haugh', 102],
  ['haulm', 2],
  ['hauls', 810],
  ['haunt', 541],
  ['haute', 1834],
  ['haven', 8781],
  ['haver', 127],
  ['haves', 53],
  ['havoc', 775],
  ['hawed', 0],
  ['hawks', 6183],
  ['hawse', 9],
  ['hayed', 2],
  ['hayer', 85],
  ['hayey', 0],
  ['hazan', 92],
  ['hazed', 15],
  ['hazel', 2397],
  ['hazer', 23],
  ['hazes', 37],
  ['heads', 16140],
  ['heady', 138],
  ['heals', 145],
  ['heaps', 366],
  ['heapy', 2],
  ['heard', 13975],
  ['hears', 623],
  ['heart', 44923],
  ['heath', 8879],
  ['heats', 4185],
  ['heave', 92],
  ['heavy', 33750],
  ['hebes', 72],
  ['hecks', 9],
  ['heder', 55],
  ['hedge', 1973],
  ['hedgy', 0],
  ['heeds', 8],
  ['heels', 2175],
  ['heeze', 26],
  ['hefts', 0],
  ['hefty', 177],
  ['heigh', 15],
  ['heils', 10],
  ['heirs', 2544],
  ['heist', 773],
  ['helio', 192],
  ['helix', 1286],
  ['hello', 3387],
  ['hells', 410],
  ['helms', 1054],
  ['helos', 42],
  ['helot', 18],
  ['helps', 6297],
  ['helve', 6],
  ['hemal', 44],
  ['hemes', 5],
  ['hemic', 5],
  ['hemin', 20],
  ['hemps', 2],
  ['hempy', 0],
  ['hence', 12520],
  ['henge', 198],
  ['henna', 158],
  ['henry', 91799],
  ['hents', 0],
  ['herbs', 2214],
  ['herby', 90],
  ['herds', 645],
  ['heres', 30],
  ['herls', 0],
  ['herma', 67],
  ['herms', 70],
  ['herns', 4],
  ['heron', 1846],
  ['heros', 254],
  ['herry', 42],
  ['hertz', 809],
  ['hests', 0],
  ['heths', 0],
  ['heuch', 27],
  ['heugh', 28],
  ['hewed', 15],
  ['hewer', 64],
  ['hexad', 15],
  ['hexed', 18],
  ['hexer', 23],
  ['hexes', 55],
  ['hexyl', 30],
  ['hicks', 2784],
  ['hided', 2],
  ['hider', 71],
  ['hides', 770],
  ['highs', 650],
  ['hight', 85],
  ['hijab', 209],
  ['hijra', 112],
  ['hiked', 121],
  ['hiker', 154],
  ['hikes', 331],
  ['hilar', 13],
  ['hillo', 3],
  ['hills', 37268],
  ['hilly', 2010],
  ['hilts', 37],
  ['hilum', 44],
  ['hilus', 14],
  ['hinds', 736],
  ['hinge', 495],
  ['hinky', 16],
  ['hinny', 14],
  ['hints', 698],
  ['hiply', 0],
  ['hippo', 579],
  ['hippy', 181],
  ['hired', 11955],
  ['hiree', 0],
  ['hirer', 10],
  ['hires', 469],
  ['hissy', 7],
  ['hists', 0],
  ['hitch', 788],
  ['hived', 27],
  ['hives', 286],
  ['hoagy', 143],
  ['hoard', 954],
  ['hoars', 0],
  ['hoary', 251],
  ['hobby', 2189],
  ['hobos', 69],
  ['hocks', 34],
  ['hocus', 116],
  ['hodad', 0],
  ['hoers', 0],
  ['hogan', 2768],
  ['hoggs', 26],
  ['hoick', 0],
  ['hoise', 9],
  ['hoist', 429],
  ['hoked', 0],
  ['hokes', 17],
  ['hokey', 82],
  ['hokku', 18],
  ['hokum', 58],
  ['holds', 33248],
  ['holed', 196],
  ['holes', 5489],
  ['holey', 38],
  ['holks', 0],
  ['holla', 87],
  ['hollo', 30],
  ['holly', 4329],
  ['holms', 59],
  ['holts', 48],
  ['homed', 21],
  ['homer', 3989],
  ['homes', 16810],
  ['homey', 41],
  ['homie', 153],
  ['honan', 84],
  ['honda', 5913],
  ['honed', 248],
  ['honer', 23],
  ['hones', 26],
  ['honey', 5639],
  ['hongi', 48],
  ['hongs', 18],
  ['honks', 7],
  ['honor', 34292],
  ['hooch', 208],
  ['hoods', 506],
  ['hoody', 14],
  ['hooey', 17],
  ['hoofs', 33],
  ['hooka', 3],
  ['hooks', 1218],
  ['hooky', 52],
  ['hooly', 6],
  ['hoops', 577],
  ['hoots', 62],
  ['hooty', 2],
  ['hoped', 2864],
  ['hoper', 12],
  ['hopes', 3305],
  ['hoppy', 113],
  ['horah', 2],
  ['horal', 8],
  ['horas', 118],
  ['horde', 894],
  ['horns', 2689],
  ['horny', 217],
  ['horse', 35857],
  ['horst', 1670],
  ['horsy', 0],
  ['hosed', 17],
  ['hosel', 2],
  ['hosen', 167],
  ['hoser', 34],
  ['hoses', 200],
  ['hosey', 36],
  ['hosta', 92],
  ['hosts', 18968],
  ['hotch', 22],
  ['hotel', 47979],
  ['hotly', 295],
  ['hound', 1361],
  ['houri', 30],
  ['hours', 30568],
  ['house', 336189],
  ['hovel', 27],
  ['hover', 302],
  ['howdy', 179],
  ['howes', 428],
  ['howff', 25],
  ['howfs', 0],
  ['howks', 0],
  ['howls', 38],
  ['hoyas', 663],
  ['hoyle', 433],
  ['hubby', 27],
  ['hucks', 48],
  ['huffs', 4],
  ['huffy', 15],
  ['huger', 144],
  ['hulas', 21],
  ['hulks', 77],
  ['hulky', 0],
  ['hullo', 13],
  ['hulls', 583],
  ['human', 82756],
  ['humic', 65],
  ['humid', 1663],
  ['humor', 4453],
  ['humph', 15],
  ['humps', 103],
  ['humpy', 59],
  ['humus', 188],
  ['hunch', 71],
  ['hunks', 37],
  ['hunky', 102],
  ['hunts', 762],
  ['hurds', 17],
  ['hurls', 14],
  ['hurly', 13],
  ['hurry', 514],
  ['hurst', 1662],
  ['hurts', 746],
  ['husks', 94],
  ['husky', 745],
  ['hussy', 44],
  ['hutch', 493],
  ['huzza', 8],
  ['hydra', 1039],
  ['hydro', 2265],
  ['hyena', 258],
  ['hying', 4],
  ['hylas', 75],
  ['hymen', 134],
  ['hymns', 2893],
  ['hyoid', 110],
  ['hyped', 77],
  ['hyper', 629],
  ['hypes', 4],
  ['hypha', 21],
  ['hypos', 0],
  ['hyrax', 87],
  ['hyson', 84],
  ['iambi', 4],
  ['iambs', 11],
  ['ichor', 9],
  ['icier', 0],
  ['icily', 2],
  ['icing', 429],
  ['icker', 4],
  ['icons', 2397],
  ['ictic', 0],
  ['ictus', 36],
  ['ideal', 6219],
  ['ideas', 14698],
  ['idiom', 887],
  ['idiot', 725],
  ['idled', 36],
  ['idler', 128],
  ['idles', 48],
  ['idols', 1435],
  ['idyll', 135],
  ['idyls', 5],
  ['igged', 0],
  ['igloo', 174],
  ['iglus', 0],
  ['ihram', 30],
  ['ikats', 3],
  ['ikons', 9],
  ['ileac', 0],
  ['ileal', 58],
  ['ileum', 125],
  ['ileus', 31],
  ['iliac', 333],
  ['iliad', 859],
  ['ilial', 0],
  ['ilium', 208],
  ['iller', 94],
  ['image', 24552],
  ['imago', 217],
  ['imams', 402],
  ['imaum', 6],
  ['imbed', 0],
  ['imbue', 34],
  ['imide', 35],
  ['imido', 15],
  ['imids', 4],
  ['imine', 96],
  ['imino', 27],
  ['immix', 0],
  ['imped', 0],
  ['impel', 36],
  ['impis', 7],
  ['imply', 1315],
  ['inane', 26],
  ['inapt', 3],
  ['inarm', 0],
  ['inbye', 0],
  ['incog', 6],
  ['incur', 231],
  ['incus', 70],
  ['index', 18184],
  ['indie', 13329],
  ['indol', 5],
  ['indow', 0],
  ['indri', 54],
  ['indue', 5],
  ['inept', 246],
  ['inert', 566],
  ['infer', 413],
  ['infix', 62],
  ['infos', 6],
  ['infra', 147],
  ['ingle', 249],
  ['ingot', 71],
  ['inion', 45],
  ['inked', 261],
  ['inker', 188],
  ['inkle', 31],
  ['inlay', 227],
  ['inlet', 5927],
  ['inned', 0],
  ['inner', 19349],
  ['input', 7491],
  ['inrun', 3],
  ['inset', 211],
  ['inter', 4352],
  ['intis', 0],
  ['intro', 1334],
  ['inure', 4],
  ['inurn', 0],
  ['invar', 12],
  ['iodic', 16],
  ['iodid', 0],
  ['iodin', 0],
  ['ionic', 1810],
  ['iotas', 3],
  ['irade', 7],
  ['irate', 57],
  ['irids', 0],
  ['iring', 4],
  ['irked', 19],
  ['iroko', 33],
  ['irone', 10],
  ['irons', 751],
  ['irony', 684],
  ['isbas', 0],
  ['isled', 0],
  ['isles', 5207],
  ['islet', 2107],
  ['issei', 200],
  ['issue', 30175],
  ['istle', 0],
  ['itchy', 230],
  ['items', 13423],
  ['ither', 0],
  ['ivied', 0],
  ['ivies', 50],
  ['ivory', 7163],
  ['ixias', 17],
  ['ixora', 124],
  ['ixtle', 17],
  ['izars', 0],
  ['jabot', 27],
  ['jacal', 14],
  ['jacks', 836],
  ['jacky', 629],
  ['jaded', 145],
  ['jades', 36],
  ['jager', 342],
  ['jaggs', 0],
  ['jaggy', 13],
  ['jagra', 2],
  ['jails', 487],
  ['jakes', 204],
  ['jalap', 28],
  ['jalop', 0],
  ['jambe', 35],
  ['jambs', 36],
  ['jammy', 58],
  ['janes', 282],
  ['janty', 2],
  ['japan', 124684],
  ['japed', 7],
  ['japer', 2],
  ['japes', 2],
  ['jarls', 53],
  ['jatos', 0],
  ['jauks', 0],
  ['jaunt', 48],
  ['jaups', 0],
  ['javas', 6],
  ['jawan', 136],
  ['jawed', 131],
  ['jazzy', 484],
  ['jeans', 1213],
  ['jebel', 566],
  ['jeeps', 157],
  ['jeers', 17],
  ['jefes', 16],
  ['jehad', 34],
  ['jehus', 0],
  ['jello', 222],
  ['jells', 11],
  ['jelly', 1366],
  ['jemmy', 69],
  ['jenny', 4266],
  ['jerid', 13],
  ['jerks', 187],
  ['jerky', 236],
  ['jerry', 12878],
  ['jesse', 7030],
  ['jests', 24],
  ['jetes', 3],
  ['jeton', 13],
  ['jetty', 700],
  ['jewed', 0],
  ['jewel', 2598],
  ['jibbs', 28],
  ['jibed', 4],
  ['jiber', 0],
  ['jibes', 14],
  ['jiffs', 6],
  ['jiffy', 60],
  ['jiggy', 40],
  ['jihad', 1354],
  ['jills', 34],
  ['jilts', 5],
  ['jimmy', 16907],
  ['jimpy', 3],
  ['jingo', 66],
  ['jinks', 133],
  ['jinni', 18],
  ['jinns', 18],
  ['jisms', 0],
  ['jived', 0],
  ['jiver', 6],
  ['jives', 4],
  ['jivey', 2],
  ['jnana', 121],
  ['jocko', 197],
  ['jocks', 73],
  ['joeys', 27],
  ['johns', 6370],
  ['joins', 6224],
  ['joint', 31292],
  ['joist', 47],
  ['joked', 193],
  ['joker', 928],
  ['jokes', 1596],
  ['jokey', 21],
  ['joles', 9],
  ['jolly', 1359],
  ['jolts', 20],
  ['jolty', 0],
  ['jomon', 52],
  ['jones', 42824],
  ['joram', 76],
  ['jorum', 18],
  ['jotas', 4],
  ['jotty', 3],
  ['joual', 21],
  ['jouks', 0],
  ['joule', 252],
  ['joust', 104],
  ['jowar', 29],
  ['jowed', 0],
  ['jowls', 15],
  ['jowly', 2],
  ['joyed', 0],
  ['jubas', 0],
  ['jubes', 2],
  ['jucos', 0],
  ['judas', 1240],
  ['judge', 42608],
  ['judos', 0],
  ['jugal', 129],
  ['jugum', 7],
  ['juice', 3382],
  ['juicy', 675],
  ['jujus', 7],
  ['juked', 7],
  ['jukes', 261],
  ['jukus', 4],
  ['julep', 34],
  ['jumbo', 814],
  ['jumps', 1213],
  ['jumpy', 38],
  ['junco', 194],
  ['junks', 42],
  ['junky', 52],
  ['junta', 1921],
  ['junto', 125],
  ['jupes', 5],
  ['jupon', 5],
  ['jural', 10],
  ['jurat', 60],
  ['jurel', 4],
  ['juror', 351],
  ['justs', 14],
  ['jutes', 39],
  ['jutty', 7],
  ['kabab', 58],
  ['kabar', 36],
  ['kabob', 8],
  ['kadis', 15],
  ['kafir', 77],
  ['kagus', 2],
  ['kaiak', 0],
  ['kaifs', 0],
  ['kails', 0],
  ['kains', 9],
  ['kakas', 20],
  ['kakis', 4],
  ['kalam', 845],
  ['kales', 21],
  ['kalif', 13],
  ['kalpa', 219],
  ['kames', 92],
  ['kamik', 9],
  ['kanas', 26],
  ['kanes', 26],
  ['kanji', 1145],
  ['kanzu', 9],
  ['kaons', 34],
  ['kapas', 24],
  ['kaphs', 0],
  ['kapok', 60],
  ['kappa', 3057],
  ['kaput', 36],
  ['karat', 147],
  ['karma', 1531],
  ['karns', 124],
  ['karoo', 684],
  ['karst', 1362],
  ['karts', 167],
  ['kasha', 137],
  ['katas', 27],
  ['kauri', 223],
  ['kaury', 0],
  ['kavas', 12],
  ['kayak', 1093],
  ['kayos', 0],
  ['kazoo', 96],
  ['kbars', 0],
  ['kebab', 290],
  ['kebar', 20],
  ['kebob', 3],
  ['kecks', 9],
  ['kedge', 26],
  ['keefs', 0],
  ['keeks', 3],
  ['keels', 161],
  ['keens', 17],
  ['keeps', 2389],
  ['keets', 4],
  ['keeve', 7],
  ['kefir', 50],
  ['keirs', 3],
  ['kelep', 0],
  ['kelim', 12],
  ['kelly', 16855],
  ['kelps', 10],
  ['kelpy', 0],
  ['kelts', 3],
  ['kemps', 76],
  ['kempt', 49],
  ['kenaf', 22],
  ['kench', 13],
  ['kendo', 347],
  ['kenos', 10],
  ['kente', 34],
  ['kepis', 4],
  ['kerbs', 16],
  ['kerfs', 0],
  ['kerne', 18],
  ['kerns', 189],
  ['kerry', 6577],
  ['ketch', 285],
  ['ketol', 19],
  ['kevel', 0],
  ['kevil', 6],
  ['kexes', 0],
  ['keyed', 177],
  ['khadi', 135],
  ['khafs', 0],
  ['khaki', 234],
  ['khans', 288],
  ['khaph', 0],
  ['khats', 0],
  ['kheda', 194],
  ['kheth', 2],
  ['khets', 0],
  ['khoum', 3],
  ['kiang', 135],
  ['kibbe', 53],
  ['kibbi', 0],
  ['kibei', 7],
  ['kibes', 0],
  ['kibla', 8],
  ['kicks', 1457],
  ['kicky', 2],
  ['kiddo', 51],
  ['kiddy', 70],
  ['kiefs', 0],
  ['kiers', 5],
  ['kilim', 41],
  ['kills', 2250],
  ['kilns', 560],
  ['kilos', 137],
  ['kilts', 55],
  ['kilty', 47],
  ['kinas', 6],
  ['kinds', 5434],
  ['kines', 21],
  ['kings', 21760],
  ['kinin', 18],
  ['kinks', 712],
  ['kinky', 257],
  ['kinos', 4],
  ['kiosk', 541],
  ['kirks', 39],
  ['kirns', 0],
  ['kissy', 58],
  ['kists', 7],
  ['kited', 3],
  ['kiter', 2],
  ['kites', 555],
  ['kithe', 0],
  ['kiths', 0],
  ['kitty', 2038],
  ['kivas', 46],
  ['kiwis', 157],
  ['klick', 56],
  ['kliks', 0],
  ['klong', 150],
  ['kloof', 109],
  ['kluge', 246],
  ['klutz', 39],
  ['knack', 339],
  ['knaps', 0],
  ['knars', 0],
  ['knaur', 12],
  ['knave', 125],
  ['knawe', 0],
  ['knead', 10],
  ['kneed', 9],
  ['kneel', 138],
  ['knees', 970],
  ['knell', 102],
  ['knelt', 40],
  ['knife', 3513],
  ['knish', 18],
  ['knits', 50],
  ['knobs', 362],
  ['knock', 1889],
  ['knoll', 1313],
  ['knops', 22],
  ['knosp', 4],
  ['knots', 2343],
  ['knout', 15],
  ['known', 742591],
  ['knows', 2934],
  ['knurl', 5],
  ['knurs', 0],
  ['koala', 383],
  ['koans', 36],
  ['kobos', 0],
  ['koels', 4],
  ['kohls', 41],
  ['koine', 220],
  ['kojis', 9],
  ['kolas', 27],
  ['kolos', 92],
  ['kombu', 49],
  ['konks', 9],
  ['kooks', 91],
  ['kooky', 33],
  ['kopek', 16],
  ['kophs', 0],
  ['kopje', 32],
  ['koppa', 45],
  ['korai', 31],
  ['koras', 17],
  ['korat', 79],
  ['korma', 22],
  ['korun', 43],
  ['kotos', 5],
  ['kotow', 2],
  ['kraal', 73],
  ['kraft', 1521],
  ['krait', 109],
  ['kraut', 104],
  ['kreep', 15],
  ['krewe', 206],
  ['krill', 189],
  ['krona', 89],
  ['krone', 343],
  ['kroon', 167],
  ['krubi', 0],
  ['kudos', 138],
  ['kudus', 122],
  ['kudzu', 108],
  ['kufis', 2],
  ['kugel', 93],
  ['kukri', 133],
  ['kulak', 58],
  ['kumys', 3],
  ['kurta', 62],
  ['kurus', 26],
  ['kusso', 0],
  ['kvass', 25],
  ['kvell', 0],
  ['kyack', 0],
  ['kyaks', 0],
  ['kyars', 0],
  ['kyats', 17],
  ['kylix', 87],
  ['kyrie', 217],
  ['kytes', 2],
  ['kythe', 4],
  ['laari', 19],
  ['label', 64789],
  ['labia', 201],
  ['labor', 28698],
  ['labra', 45],
  ['laced', 267],
  ['lacer', 9],
  ['laces', 151],
  ['lacey', 1190],
  ['lacks', 2916],
  ['laded', 0],
  ['laden', 1223],
  ['lader', 20],
  ['lades', 19],
  ['ladle', 99],
  ['laevo', 3],
  ['lagan', 295],
  ['lager', 783],
  ['lahar', 83],
  ['laich', 21],
  ['laics', 0],
  ['laigh', 27],
  ['laird', 1751],
  ['lairs', 13],
  ['laith', 65],
  ['laity', 435],
  ['laked', 0],
  ['laker', 194],
  ['lakes', 22229],
  ['lakhs', 304],
  ['lalls', 2],
  ['lamas', 536],
  ['lambs', 510],
  ['lamby', 12],
  ['lamed', 35],
  ['lamer', 54],
  ['lames', 21],
  ['lamia', 588],
  ['lamps', 1619],
  ['lanai', 179],
  ['lance', 3689],
  ['lands', 17226],
  ['lanes', 3892],
  ['lanky', 81],
  ['lapel', 159],
  ['lapin', 182],
  ['lapis', 221],
  ['lapse', 401],
  ['larch', 450],
  ['lards', 2],
  ['lardy', 31],
  ['laree', 20],
  ['lares', 296],
  ['large', 140389],
  ['largo', 1157],
  ['laris', 29],
  ['larks', 186],
  ['larky', 3],
  ['larum', 16],
  ['larva', 1791],
  ['lased', 4],
  ['laser', 6360],
  ['lases', 16],
  ['lassi', 82],
  ['lasso', 331],
  ['lasts', 2304],
  ['latch', 203],
  ['lated', 5],
  ['laten', 6],
  ['later', 330805],
  ['latex', 893],
  ['lathe', 516],
  ['lathi', 54],
  ['laths', 21],
  ['lathy', 15],
  ['latke', 15],
  ['latte', 213],
  ['lauan', 36],
  ['lauds', 60],
  ['laugh', 1404],
  ['laura', 10269],
  ['lavas', 259],
  ['laved', 2],
  ['laver', 476],
  ['laves', 33],
  ['lawed', 0],
  ['lawns', 553],
  ['lawny', 3],
  ['laxer', 28],
  ['laxes', 0],
  ['laxly', 2],
  ['layed', 5],
  ['layer', 9624],
  ['layin', 29],
  ['layup', 60],
  ['lazar', 988],
  ['lazed', 0],
  ['lazes', 4],
  ['leach', 1620],
  ['leads', 11811],
  ['leady', 6],
  ['leafs', 2340],
  ['leafy', 689],
  ['leaks', 586],
  ['leaky', 206],
  ['leans', 240],
  ['leant', 18],
  ['leaps', 265],
  ['leapt', 114],
  ['learn', 7638],
  ['lears', 17],
  ['leary', 691],
  ['lease', 4466],
  ['leash', 199],
  ['least', 55298],
  ['leave', 16401],
  ['leavy', 45],
  ['leben', 658],
  ['ledge', 807],
  ['ledgy', 0],
  ['leech', 931],
  ['leeks', 106],
  ['leers', 38],
  ['leery', 21],
  ['leets', 9],
  ['lefts', 39],
  ['lefty', 644],
  ['legal', 46410],
  ['leger', 1028],
  ['leges', 102],
  ['leggy', 21],
  ['legit', 83],
  ['lehrs', 23],
  ['lehua', 77],
  ['leman', 279],
  ['lemma', 1413],
  ['lemon', 3203],
  ['lemur', 987],
  ['lends', 683],
  ['lenes', 12],
  ['lenis', 56],
  ['lenos', 17],
  ['lense', 32],
  ['lento', 159],
  ['leone', 7660],
  ['leper', 305],
  ['lepta', 34],
  ['leses', 0],
  ['letch', 12],
  ['lethe', 138],
  ['letup', 0],
  ['leuds', 0],
  ['levee', 543],
  ['level', 102587],
  ['lever', 1778],
  ['levin', 1974],
  ['levis', 359],
  ['lewis', 29219],
  ['lexes', 0],
  ['lexis', 104],
  ['lezzy', 5],
  ['liana', 368],
  ['liane', 298],
  ['liang', 2428],
  ['liard', 196],
  ['liars', 635],
  ['libel', 1021],
  ['liber', 1081],
  ['libra', 337],
  ['libri', 352],
  ['lichi', 11],
  ['licht', 341],
  ['licit', 27],
  ['licks', 292],
  ['lidar', 215],
  ['lidos', 14],
  ['liege', 481],
  ['liens', 108],
  ['liers', 22],
  ['lieus', 2],
  ['lieve', 79],
  ['lifer', 28],
  ['lifts', 1575],
  ['ligan', 11],
  ['liger', 90],
  ['light', 81303],
  ['liked', 1728],
  ['liken', 48],
  ['liker', 8],
  ['likes', 2959],
  ['lilac', 837],
  ['lilos', 0],
  ['lilts', 2],
  ['liman', 279],
  ['limas', 30],
  ['limba', 99],
  ['limbi', 3],
  ['limbo', 467],
  ['limbs', 1600],
  ['limby', 4],
  ['limed', 4],
  ['limen', 39],
  ['limes', 423],
  ['limey', 43],
  ['limit', 11471],
  ['limns', 3],
  ['limos', 28],
  ['limpa', 15],
  ['limps', 2],
  ['linac', 60],
  ['lindy', 511],
  ['lined', 3029],
  ['linen', 1100],
  ['liner', 4237],
  ['lines', 39724],
  ['liney', 9],
  ['linga', 301],
  ['lingo', 268],
  ['lings', 29],
  ['lingy', 2],
  ['linin', 3],
  ['links', 13181],
  ['linky', 14],
  ['linns', 7],
  ['linos', 30],
  ['lints', 10],
  ['linty', 0],
  ['linum', 136],
  ['lions', 14827],
  ['lipid', 1202],
  ['lipin', 25],
  ['lippy', 46],
  ['liras', 43],
  ['lirot', 3],
  ['lisle', 721],
  ['lisps', 19],
  ['lists', 23973],
  ['litai', 18],
  ['litas', 61],
  ['liter', 431],
  ['lithe', 33],
  ['litho', 39],
  ['litre', 935],
  ['lived', 49123],
  ['liven', 29],
  ['liver', 4707],
  ['lives', 43494],
  ['livid', 64],
  ['livre', 724],
  ['llama', 306],
  ['llano', 720],
  ['loach', 998],
  ['loads', 1730],
  ['loafs', 6],
  ['loams', 52],
  ['loamy', 201],
  ['loans', 4101],
  ['loath', 31],
  ['lobar', 50],
  ['lobby', 2571],
  ['lobed', 626],
  ['lobes', 1918],
  ['lobos', 1080],
  ['local', 182670],
  ['lochs', 358],
  ['locks', 2525],
  ['locos', 335],
  ['locum', 124],
  ['locus', 1613],
  ['loden', 66],
  ['lodes', 46],
  ['lodge', 11022],
  ['loess', 242],
  ['lofts', 415],
  ['lofty', 664],
  ['logan', 7063],
  ['loges', 38],
  ['loggy', 14],
  ['logia', 162],
  ['logic', 8957],
  ['login', 359],
  ['logoi', 22],
  ['logon', 61],
  ['logos', 1319],
  ['loids', 0],
  ['loins', 40],
  ['lolls', 2],
  ['lolly', 117],
  ['loner', 176],
  ['longe', 70],
  ['longs', 217],
  ['looby', 41],
  ['looed', 0],
  ['looey', 7],
  ['loofa', 4],
  ['loofs', 5],
  ['looie', 3],
  ['looks', 5117],
  ['looms', 333],
  ['loons', 112],
  ['loony', 74],
  ['loops', 2087],
  ['loopy', 52],
  ['loose', 5797],
  ['loots', 49],
  ['loped', 0],
  ['loper', 94],
  ['lopes', 1535],
  ['loppy', 2],
  ['loral', 120],
  ['loran', 165],
  ['lords', 8526],
  ['lores', 89],
  ['loris', 352],
  ['lorry', 480],
  ['losel', 8],
  ['loser', 1628],
  ['loses', 1542],
  ['lossy', 256],
  ['lotah', 0],
  ['lotas', 4],
  ['lotic', 25],
  ['lotos', 75],
  ['lotte', 1202],
  ['lotto', 641],
  ['lotus', 4381],
  ['lough', 2241],
  ['louie', 1407],
  ['louis', 62286],
  ['louma', 0],
  ['loupe', 34],
  ['loups', 83],
  ['lours', 0],
  ['loury', 21],
  ['louse', 316],
  ['lousy', 58],
  ['louts', 11],
  ['lovat', 338],
  ['loved', 4126],
  ['lover', 5692],
  ['loves', 3125],
  ['lowed', 3],
  ['lower', 72230],
  ['lowes', 194],
  ['lowly', 333],
  ['lowse', 0],
  ['loxed', 0],
  ['loxes', 0],
  ['loyal', 4228],
  ['luaus', 5],
  ['lubed', 0],
  ['lubes', 8],
  ['luces', 62],
  ['lucid', 423],
  ['lucks', 28],
  ['lucky', 5031],
  ['lucre', 35],
  ['ludes', 8],
  ['ludic', 47],
  ['luffa', 45],
  ['luffs', 0],
  ['luged', 0],
  ['luger', 1553],
  ['luges', 3],
  ['lulls', 15],
  ['lulus', 5],
  ['lumas', 29],
  ['lumen', 488],
  ['lumps', 205],
  ['lumpy', 148],
  ['lunar', 7937],
  ['lunas', 145],
  ['lunch', 3527],
  ['lunes', 58],
  ['lunet', 3],
  ['lunge', 70],
  ['lungi', 63],
  ['lungs', 1595],
  ['lunks', 0],
  ['lunts', 9],
  ['lupin', 680],
  ['lupus', 1134],
  ['lurch', 74],
  ['lured', 475],
  ['lurer', 0],
  ['lures', 229],
  ['lurex', 13],
  ['lurid', 144],
  ['lurks', 61],
  ['lusts', 17],
  ['lusty', 101],
  ['lusus', 23],
  ['lutea', 477],
  ['luted', 2],
  ['lutes', 224],
  ['luxes', 0],
  ['lweis', 0],
  ['lyard', 3],
  ['lyart', 0],
  ['lyase', 496],
  ['lycea', 2],
  ['lycee', 433],
  ['lycra', 42],
  ['lying', 8759],
  ['lymph', 1076],
  ['lynch', 5356],
  ['lyres', 70],
  ['lyric', 3711],
  ['lysed', 25],
  ['lyses', 11],
  ['lysin', 20],
  ['lysis', 227],
  ['lyssa', 32],
  ['lytic', 94],
  ['lytta', 75],
  ['maars', 91],
  ['mabes', 7],
  ['macaw', 275],
  ['maced', 5],
  ['macer', 117],
  ['maces', 62],
  ['mache', 81],
  ['macho', 450],
  ['machs', 4],
  ['macks', 47],
  ['macle', 3],
  ['macon', 2001],
  ['macro', 1084],
  ['madam', 891],
  ['madly', 174],
  ['madre', 1451],
  ['mafia', 3503],
  ['mafic', 267],
  ['mages', 129],
  ['magic', 17994],
  ['magma', 1035],
  ['magot', 6],
  ['magus', 204],
  ['mahoe', 26],
  ['maids', 499],
  ['maile', 100],
  ['maill', 4],
  ['mails', 125],
  ['maims', 0],
  ['mains', 698],
  ['mairs', 26],
  ['maist', 2],
  ['maize', 2182],
  ['major', 199259],
  ['makar', 216],
  ['maker', 7576],
  ['makes', 25299],
  ['makos', 33],
  ['malar', 170],
  ['males', 13413],
  ['malic', 69],
  ['malls', 1820],
  ['malms', 2],
  ['malmy', 3],
  ['malts', 102],
  ['malty', 23],
  ['mamas', 251],
  ['mamba', 267],
  ['mambo', 636],
  ['mamey', 62],
  ['mamie', 321],
  ['mamma', 468],
  ['mammy', 124],
  ['manas', 344],
  ['manat', 88],
  ['maned', 47],
  ['manes', 172],
  ['manga', 18580],
  ['mange', 90],
  ['mango', 1759],
  ['mangy', 22],
  ['mania', 1099],
  ['manic', 636],
  ['manly', 1295],
  ['manna', 422],
  ['manor', 15082],
  ['manos', 343],
  ['manse', 353],
  ['manta', 535],
  ['manus', 527],
  ['maple', 6974],
  ['maqui', 12],
  ['maras', 114],
  ['march', 340792],
  ['marcs', 7],
  ['mares', 1000],
  ['marge', 570],
  ['maria', 39755],
  ['marka', 236],
  ['marks', 13979],
  ['marls', 72],
  ['marly', 204],
  ['marry', 3867],
  ['marse', 15],
  ['marsh', 8955],
  ['marts', 69],
  ['marvy', 2],
  ['masas', 17],
  ['maser', 118],
  ['mashy', 0],
  ['masks', 1534],
  ['mason', 10907],
  ['massa', 865],
  ['masse', 486],
  ['massy', 303],
  ['masts', 879],
  ['match', 67407],
  ['mated', 280],
  ['mater', 2948],
  ['mates', 1360],
  ['matey', 28],
  ['maths', 795],
  ['matin', 265],
  ['matte', 531],
  ['matts', 39],
  ['matza', 25],
  ['matzo', 64],
  ['mauds', 20],
  ['mauls', 14],
  ['maund', 66],
  ['mauts', 0],
  ['mauve', 419],
  ['maven', 151],
  ['mavie', 32],
  ['mavin', 74],
  ['mavis', 519],
  ['mawed', 3],
  ['maxed', 26],
  ['maxes', 3],
  ['maxim', 2530],
  ['maxis', 160],
  ['mayan', 1015],
  ['mayas', 97],
  ['maybe', 1906],
  ['mayed', 0],
  ['mayor', 53573],
  ['mayos', 19],
  ['mayst', 0],
  ['mazed', 0],
  ['mazer', 140],
  ['mazes', 184],
  ['mbira', 127],
  ['meads', 367],
  ['meals', 1939],
  ['mealy', 140],
  ['means', 54037],
  ['meant', 12527],
  ['meany', 137],
  ['meats', 771],
  ['meaty', 93],
  ['mecca', 1939],
  ['medal', 108310],
  ['media', 96011],
  ['medic', 436],
  ['medii', 58],
  ['meeds', 12],
  ['meets', 8414],
  ['meiny', 0],
  ['melds', 64],
  ['melee', 380],
  ['melic', 39],
  ['mells', 96],
  ['melon', 891],
  ['melts', 443],
  ['melty', 18],
  ['memes', 233],
  ['memos', 183],
  ['menad', 12],
  ['mends', 48],
  ['mensa', 342],
  ['mense', 37],
  ['mensh', 3],
  ['menta', 78],
  ['menus', 554],
  ['meous', 0],
  ['meows', 8],
  ['merch', 29],
  ['mercs', 18],
  ['mercy', 4519],
  ['merde', 23],
  ['merer', 10],
  ['meres', 94],
  ['merge', 3257],
  ['merit', 8413],
  ['merks', 30],
  ['merle', 1298],
  ['merls', 0],
  ['merry', 2181],
  ['mesas', 163],
  ['meshy', 0],
  ['mesic', 303],
  ['mesne', 59],
  ['meson', 229],
  ['messy', 294],
  ['metal', 48937],
  ['meted', 77],
  ['meter', 6388],
  ['metes', 22],
  ['meths', 2],
  ['metis', 369],
  ['metol', 8],
  ['metre', 23766],
  ['metro', 34171],
  ['mewed', 0],
  ['mewls', 0],
  ['mezes', 12],
  ['mezzo', 210],
  ['miaou', 2],
  ['miaow', 17],
  ['miasm', 0],
  ['miaul', 0],
  ['micas', 32],
  ['miche', 53],
  ['micra', 87],
  ['micro', 2984],
  ['middy', 14],
  ['midge', 703],
  ['midis', 7],
  ['midst', 1691],
  ['miens', 6],
  ['miffs', 0],
  ['miffy', 29],
  ['miggs', 13],
  ['might', 19886],
  ['miked', 7],
  ['mikes', 103],
  ['mikra', 61],
  ['milch', 107],
  ['milds', 6],
  ['miler', 180],
  ['miles', 123334],
  ['milia', 110],
  ['milks', 51],
  ['milky', 1961],
  ['mille', 1009],
  ['mills', 16521],
  ['milos', 597],
  ['milpa', 53],
  ['milts', 2],
  ['milty', 0],
  ['mimed', 52],
  ['mimeo', 17],
  ['mimer', 12],
  ['mimes', 58],
  ['mimic', 1365],
  ['minae', 33],
  ['minas', 4617],
  ['mince', 105],
  ['mincy', 23],
  ['minds', 2766],
  ['mined', 1787],
  ['miner', 2717],
  ['mines', 11974],
  ['mingy', 0],
  ['minim', 80],
  ['minis', 162],
  ['minke', 69],
  ['minks', 47],
  ['minny', 27],
  ['minor', 60403],
  ['mints', 394],
  ['minty', 144],
  ['minus', 1710],
  ['mired', 155],
  ['mires', 117],
  ['mirex', 11],
  ['mirin', 69],
  ['mirks', 0],
  ['mirky', 0],
  ['mirth', 130],
  ['mirza', 3504],
  ['misdo', 0],
  ['miser', 162],
  ['mises', 233],
  ['misos', 4],
  ['missy', 770],
  ['mists', 206],
  ['misty', 756],
  ['miter', 451],
  ['mites', 1376],
  ['mitis', 159],
  ['mitre', 960],
  ['mitts', 51],
  ['mixed', 38484],
  ['mixer', 1145],
  ['mixes', 2232],
  ['mixup', 38],
  ['mizen', 43],
  ['moans', 53],
  ['moats', 165],
  ['mocha', 231],
  ['mocks', 137],
  ['modal', 859],
  ['model', 74721],
  ['modem', 841],
  ['modes', 3884],
  ['modus', 360],
  ['moggy', 13],
  ['mogul', 762],
  ['mohel', 23],
  ['mohur', 21],
  ['moils', 0],
  ['moira', 875],
  ['moire', 48],
  ['moist', 12866],
  ['mojos', 33],
  ['mokes', 8],
  ['molal', 7],
  ['molar', 1223],
  ['molas', 36],
  ['molds', 476],
  ['moldy', 66],
  ['moles', 469],
  ['molls', 8],
  ['molly', 2975],
  ['molto', 214],
  ['molts', 17],
  ['momes', 3],
  ['momma', 132],
  ['mommy', 285],
  ['momus', 105],
  ['monad', 125],
  ['monas', 63],
  ['monde', 1427],
  ['mondo', 1056],
  ['money', 34696],
  ['mongo', 229],
  ['monie', 69],
  ['monks', 4702],
  ['monos', 52],
  ['monte', 10058],
  ['month', 24272],
  ['mooch', 19],
  ['moods', 620],
  ['moody', 3159],
  ['mooed', 2],
  ['moola', 62],
  ['mools', 0],
  ['moons', 1086],
  ['moony', 29],
  ['moors', 1821],
  ['moory', 3],
  ['moose', 3093],
  ['moots', 34],
  ['moped', 190],
  ['moper', 0],
  ['mopes', 6],
  ['mopey', 0],
  ['morae', 28],
  ['moral', 7661],
  ['moras', 88],
  ['moray', 2481],
  ['morel', 591],
  ['mores', 349],
  ['morns', 2],
  ['moron', 203],
  ['morph', 289],
  ['morro', 598],
  ['morse', 2849],
  ['morts', 188],
  ['mosey', 51],
  ['mosks', 0],
  ['mosso', 99],
  ['mossy', 333],
  ['moste', 30],
  ['mosts', 2],
  ['motel', 1254],
  ['motes', 29],
  ['motet', 353],
  ['motey', 0],
  ['moths', 14648],
  ['mothy', 0],
  ['motif', 3487],
  ['motor', 26129],
  ['motte', 980],
  ['motto', 5233],
  ['motts', 20],
  ['mouch', 2],
  ['moues', 0],
  ['mould', 793],
  ['moult', 126],
  ['mound', 4681],
  ['mount', 60413],
  ['mourn', 174],
  ['mouse', 9170],
  ['mousy', 29],
  ['mouth', 17062],
  ['moved', 102373],
  ['mover', 833],
  ['moves', 6860],
  ['movie', 52919],
  ['mowed', 40],
  ['mower', 433],
  ['moxas', 0],
  ['moxie', 137],
  ['mozos', 18],
  ['mucho', 175],
  ['mucid', 0],
  ['mucin', 82],
  ['mucks', 4],
  ['mucky', 71],
  ['mucor', 70],
  ['mucro', 10],
  ['mucus', 443],
  ['muddy', 1785],
  ['mudra', 147],
  ['muffs', 40],
  ['mufti', 674],
  ['muggs', 97],
  ['muggy', 16],
  ['muhly', 136],
  ['mujik', 6],
  ['mulch', 131],
  ['mulct', 6],
  ['muled', 2],
  ['mules', 535],
  ['muley', 51],
  ['mulla', 376],
  ['mulls', 7],
  ['mumms', 0],
  ['mummy', 1048],
  ['mumps', 192],
  ['mumus', 0],
  ['munch', 533],
  ['mungo', 409],
  ['munis', 66],
  ['muons', 78],
  ['mural', 2404],
  ['muras', 7],
  ['mured', 2],
  ['mures', 151],
  ['murex', 2250],
  ['murid', 87],
  ['murks', 0],
  ['murky', 169],
  ['murra', 45],
  ['murre', 45],
  ['murrs', 0],
  ['murry', 250],
  ['musca', 163],
  ['mused', 21],
  ['muser', 19],
  ['muses', 545],
  ['mushy', 34],
  ['music', 353576],
  ['musks', 17],
  ['musky', 98],
  ['mussy', 8],
  ['musth', 9],
  ['musts', 9],
  ['musty', 40],
  ['mutch', 117],
  ['muted', 324],
  ['muter', 31],
  ['mutes', 42],
  ['muton', 6],
  ['mutts', 41],
  ['muzzy', 89],
  ['mylar', 70],
  ['mynah', 41],
  ['mynas', 25],
  ['myoid', 5],
  ['myoma', 44],
  ['myope', 0],
  ['myopy', 0],
  ['myrrh', 213],
  ['mysid', 23],
  ['myths', 2270],
  ['mythy', 0],
  ['naans', 0],
  ['nabes', 0],
  ['nabis', 206],
  ['nabob', 61],
  ['nacho', 399],
  ['nacre', 60],
  ['nadas', 50],
  ['nadir', 598],
  ['naevi', 20],
  ['naffs', 0],
  ['naggy', 0],
  ['naiad', 218],
  ['naifs', 0],
  ['nails', 1563],
  ['naira', 86],
  ['nairu', 17],
  ['naive', 695],
  ['naked', 5982],
  ['nakfa', 22],
  ['nalas', 11],
  ['naled', 12],
  ['named', 253942],
  ['namer', 38],
  ['names', 65569],
  ['nanas', 59],
  ['nance', 554],
  ['nancy', 9119],
  ['nanny', 705],
  ['napas', 12],
  ['napes', 43],
  ['nappa', 51],
  ['nappe', 71],
  ['nappy', 141],
  ['narco', 81],
  ['narcs', 7],
  ['nards', 3],
  ['nares', 253],
  ['naric', 9],
  ['naris', 28],
  ['narks', 0],
  ['narky', 0],
  ['nasal', 1832],
  ['nasty', 934],
  ['natal', 2573],
  ['natch', 5],
  ['nates', 8],
  ['natty', 125],
  ['naval', 38766],
  ['navar', 15],
  ['navel', 288],
  ['naves', 226],
  ['navvy', 31],
  ['nawab', 1549],
  ['nazis', 2920],
  ['neaps', 7],
  ['nears', 104],
  ['neath', 1053],
  ['neats', 13],
  ['necks', 490],
  ['neddy', 32],
  ['needs', 16339],
  ['needy', 652],
  ['neems', 0],
  ['neeps', 6],
  ['negus', 206],
  ['neifs', 0],
  ['neigh', 15],
  ['neist', 9],
  ['nelly', 1196],
  ['nemas', 0],
  ['nenes', 7],
  ['neons', 4],
  ['nerds', 286],
  ['nerdy', 98],
  ['nerol', 12],
  ['nerts', 2],
  ['nertz', 6],
  ['nerve', 5562],
  ['nervy', 23],
  ['nests', 1837],
  ['netop', 6],
  ['netts', 0],
  ['netty', 48],
  ['neuks', 0],
  ['neume', 14],
  ['neums', 2],
  ['never', 59246],
  ['neves', 611],
  ['nevus', 269],
  ['newel', 82],
  ['newer', 4447],
  ['newie', 0],
  ['newly', 19847],
  ['newsy', 29],
  ['newts', 229],
  ['nexus', 1160],
  ['ngwee', 3],
  ['nicad', 11],
  ['nicer', 72],
  ['niche', 2008],
  ['nicks', 571],
  ['nicol', 877],
  ['nidal', 120],
  ['nided', 0],
  ['nides', 5],
  ['nidus', 33],
  ['niece', 2468],
  ['nieve', 89],
  ['nifty', 127],
  ['nighs', 0],
  ['night', 56379],
  ['nihil', 142],
  ['nills', 2],
  ['nimbi', 5],
  ['nines', 312],
  ['ninja', 3563],
  ['ninny', 10],
  ['ninon', 51],
  ['ninth', 19801],
  ['nipas', 14],
  ['nippy', 25],
  ['nisei', 158],
  ['nisus', 59],
  ['niter', 49],
  ['nites', 30],
  ['nitid', 0],
  ['niton', 53],
  ['nitre', 56],
  ['nitro', 834],
  ['nitty', 240],
  ['nival', 50],
  ['nixed', 15],
  ['nixes', 9],
  ['nixie', 56],
  ['nizam', 768],
  ['nobby', 136],
  ['noble', 14036],
  ['nobly', 35],
  ['nocks', 4],
  ['nodal', 395],
  ['noddy', 263],
  ['nodes', 3287],
  ['nodus', 24],
  ['noels', 6],
  ['noggs', 6],
  ['nohow', 9],
  ['noils', 2],
  ['noily', 0],
  ['noirs', 136],
  ['noise', 8797],
  ['noisy', 795],
  ['nolos', 0],
  ['nomad', 714],
  ['nomas', 14],
  ['nomen', 652],
  ['nomes', 37],
  ['nomoi', 19],
  ['nomos', 103],
  ['nonas', 5],
  ['nonce', 51],
  ['nones', 72],
  ['nonet', 69],
  ['nonyl', 14],
  ['nooks', 46],
  ['nooky', 0],
  ['noons', 17],
  ['noose', 202],
  ['nopal', 49],
  ['noria', 40],
  ['noris', 63],
  ['norms', 1946],
  ['north', 448580],
  ['nosed', 94],
  ['noses', 239],
  ['nosey', 34],
  ['notal', 0],
  ['notch', 1373],
  ['noted', 50657],
  ['noter', 11],
  ['notes', 15466],
  ['notum', 12],
  ['nouns', 898],
  ['novae', 353],
  ['novas', 252],
  ['novel', 104740],
  ['noway', 4],
  ['nowts', 0],
  ['nubby', 0],
  ['nubia', 490],
  ['nucha', 11],
  ['nuder', 6],
  ['nudes', 395],
  ['nudge', 58],
  ['nudie', 61],
  ['nudzh', 0],
  ['nuked', 9],
  ['nukes', 45],
  ['nulls', 38],
  ['numbs', 3],
  ['numen', 43],
  ['nurds', 2],
  ['nurls', 0],
  ['nurse', 5961],
  ['nutsy', 5],
  ['nutty', 329],
  ['nyala', 128],
  ['nylon', 855],
  ['nymph', 966],
  ['oaken', 70],
  ['oakum', 15],
  ['oared', 34],
  ['oases', 193],
  ['oasis', 2470],
  ['oasts', 6],
  ['oaten', 49],
  ['oater', 3],
  ['oaths', 386],
  ['oaves', 0],
  ['obeah', 50],
  ['obeli', 3],
  ['obese', 381],
  ['obeys', 108],
  ['obias', 2],
  ['obits', 26],
  ['objet', 63],
  ['oboes', 426],
  ['obole', 2],
  ['oboli', 32],
  ['obols', 7],
  ['occur', 18075],
  ['ocean', 39162],
  ['ocher', 74],
  ['ochre', 531],
  ['ochry', 0],
  ['ocker', 52],
  ['ocrea', 16],
  ['octad', 7],
  ['octal', 63],
  ['octan', 12],
  ['octet', 340],
  ['octyl', 49],
  ['oculi', 96],
  ['odahs', 0],
  ['odder', 82],
  ['oddly', 326],
  ['odeon', 675],
  ['odeum', 31],
  ['odist', 0],
  ['odium', 51],
  ['odors', 181],
  ['odour', 318],
  ['odyle', 2],
  ['odyls', 0],
  ['ofays', 0],
  ['offal', 169],
  ['offed', 4],
  ['offer', 15877],
  ['often', 142319],
  ['ofter', 3],
  ['ogams', 2],
  ['ogees', 2],
  ['ogham', 199],
  ['ogive', 38],
  ['ogled', 5],
  ['ogler', 0],
  ['ogles', 21],
  ['ogres', 86],
  ['ohias', 0],
  ['ohing', 0],
  ['ohmic', 49],
  ['oidia', 3],
  ['oiled', 90],
  ['oiler', 572],
  ['oinks', 2],
  ['okapi', 106],
  ['okays', 2],
  ['okehs', 0],
  ['okras', 0],
  ['olden', 294],
  ['older', 27282],
  ['oldie', 73],
  ['oleic', 72],
  ['olein', 9],
  ['oleos', 2],
  ['oleum', 46],
  ['olios', 38],
  ['olive', 5937],
  ['ollas', 17],
  ['ology', 33],
  ['omasa', 5],
  ['omber', 0],
  ['ombre', 57],
  ['omega', 4227],
  ['omens', 177],
  ['omers', 26],
  ['omits', 386],
  ['oncet', 0],
  ['onery', 0],
  ['onion', 2408],
  ['onium', 33],
  ['onlay', 9],
  ['onset', 2502],
  ['ontic', 18],
  ['oohed', 0],
  ['oomph', 61],
  ['oorie', 0],
  ['ootid', 2],
  ['oozed', 14],
  ['oozes', 45],
  ['opahs', 8],
  ['opals', 67],
  ['opens', 3567],
  ['opera', 51886],
  ['opine', 54],
  ['oping', 0],
  ['opium', 1474],
  ['opsin', 38],
  ['opted', 2021],
  ['optic', 1417],
  ['orach', 51],
  ['orals', 2],
  ['orang', 391],
  ['orate', 11],
  ['orbed', 6],
  ['orbit', 8999],
  ['orcas', 211],
  ['orcin', 3],
  ['order', 128558],
  ['ordos', 212],
  ['oread', 68],
  ['organ', 12719],
  ['orgic', 0],
  ['oribi', 40],
  ['oriel', 834],
  ['orles', 2],
  ['orlon', 2],
  ['orlop', 8],
  ['ormer', 27],
  ['ornis', 49],
  ['orpin', 8],
  ['orris', 66],
  ['ortho', 187],
  ['orzos', 0],
  ['osier', 57],
  ['osmic', 9],
  ['osmol', 8],
  ['ossia', 80],
  ['ostia', 354],
  ['other', 510524],
  ['ottar', 104],
  ['otter', 2272],
  ['ottos', 14],
  ['ought', 868],
  ['ounce', 397],
  ['ouphe', 0],
  ['ouphs', 0],
  ['ourie', 0],
  ['ousel', 9],
  ['ousts', 4],
  ['outby', 0],
  ['outdo', 51],
  ['outed', 91],
  ['outer', 14622],
  ['outgo', 2],
  ['outre', 13],
  ['ouzel', 48],
  ['ouzos', 0],
  ['ovals', 197],
  ['ovary', 569],
  ['ovate', 568],
  ['ovens', 827],
  ['overs', 4192],
  ['overt', 532],
  ['ovine', 51],
  ['ovoid', 440],
  ['ovoli', 0],
  ['ovolo', 20],
  ['ovule', 43],
  ['owing', 3927],
  ['owlet', 644],
  ['owned', 98048],
  ['owner', 30915],
  ['owsen', 4],
  ['oxbow', 436],
  ['oxeye', 40],
  ['oxide', 3301],
  ['oxids', 0],
  ['oxime', 120],
  ['oxims', 0],
  ['oxlip', 28],
  ['oxter', 2],
  ['oyers', 0],
  ['ozone', 1055],
  ['pacas', 23],
  ['paced', 249],
  ['pacer', 331],
  ['paces', 184],
  ['pacey', 79],
  ['pacha', 258],
  ['packs', 1203],
  ['pacts', 122],
  ['paddy', 3235],
  ['padis', 5],
  ['padle', 0],
  ['padre', 1048],
  ['padri', 73],
  ['paean', 81],
  ['paeon', 49],
  ['pagan', 2935],
  ['paged', 39],
  ['pager', 127],
  ['pages', 10164],
  ['pagod', 0],
  ['paiks', 4],
  ['pails', 41],
  ['pains', 773],
  ['paint', 6601],
  ['pairs', 8547],
  ['paisa', 208],
  ['paise', 50],
  ['palea', 33],
  ['paled', 24],
  ['paler', 1806],
  ['pales', 84],
  ['palet', 14],
  ['palls', 2],
  ['pally', 150],
  ['palms', 1722],
  ['palmy', 18],
  ['palpi', 192],
  ['palps', 101],
  ['palsy', 1115],
  ['pampa', 981],
  ['panda', 1686],
  ['pandy', 76],
  ['paned', 23],
  ['panel', 12298],
  ['panes', 261],
  ['panga', 81],
  ['pangs', 44],
  ['panic', 2936],
  ['panne', 197],
  ['pansy', 330],
  ['panto', 84],
  ['pants', 1398],
  ['panty', 100],
  ['papal', 5115],
  ['papas', 317],
  ['papaw', 22],
  ['paper', 31443],
  ['pappi', 40],
  ['pappy', 233],
  ['parae', 7],
  ['paras', 277],
  ['parch', 17],
  ['pardi', 64],
  ['pards', 4],
  ['pardy', 50],
  ['pared', 107],
  ['pareo', 9],
  ['parer', 65],
  ['pares', 175],
  ['pareu', 3],
  ['parge', 4],
  ['pargo', 53],
  ['paris', 69988],
  ['parka', 140],
  ['parks', 19258],
  ['parle', 351],
  ['parol', 30],
  ['parrs', 34],
  ['parry', 2362],
  ['parse', 220],
  ['parts', 64339],
  ['party', 266614],
  ['parve', 16],
  ['parvo', 23],
  ['paseo', 534],
  ['pases', 6],
  ['pasha', 4801],
  ['passe', 193],
  ['pasta', 1072],
  ['paste', 1732],
  ['pasts', 87],
  ['pasty', 88],
  ['patch', 5532],
  ['pated', 0],
  ['paten', 46],
  ['pater', 328],
  ['pates', 34],
  ['paths', 3806],
  ['patin', 82],
  ['patio', 478],
  ['patly', 0],
  ['patsy', 1156],
  ['patty', 1772],
  ['pause', 725],
  ['pavan', 214],
  ['paved', 3748],
  ['paver', 54],
  ['paves', 40],
  ['pavid', 0],
  ['pavin', 58],
  ['pavis', 18],
  ['pawed', 2],
  ['pawer', 0],
  ['pawky', 0],
  ['pawls', 0],
  ['pawns', 299],
  ['paxes', 0],
  ['payed', 2],
  ['payee', 66],
  ['payer', 203],
  ['payor', 11],
  ['peace', 34572],
  ['peach', 2300],
  ['peage', 2],
  ['peags', 0],
  ['peaks', 8467],
  ['peaky', 45],
  ['peals', 23],
  ['peans', 0],
  ['pearl', 8466],
  ['pears', 660],
  ['peart', 211],
  ['pease', 725],
  ['peats', 54],
  ['peaty', 124],
  ['peavy', 58],
  ['pecan', 399],
  ['pechs', 8],
  ['pecks', 52],
  ['pecky', 9],
  ['pedal', 1429],
  ['pedes', 21],
  ['pedro', 14959],
  ['peeks', 32],
  ['peels', 115],
  ['peens', 9],
  ['peeps', 67],
  ['peers', 3376],
  ['peery', 77],
  ['peeve', 14],
  ['peins', 2],
  ['peise', 0],
  ['pekan', 220],
  ['pekes', 9],
  ['pekin', 317],
  ['pekoe', 20],
  ['peles', 26],
  ['pelfs', 0],
  ['pelon', 12],
  ['pelts', 127],
  ['penal', 2157],
  ['pence', 969],
  ['pends', 0],
  ['penes', 6],
  ['pengo', 32],
  ['penna', 296],
  ['penne', 193],
  ['penni', 47],
  ['penny', 3717],
  ['peons', 14],
  ['peony', 278],
  ['pepla', 5],
  ['pepos', 2],
  ['peppy', 93],
  ['perch', 1454],
  ['perdu', 127],
  ['perdy', 2],
  ['perea', 194],
  ['peres', 448],
  ['peril', 610],
  ['peris', 149],
  ['perks', 238],
  ['perky', 95],
  ['perms', 5],
  ['perps', 4],
  ['perry', 12013],
  ['perse', 117],
  ['pervs', 2],
  ['pesky', 64],
  ['pesos', 524],
  ['pesto', 58],
  ['pests', 1014],
  ['pesty', 0],
  ['petal', 388],
  ['peter', 80313],
  ['petit', 2972],
  ['petti', 29],
  ['petto', 30],
  ['petty', 2995],
  ['pewee', 133],
  ['pewit', 7],
  ['phage', 583],
  ['phase', 18851],
  ['phial', 12],
  ['phlox', 468],
  ['phone', 10644],
  ['phono', 41],
  ['phons', 7],
  ['phony', 155],
  ['photo', 7183],
  ['phots', 0],
  ['phpht', 0],
  ['phuts', 0],
  ['phyla', 328],
  ['phyle', 155],
  ['piano', 24158],
  ['pians', 6],
  ['pibal', 6],
  ['pical', 4],
  ['picas', 20],
  ['picks', 2652],
  ['picky', 28],
  ['picot', 94],
  ['picul', 8],
  ['piece', 20063],
  ['piers', 2674],
  ['pieta', 122],
  ['piety', 774],
  ['piggy', 277],
  ['pigmy', 144],
  ['piing', 0],
  ['pikas', 31],
  ['piked', 21],
  ['piker', 17],
  ['pikes', 522],
  ['pikis', 5],
  ['pilaf', 46],
  ['pilar', 1285],
  ['pilau', 8],
  ['pilaw', 0],
  ['pilea', 99],
  ['piled', 218],
  ['pilei', 3],
  ['piles', 692],
  ['pilis', 51],
  ['pills', 676],
  ['pilot', 21018],
  ['pilus', 62],
  ['pimas', 7],
  ['pimps', 176],
  ['pinas', 87],
  ['pinch', 1385],
  ['pined', 11],
  ['pines', 2284],
  ['piney', 585],
  ['pingo', 75],
  ['pings', 24],
  ['pinko', 20],
  ['pinks', 94],
  ['pinky', 540],
  ['pinna', 153],
  ['pinny', 18],
  ['pinon', 104],
  ['pinot', 639],
  ['pinta', 162],
  ['pinto', 2310],
  ['pints', 138],
  ['pinup', 53],
  ['pions', 56],
  ['pious', 1208],
  ['pipal', 38],
  ['piped', 255],
  ['piper', 3119],
  ['pipes', 2640],
  ['pipet', 4],
  ['pipit', 277],
  ['pique', 158],
  ['pirns', 8],
  ['pirog', 31],
  ['pisco', 195],
  ['pisos', 0],
  ['piste', 99],
  ['pitas', 33],
  ['pitch', 7940],
  ['piths', 0],
  ['pithy', 82],
  ['piton', 143],
  ['pitta', 320],
  ['pivot', 873],
  ['pixel', 1380],
  ['pixes', 0],
  ['pixie', 398],
  ['pizza', 3007],
  ['place', 225174],
  ['plack', 34],
  ['plage', 148],
  ['plaid', 786],
  ['plain', 14485],
  ['plait', 54],
  ['plane', 11769],
  ['plank', 996],
  ['plans', 21497],
  ['plant', 88729],
  ['plash', 0],
  ['plasm', 40],
  ['plate', 11950],
  ['plats', 100],
  ['platy', 79],
  ['playa', 1232],
  ['plays', 141603],
  ['plaza', 11332],
  ['plead', 332],
  ['pleas', 1361],
  ['pleat', 26],
  ['plebe', 41],
  ['plebs', 278],
  ['plena', 128],
  ['pleon', 20],
  ['plews', 19],
  ['plica', 70],
  ['plied', 174],
  ['plier', 12],
  ['plies', 171],
  ['plink', 13],
  ['plods', 4],
  ['plonk', 21],
  ['plops', 3],
  ['plots', 2270],
  ['plotz', 20],
  ['plows', 84],
  ['ploys', 24],
  ['pluck', 149],
  ['plugs', 481],
  ['plumb', 403],
  ['plume', 1223],
  ['plump', 212],
  ['plums', 250],
  ['plumy', 0],
  ['plunk', 38],
  ['plush', 363],
  ['plyer', 0],
  ['poach', 22],
  ['poboy', 0],
  ['pocks', 3],
  ['pocky', 45],
  ['podgy', 0],
  ['podia', 19],
  ['poems', 15916],
  ['poesy', 26],
  ['poets', 8361],
  ['pogey', 2],
  ['poilu', 13],
  ['poind', 0],
  ['point', 101728],
  ['poise', 95],
  ['poked', 77],
  ['poker', 6827],
  ['pokes', 129],
  ['pokey', 139],
  ['polar', 6096],
  ['poled', 32],
  ['poler', 0],
  ['poles', 4229],
  ['polio', 668],
  ['polis', 683],
  ['polka', 974],
  ['polls', 3507],
  ['polos', 30],
  ['polyp', 179],
  ['polys', 28],
  ['pomes', 35],
  ['pommy', 19],
  ['pomos', 12],
  ['pomps', 11],
  ['ponce', 2763],
  ['ponds', 3633],
  ['pones', 4],
  ['pongs', 3],
  ['pooch', 98],
  ['poods', 14],
  ['pooed', 0],
  ['poofs', 7],
  ['poofy', 3],
  ['poohs', 0],
  ['pools', 4438],
  ['poons', 18],
  ['poops', 5],
  ['poori', 28],
  ['poove', 46],
  ['popes', 896],
  ['poppa', 93],
  ['poppy', 1366],
  ['popsy', 17],
  ['porch', 6105],
  ['pored', 61],
  ['pores', 681],
  ['porgy', 337],
  ['porks', 0],
  ['porky', 356],
  ['porns', 0],
  ['porny', 0],
  ['ports', 6847],
  ['posed', 1934],
  ['poser', 85],
  ['poses', 958],
  ['posit', 113],
  ['posse', 1023],
  ['posts', 7641],
  ['potsy', 27],
  ['potto', 39],
  ['potty', 112],
  ['pouch', 685],
  ['pouff', 0],
  ['poufs', 0],
  ['poult', 4],
  ['pound', 3662],
  ['pours', 122],
  ['pouts', 4],
  ['pouty', 3],
  ['power', 115308],
  ['poxed', 0],
  ['poxes', 3],
  ['poyou', 0],
  ['praam', 2],
  ['prahu', 7],
  ['prams', 31],
  ['prang', 65],
  ['prank', 641],
  ['praos', 0],
  ['prase', 6],
  ['prate', 5],
  ['prats', 215],
  ['praus', 6],
  ['prawn', 439],
  ['prays', 234],
  ['preed', 0],
  ['preen', 62],
  ['prees', 47],
  ['preop', 0],
  ['preps', 43],
  ['presa', 145],
  ['prese', 22],
  ['press', 53101],
  ['prest', 101],
  ['prexy', 6],
  ['preys', 211],
  ['price', 23091],
  ['pricy', 2],
  ['pride', 7454],
  ['pried', 6],
  ['prier', 26],
  ['pries', 42],
  ['prigs', 4],
  ['prill', 21],
  ['prima', 1942],
  ['prime', 46249],
  ['primi', 67],
  ['primo', 942],
  ['primp', 0],
  ['prims', 24],
  ['prink', 11],
  ['print', 15146],
  ['prion', 188],
  ['prior', 61125],
  ['prise', 93],
  ['prism', 1618],
  ['priss', 6],
  ['privy', 3643],
  ['prize', 58187],
  ['proas', 7],
  ['probe', 2414],
  ['prods', 37],
  ['proem', 18],
  ['profs', 45],
  ['progs', 12],
  ['prole', 23],
  ['promo', 1261],
  ['proms', 476],
  ['prone', 2555],
  ['prong', 292],
  ['proof', 5621],
  ['props', 785],
  ['prose', 4985],
  ['proso', 21],
  ['pross', 18],
  ['prost', 429],
  ['prosy', 2],
  ['proud', 2540],
  ['prove', 4487],
  ['prowl', 75],
  ['prows', 20],
  ['proxy', 1246],
  ['prude', 34],
  ['prune', 126],
  ['pruta', 9],
  ['pryer', 50],
  ['psalm', 1848],
  ['pseud', 29],
  ['pshaw', 0],
  ['psoae', 0],
  ['psoai', 0],
  ['psoas', 59],
  ['psych', 310],
  ['pubes', 12],
  ['pubic', 324],
  ['pubis', 169],
  ['puces', 5],
  ['pucka', 12],
  ['pucks', 41],
  ['pudgy', 74],
  ['pudic', 6],
  ['puffs', 194],
  ['puffy', 279],
  ['puggy', 41],
  ['pujah', 5],
  ['pujas', 49],
  ['puked', 2],
  ['pukes', 2],
  ['pukka', 41],
  ['puled', 0],
  ['puler', 0],
  ['pules', 0],
  ['pulik', 2],
  ['pulis', 102],
  ['pulls', 500],
  ['pulps', 108],
  ['pulpy', 24],
  ['pulse', 2960],
  ['pumas', 543],
  ['pumps', 1706],
  ['punas', 0],
  ['punch', 2922],
  ['pungs', 2],
  ['punji', 22],
  ['punka', 5],
  ['punks', 370],
  ['punky', 129],
  ['punny', 6],
  ['punto', 294],
  ['punts', 237],
  ['punty', 6],
  ['pupae', 206],
  ['pupal', 276],
  ['pupas', 2],
  ['pupil', 4782],
  ['puppy', 881],
  ['pupus', 0],
  ['purda', 92],
  ['puree', 47],
  ['purer', 63],
  ['purge', 1319],
  ['purin', 21],
  ['puris', 15],
  ['purls', 6],
  ['purrs', 9],
  ['purse', 2467],
  ['pursy', 0],
  ['purty', 3],
  ['puses', 0],
  ['pushy', 23],
  ['puton', 4],
  ['putti', 125],
  ['putto', 26],
  ['putts', 42],
  ['putty', 193],
  ['pygmy', 1892],
  ['pyins', 0],
  ['pylon', 424],
  ['pyoid', 0],
  ['pyran', 20],
  ['pyres', 22],
  ['pyrex', 38],
  ['pyric', 0],
  ['pyros', 65],
  ['pyxes', 3],
  ['pyxie', 4],
  ['pyxis', 112],
  ['qadis', 17],
  ['qaids', 0],
  ['qanat', 161],
  ['qophs', 0],
  ['quack', 272],
  ['quads', 125],
  ['quaff', 2],
  ['quags', 2],
  ['quail', 861],
  ['quais', 19],
  ['quake', 871],
  ['quaky', 0],
  ['quale', 37],
  ['qualm', 3],
  ['quant', 156],
  ['quare', 36],
  ['quark', 764],
  ['quart', 174],
  ['quash', 66],
  ['quasi', 324],
  ['quass', 3],
  ['quate', 10],
  ['quays', 369],
  ['qubit', 124],
  ['quean', 4],
  ['queen', 47376],
  ['quell', 343],
  ['quern', 21],
  ['query', 1390],
  ['quest', 6292],
  ['queue', 859],
  ['queys', 0],
  ['quick', 5568],
  ['quids', 10],
  ['quiet', 4541],
  ['quiff', 14],
  ['quill', 722],
  ['quilt', 494],
  ['quins', 120],
  ['quint', 210],
  ['quips', 55],
  ['quipu', 20],
  ['quire', 76],
  ['quirk', 353],
  ['quirt', 28],
  ['quite', 12620],
  ['quits', 260],
  ['quods', 3],
  ['quoin', 92],
  ['quoit', 51],
  ['quoll', 50],
  ['quota', 1804],
  ['quote', 1889],
  ['quoth', 17],
  ['qursh', 0],
  ['rabat', 1061],
  ['rabbi', 11330],
  ['rabic', 5],
  ['rabid', 159],
  ['raced', 5003],
  ['racer', 8857],
  ['races', 24999],
  ['racks', 489],
  ['racon', 21],
  ['radar', 8628],
  ['radii', 338],
  ['radio', 156376],
  ['radix', 299],
  ['radon', 287],
  ['raffs', 0],
  ['rafts', 323],
  ['ragas', 282],
  ['raged', 199],
  ['ragee', 6],
  ['rages', 61],
  ['raggs', 16],
  ['raggy', 17],
  ['ragis', 3],
  ['raias', 0],
  ['raids', 3223],
  ['rails', 1846],
  ['rains', 2074],
  ['rainy', 2087],
  ['raise', 9146],
  ['raita', 59],
  ['rajah', 653],
  ['rajas', 154],
  ['rajes', 16],
  ['raked', 136],
  ['rakee', 0],
  ['raker', 58],
  ['rakes', 144],
  ['rakis', 7],
  ['rakus', 4],
  ['rales', 28],
  ['rally', 12260],
  ['ralph', 14426],
  ['ramal', 128],
  ['ramee', 13],
  ['ramen', 408],
  ['ramet', 15],
  ['ramie', 37],
  ['rammy', 10],
  ['ramps', 804],
  ['ramus', 283],
  ['rance', 150],
  ['ranch', 8475],
  ['rands', 85],
  ['randy', 5489],
  ['ranee', 59],
  ['range', 92838],
  ['rangy', 21],
  ['ranid', 17],
  ['ranis', 17],
  ['ranks', 12514],
  ['rants', 116],
  ['raped', 992],
  ['raper', 114],
  ['rapes', 335],
  ['raphe', 169],
  ['rapid', 15800],
  ['rared', 0],
  ['rarer', 555],
  ['rares', 37],
  ['rased', 5],
  ['raser', 13],
  ['rases', 0],
  ['rasps', 10],
  ['raspy', 118],
  ['ratal', 0],
  ['ratan', 360],
  ['ratch', 6],
  ['rated', 9539],
  ['ratel', 83],
  ['rater', 70],
  ['rates', 9301],
  ['rathe', 8],
  ['ratio', 11287],
  ['ratos', 42],
  ['ratty', 32],
  ['raved', 28],
  ['ravel', 509],
  ['raven', 2979],
  ['raver', 85],
  ['raves', 132],
  ['ravin', 91],
  ['rawer', 77],
  ['rawin', 0],
  ['rawly', 4],
  ['raxed', 0],
  ['raxes', 0],
  ['rayah', 27],
  ['rayas', 22],
  ['rayed', 52],
  ['rayon', 5736],
  ['razed', 916],
  ['razee', 17],
  ['razer', 92],
  ['razes', 11],
  ['razor', 1030],
  ['reach', 27252],
  ['react', 1277],
  ['readd', 0],
  ['reads', 3265],
  ['ready', 6796],
  ['realm', 4805],
  ['reals', 151],
  ['reams', 84],
  ['reaps', 23],
  ['rearm', 23],
  ['rears', 35],
  ['reata', 26],
  ['reave', 6],
  ['rebar', 127],
  ['rebbe', 836],
  ['rebec', 29],
  ['rebel', 7110],
  ['rebid', 32],
  ['rebop', 14],
  ['rebus', 270],
  ['rebut', 53],
  ['rebuy', 12],
  ['recap', 263],
  ['recce', 56],
  ['recit', 6],
  ['recks', 0],
  ['recon', 336],
  ['recta', 155],
  ['recti', 40],
  ['recto', 183],
  ['recur', 187],
  ['recut', 95],
  ['redan', 102],
  ['redds', 3],
  ['reded', 0],
  ['redes', 41],
  ['redia', 3],
  ['redid', 15],
  ['redip', 0],
  ['redly', 0],
  ['redon', 124],
  ['redos', 3],
  ['redox', 450],
  ['redry', 0],
  ['redub', 9],
  ['redux', 288],
  ['redye', 0],
  ['reeds', 767],
  ['reedy', 409],
  ['reefs', 2841],
  ['reefy', 0],
  ['reeks', 73],
  ['reeky', 0],
  ['reels', 579],
  ['reest', 6],
  ['reeve', 1770],
  ['refed', 0],
  ['refel', 0],
  ['refer', 42520],
  ['refit', 548],
  ['refix', 5],
  ['refly', 0],
  ['refry', 0],
  ['regal', 1302],
  ['reges', 28],
  ['regma', 0],
  ['regna', 17],
  ['rehab', 513],
  ['rehem', 3],
  ['reifs', 3],
  ['reify', 5],
  ['reign', 19627],
  ['reink', 2],
  ['reins', 536],
  ['reive', 5],
  ['rejig', 0],
  ['rekey', 3],
  ['relax', 587],
  ['relay', 20860],
  ['relet', 0],
  ['relic', 967],
  ['relit', 104],
  ['reman', 17],
  ['remap', 12],
  ['remet', 0],
  ['remex', 8],
  ['remit', 578],
  ['remix', 8488],
  ['renal', 1664],
  ['rends', 3],
  ['renew', 1266],
  ['renig', 0],
  ['renin', 81],
  ['rente', 7],
  ['rents', 703],
  ['reoil', 0],
  ['repay', 484],
  ['repeg', 0],
  ['repel', 409],
  ['repin', 136],
  ['reply', 1250],
  ['repos', 88],
  ['repot', 2],
  ['repps', 11],
  ['repro', 16],
  ['reran', 53],
  ['rerig', 0],
  ['rerun', 342],
  ['resat', 15],
  ['resaw', 5],
  ['resay', 0],
  ['resee', 0],
  ['reset', 711],
  ['resew', 0],
  ['resid', 14],
  ['resin', 1449],
  ['resit', 22],
  ['resod', 0],
  ['resow', 0],
  ['rests', 1929],
  ['retag', 0],
  ['retax', 0],
  ['retch', 10],
  ['retem', 0],
  ['retia', 4],
  ['retie', 14],
  ['retro', 1212],
  ['retry', 56],
  ['reuse', 1077],
  ['revel', 330],
  ['revet', 7],
  ['revue', 2770],
  ['rewan', 12],
  ['rewax', 0],
  ['rewed', 0],
  ['rewet', 0],
  ['rewin', 0],
  ['rewon', 0],
  ['rexes', 2],
  ['rheas', 24],
  ['rheme', 8],
  ['rheum', 84],
  ['rhino', 1444],
  ['rhomb', 63],
  ['rhumb', 24],
  ['rhyme', 1705],
  ['rhyta', 4],
  ['rials', 67],
  ['riant', 6],
  ['riata', 240],
  ['ribby', 12],
  ['ribes', 442],
  ['riced', 9],
  ['ricer', 10],
  ['rices', 57],
  ['ricin', 78],
  ['ricks', 291],
  ['rider', 10730],
  ['rides', 5066],
  ['ridge', 25284],
  ['ridgy', 2],
  ['riels', 5],
  ['rifer', 3],
  ['riffs', 656],
  ['rifle', 13765],
  ['rifts', 150],
  ['right', 69824],
  ['rigid', 2284],
  ['rigor', 383],
  ['riled', 21],
  ['riles', 43],
  ['riley', 4772],
  ['rille', 183],
  ['rills', 15],
  ['rimed', 14],
  ['rimer', 44],
  ['rimes', 332],
  ['rinds', 49],
  ['rindy', 7],
  ['rings', 6850],
  ['rinks', 293],
  ['rinse', 163],
  ['rioja', 1220],
  ['riots', 4266],
  ['riped', 0],
  ['ripen', 180],
  ['riper', 49],
  ['ripes', 0],
  ['risen', 1733],
  ['riser', 300],
  ['rises', 8821],
  ['rishi', 899],
  ['risks', 3118],
  ['risky', 660],
  ['risus', 15],
  ['rites', 2064],
  ['ritzy', 35],
  ['rival', 10018],
  ['rived', 4],
  ['riven', 106],
  ['river', 347922],
  ['rives', 219],
  ['rivet', 198],
  ['riyal', 30],
  ['roach', 2083],
  ['roads', 20840],
  ['roams', 71],
  ['roans', 9],
  ['roars', 70],
  ['roast', 599],
  ['robed', 34],
  ['robes', 569],
  ['robin', 13835],
  ['roble', 125],
  ['robot', 5580],
  ['rocks', 15000],
  ['rocky', 15655],
  ['rodeo', 2170],
  ['rodes', 94],
  ['roger', 20667],
  ['rogue', 2235],
  ['roils', 2],
  ['roily', 0],
  ['roles', 52091],
  ['rolfs', 19],
  ['rolls', 2501],
  ['roman', 93793],
  ['romeo', 4151],
  ['romps', 11],
  ['rondo', 623],
  ['roods', 25],
  ['roofs', 1829],
  ['rooks', 273],
  ['rooky', 8],
  ['rooms', 11963],
  ['roomy', 43],
  ['roose', 124],
  ['roost', 517],
  ['roots', 14186],
  ['rooty', 59],
  ['roped', 121],
  ['roper', 962],
  ['ropes', 993],
  ['ropey', 2],
  ['roque', 1240],
  ['rosed', 4],
  ['roses', 4448],
  ['roset', 25],
  ['roshi', 189],
  ['rosin', 171],
  ['rotas', 26],
  ['rotch', 72],
  ['rotes', 63],
  ['rotis', 28],
  ['rotls', 0],
  ['rotor', 1439],
  ['rotos', 18],
  ['rotte', 24],
  ['rouen', 1563],
  ['roues', 9],
  ['rouge', 4965],
  ['rough', 8041],
  ['round', 90666],
  ['roups', 0],
  ['roupy', 4],
  ['rouse', 1050],
  ['roust', 3],
  ['route', 111546],
  ['routh', 148],
  ['routs', 19],
  ['roved', 12],
  ['roven', 35],
  ['rover', 3036],
  ['roves', 3],
  ['rowan', 2201],
  ['rowdy', 476],
  ['rowed', 676],
  ['rowel', 15],
  ['rowen', 133],
  ['rower', 11787],
  ['rowth', 0],
  ['royal', 140803],
  ['ruana', 11],
  ['rubby', 16],
  ['rubel', 204],
  ['rubes', 38],
  ['ruble', 194],
  ['rubus', 1449],
  ['ruche', 17],
  ['rucks', 15],
  ['rudds', 9],
  ['ruddy', 374],
  ['ruder', 67],
  ['ruers', 0],
  ['ruffe', 45],
  ['ruffs', 33],
  ['rugae', 27],
  ['rugal', 15],
  ['rugby', 95075],
  ['ruing', 16],
  ['ruins', 8475],
  ['ruled', 16688],
  ['ruler', 11434],
  ['rules', 43490],
  ['rumba', 450],
  ['rumen', 212],
  ['rummy', 177],
  ['rumor', 511],
  ['rumps', 23],
  ['runes', 338],
  ['rungs', 55],
  ['runic', 711],
  ['runny', 57],
  ['runts', 21],
  ['runty', 0],
  ['rupee', 369],
  ['rural', 134421],
  ['ruses', 33],
  ['rushy', 25],
  ['rusks', 12],
  ['rusts', 55],
  ['rusty', 1658],
  ['ruths', 5],
  ['rutin', 13],
  ['rutty', 14],
  ['ryked', 0],
  ['rykes', 0],
  ['rynds', 0],
  ['ryots', 7],
  ['sabal', 134],
  ['sabed', 0],
  ['saber', 762],
  ['sabes', 32],
  ['sabin', 470],
  ['sabir', 284],
  ['sable', 924],
  ['sabot', 114],
  ['sabra', 199],
  ['sabre', 2754],
  ['sacks', 1059],
  ['sacra', 429],
  ['sades', 0],
  ['sadhe', 3],
  ['sadhu', 313],
  ['sadis', 12],
  ['sadly', 324],
  ['safer', 1289],
  ['safes', 82],
  ['sagas', 637],
  ['sager', 298],
  ['sages', 546],
  ['saggy', 10],
  ['sagos', 5],
  ['sagum', 5],
  ['sahib', 2148],
  ['saice', 5],
  ['saids', 8],
  ['saiga', 100],
  ['sails', 1339],
  ['sains', 186],
  ['saint', 74366],
  ['saith', 46],
  ['sajou', 0],
  ['saker', 105],
  ['sakes', 15],
  ['sakis', 174],
  ['salad', 1510],
  ['salal', 141],
  ['salep', 7],
  ['sales', 26799],
  ['salic', 93],
  ['sally', 4619],
  ['salmi', 99],
  ['salol', 8],
  ['salon', 3413],
  ['salpa', 45],
  ['salps', 27],
  ['salsa', 1627],
  ['salts', 1648],
  ['salty', 837],
  ['salve', 300],
  ['salvo', 568],
  ['samba', 1429],
  ['sambo', 538],
  ['samek', 11],
  ['samps', 0],
  ['sands', 3906],
  ['sandy', 11394],
  ['saned', 0],
  ['saner', 27],
  ['sanes', 17],
  ['sanga', 415],
  ['sangh', 657],
  ['santo', 8400],
  ['sapid', 2],
  ['sapor', 18],
  ['sappy', 53],
  ['saran', 546],
  ['sards', 4],
  ['saree', 150],
  ['sarge', 126],
  ['sargo', 37],
  ['sarin', 246],
  ['saris', 113],
  ['sarks', 3],
  ['sarky', 3],
  ['sarod', 126],
  ['saros', 494],
  ['sasin', 37],
  ['sassy', 229],
  ['satay', 95],
  ['sated', 11],
  ['satem', 32],
  ['sates', 12],
  ['satin', 602],
  ['satis', 81],
  ['satyr', 338],
  ['sauce', 3813],
  ['sauch', 4],
  ['saucy', 112],
  ['saugh', 5],
  ['sauls', 21],
  ['sault', 1103],
  ['sauna', 359],
  ['saury', 40],
  ['saute', 11],
  ['saved', 5118],
  ['saver', 197],
  ['saves', 2080],
  ['savin', 307],
  ['savor', 30],
  ['savoy', 3330],
  ['savvy', 257],
  ['sawed', 52],
  ['sawer', 18],
  ['saxes', 60],
  ['sayed', 857],
  ['sayer', 417],
  ['sayid', 110],
  ['sayst', 0],
  ['scabs', 41],
  ['scads', 22],
  ['scags', 0],
  ['scald', 45],
  ['scale', 19671],
  ['scall', 0],
  ['scalp', 521],
  ['scaly', 459],
  ['scamp', 117],
  ['scams', 264],
  ['scans', 517],
  ['scant', 283],
  ['scape', 360],
  ['scare', 839],
  ['scarf', 411],
  ['scarp', 500],
  ['scars', 814],
  ['scart', 27],
  ['scary', 787],
  ['scats', 26],
  ['scatt', 6],
  ['scaup', 38],
  ['scaur', 22],
  ['scena', 66],
  ['scend', 4],
  ['scene', 24046],
  ['scent', 1078],
  ['schav', 2],
  ['schmo', 31],
  ['schul', 26],
  ['schwa', 95],
  ['scion', 723],
  ['scoff', 17],
  ['scold', 22],
  ['scone', 330],
  ['scoop', 661],
  ['scoot', 83],
  ['scope', 5589],
  ['scops', 269],
  ['score', 36399],
  ['scorn', 201],
  ['scots', 4870],
  ['scour', 108],
  ['scout', 10531],
  ['scowl', 16],
  ['scows', 19],
  ['scrag', 15],
  ['scram', 49],
  ['scrap', 2433],
  ['scree', 227],
  ['screw', 210],
  ['scrim', 33],
  ['scrip', 131],
  ['scrod', 11],
  ['scrub', 2592],
  ['scrum', 528],
  ['scuba', 1102],
  ['scudi', 48],
  ['scudo', 46],
  ['scuds', 16],
  ['scuff', 13],
  ['sculk', 0],
  ['scull', 484],
  ['sculp', 6],
  ['scums', 7],
  ['scups', 0],
  ['scurf', 15],
  ['scuta', 8],
  ['scute', 35],
  ['scuts', 0],
  ['scuzz', 31],
  ['seals', 3023],
  ['seams', 380],
  ['seamy', 8],
  ['sears', 2538],
  ['seats', 31630],
  ['sebum', 60],
  ['secco', 121],
  ['sects', 753],
  ['sedan', 2233],
  ['seder', 294],
  ['sedge', 2100],
  ['sedgy', 4],
  ['sedum', 238],
  ['seeds', 8697],
  ['seedy', 147],
  ['seeks', 5366],
  ['seels', 4],
  ['seely', 313],
  ['seems', 9027],
  ['seeps', 235],
  ['seepy', 3],
  ['seers', 101],
  ['segni', 157],
  ['segno', 63],
  ['segos', 0],
  ['segue', 115],
  ['seifs', 0],
  ['seine', 1470],
  ['seise', 6],
  ['seism', 8],
  ['seize', 1451],
  ['selah', 180],
  ['selfs', 10],
  ['selle', 155],
  ['sells', 4105],
  ['selva', 605],
  ['semen', 604],
  ['semes', 23],
  ['semis', 148],
  ['sends', 1673],
  ['sengi', 49],
  ['senna', 804],
  ['senor', 125],
  ['sensa', 20],
  ['sense', 17109],
  ['sente', 48],
  ['senti', 19],
  ['sepal', 98],
  ['sepia', 475],
  ['sepic', 8],
  ['sepoy', 104],
  ['septa', 1478],
  ['septs', 133],
  ['serac', 20],
  ['serai', 134],
  ['seral', 25],
  ['sered', 14],
  ['serer', 428],
  ['seres', 73],
  ['serfs', 304],
  ['serge', 2225],
  ['serif', 423],
  ['serin', 98],
  ['serow', 76],
  ['serry', 67],
  ['serum', 1063],
  ['serve', 35195],
  ['servo', 258],
  ['setae', 272],
  ['setal', 14],
  ['seton', 1579],
  ['setts', 72],
  ['setup', 1466],
  ['seven', 83771],
  ['sever', 429],
  ['sewan', 13],
  ['sewar', 5],
  ['sewed', 87],
  ['sewer', 1046],
  ['sexed', 15],
  ['sexes', 1867],
  ['sexto', 83],
  ['sexts', 0],
  ['shack', 851],
  ['shade', 3304],
  ['shads', 19],
  ['shady', 1423],
  ['shaft', 3309],
  ['shags', 60],
  ['shahs', 77],
  ['shake', 2214],
  ['shako', 25],
  ['shaky', 232],
  ['shale', 2944],
  ['shall', 6285],
  ['shalt', 219],
  ['shaly', 8],
  ['shame', 1471],
  ['shams', 771],
  ['shank', 456],
  ['shape', 19531],
  ['shard', 182],
  ['share', 23135],
  ['shark', 4776],
  ['sharn', 16],
  ['sharp', 9611],
  ['shaul', 265],
  ['shave', 388],
  ['shawl', 274],
  ['shawm', 54],
  ['shawn', 3101],
  ['shaws', 68],
  ['shays', 88],
  ['sheaf', 562],
  ['sheal', 0],
  ['shear', 1801],
  ['sheas', 6],
  ['sheds', 949],
  ['sheen', 1108],
  ['sheep', 8548],
  ['sheer', 1143],
  ['sheet', 6644],
  ['sheik', 518],
  ['shelf', 3538],
  ['shell', 11070],
  ['shend', 12],
  ['shent', 0],
  ['sheol', 34],
  ['sherd', 26],
  ['shewn', 6],
  ['shews', 7],
  ['shied', 38],
  ['shiel', 198],
  ['shier', 43],
  ['shies', 11],
  ['shift', 7560],
  ['shill', 65],
  ['shily', 2],
  ['shims', 41],
  ['shine', 2810],
  ['shins', 164],
  ['shiny', 1467],
  ['ships', 33589],
  ['shire', 8688],
  ['shirk', 121],
  ['shirr', 0],
  ['shirt', 2010],
  ['shist', 2],
  ['shiva', 4453],
  ['shive', 67],
  ['shivs', 0],
  ['shlep', 0],
  ['shlub', 0],
  ['shoal', 1059],
  ['shoat', 6],
  ['shock', 5624],
  ['shoed', 6],
  ['shoer', 4],
  ['shoes', 4083],
  ['shogi', 1076],
  ['shogs', 0],
  ['shoji', 293],
  ['shone', 234],
  ['shook', 639],
  ['shool', 20],
  ['shoon', 28],
  ['shoos', 3],
  ['shoot', 4403],
  ['shops', 9819],
  ['shore', 22279],
  ['shorl', 2],
  ['shorn', 138],
  ['short', 109235],
  ['shote', 7],
  ['shots', 4335],
  ['shott', 19],
  ['shout', 1181],
  ['shove', 183],
  ['shown', 27545],
  ['shows', 53248],
  ['showy', 687],
  ['shoyu', 13],
  ['shred', 154],
  ['shrew', 2502],
  ['shris', 6],
  ['shrub', 9523],
  ['shrug', 40],
  ['shtik', 2],
  ['shuck', 66],
  ['shuln', 0],
  ['shuls', 8],
  ['shuns', 15],
  ['shunt', 340],
  ['shush', 306],
  ['shute', 391],
  ['shuts', 96],
  ['shwas', 0],
  ['shyer', 42],
  ['shyly', 13],
  ['sials', 3],
  ['sibbs', 3],
  ['sibyl', 372],
  ['sices', 0],
  ['sicko', 55],
  ['sicks', 15],
  ['sided', 1592],
  ['sides', 20703],
  ['sidhe', 65],
  ['sidle', 26],
  ['siege', 14075],
  ['sieur', 448],
  ['sieve', 414],
  ['sifts', 13],
  ['sighs', 117],
  ['sight', 4556],
  ['sigil', 81],
  ['sigla', 39],
  ['sigma', 5165],
  ['signa', 95],
  ['signs', 8304],
  ['sikas', 0],
  ['siker', 0],
  ['sikes', 189],
  ['silds', 0],
  ['silex', 74],
  ['silks', 216],
  ['silky', 750],
  ['sills', 525],
  ['silly', 956],
  ['silos', 507],
  ['silts', 78],
  ['silty', 177],
  ['silva', 10884],
  ['simar', 59],
  ['simas', 65],
  ['simps', 5],
  ['since', 345466],
  ['sines', 128],
  ['sinew', 47],
  ['singe', 103],
  ['sings', 5212],
  ['sinhs', 2],
  ['sinks', 481],
  ['sinus', 1177],
  ['siped', 0],
  ['sipes', 18],
  ['sired', 864],
  ['siree', 2],
  ['siren', 920],
  ['sires', 213],
  ['sirra', 11],
  ['sirup', 7],
  ['sisal', 113],
  ['sises', 0],
  ['sissy', 231],
  ['sitar', 491],
  ['sited', 1520],
  ['sites', 30612],
  ['situp', 0],
  ['situs', 102],
  ['siver', 19],
  ['sixes', 472],
  ['sixmo', 0],
  ['sixte', 14],
  ['sixth', 42833],
  ['sixty', 3251],
  ['sizar', 31],
  ['sized', 2024],
  ['sizer', 65],
  ['sizes', 4426],
  ['skags', 0],
  ['skald', 139],
  ['skank', 70],
  ['skate', 2793],
  ['skats', 13],
  ['skean', 36],
  ['skeed', 0],
  ['skeen', 107],
  ['skees', 3],
  ['skeet', 844],
  ['skegs', 2],
  ['skein', 87],
  ['skell', 28],
  ['skelm', 0],
  ['skelp', 15],
  ['skene', 308],
  ['skeps', 3],
  ['skews', 20],
  ['skids', 148],
  ['skied', 158],
  ['skier', 9582],
  ['skies', 1891],
  ['skiey', 0],
  ['skiff', 270],
  ['skill', 4825],
  ['skimo', 15],
  ['skimp', 8],
  ['skims', 19],
  ['skink', 1372],
  ['skins', 1322],
  ['skint', 58],
  ['skips', 243],
  ['skirl', 3],
  ['skirr', 0],
  ['skirt', 900],
  ['skite', 3],
  ['skits', 489],
  ['skive', 100],
  ['skoal', 36],
  ['skort', 2],
  ['skosh', 0],
  ['skuas', 43],
  ['skulk', 13],
  ['skull', 4930],
  ['skunk', 653],
  ['skyed', 0],
  ['skyey', 0],
  ['slabs', 659],
  ['slack', 736],
  ['slags', 37],
  ['slain', 1359],
  ['slake', 15],
  ['slams', 421],
  ['slang', 2534],
  ['slank', 19],
  ['slant', 449],
  ['slaps', 46],
  ['slash', 1130],
  ['slate', 3715],
  ['slats', 152],
  ['slaty', 238],
  ['slave', 8751],
  ['slaws', 0],
  ['slays', 52],
  ['sleds', 111],
  ['sleek', 217],
  ['sleep', 6482],
  ['sleet', 93],
  ['slept', 477],
  ['slews', 2],
  ['slice', 1012],
  ['slick', 927],
  ['slide', 3196],
  ['slier', 13],
  ['slily', 2],
  ['slime', 608],
  ['slims', 1208],
  ['slimy', 189],
  ['sling', 446],
  ['slink', 27],
  ['slipe', 0],
  ['slips', 550],
  ['slipt', 2],
  ['slits', 321],
  ['slobs', 2],
  ['sloes', 7],
  ['slogs', 0],
  ['sloid', 0],
  ['slojd', 0],
  ['sloop', 2242],
  ['slope', 6401],
  ['slops', 7],
  ['slosh', 32],
  ['sloth', 448],
  ['slots', 1337],
  ['slows', 233],
  ['sloyd', 16],
  ['slubs', 5],
  ['slued', 0],
  ['slues', 0],
  ['sluff', 2],
  ['slugs', 1093],
  ['slump', 470],
  ['slums', 561],
  ['slung', 85],
  ['slunk', 10],
  ['slurb', 0],
  ['slurp', 5],
  ['slurs', 130],
  ['slush', 182],
  ['sluts', 56],
  ['slyer', 0],
  ['slyly', 25],
  ['slype', 5],
  ['smack', 255],
  ['small', 196056],
  ['smalt', 18],
  ['smarm', 0],
  ['smart', 7233],
  ['smash', 2372],
  ['smaze', 0],
  ['smear', 319],
  ['smeek', 0],
  ['smell', 2028],
  ['smelt', 320],
  ['smerk', 0],
  ['smews', 0],
  ['smile', 2988],
  ['smirk', 16],
  ['smite', 71],
  ['smith', 60888],
  ['smock', 395],
  ['smogs', 0],
  ['smoke', 4492],
  ['smoky', 1646],
  ['smolt', 14],
  ['smote', 28],
  ['smush', 8],
  ['smuts', 274],
  ['snack', 1713],
  ['snafu', 116],
  ['snags', 58],
  ['snail', 26767],
  ['snake', 10432],
  ['snaky', 16],
  ['snaps', 247],
  ['snare', 564],
  ['snarf', 8],
  ['snark', 170],
  ['snarl', 31],
  ['snash', 0],
  ['snath', 4],
  ['snaws', 0],
  ['sneak', 522],
  ['sneap', 37],
  ['sneck', 11],
  ['sneds', 0],
  ['sneer', 34],
  ['snell', 819],
  ['snibs', 0],
  ['snick', 37],
  ['snide', 53],
  ['sniff', 112],
  ['snipe', 694],
  ['snips', 33],
  ['snits', 0],
  ['snobs', 47],
  ['snogs', 0],
  ['snood', 28],
  ['snook', 218],
  ['snool', 0],
  ['snoop', 1109],
  ['snoot', 16],
  ['snore', 20],
  ['snort', 51],
  ['snots', 0],
  ['snout', 4791],
  ['snows', 309],
  ['snowy', 1504],
  ['snubs', 11],
  ['snuck', 22],
  ['snuff', 449],
  ['snugs', 4],
  ['snyes', 0],
  ['soaks', 27],
  ['soaps', 425],
  ['soapy', 88],
  ['soars', 50],
  ['soave', 64],
  ['sobas', 0],
  ['sober', 608],
  ['socas', 9],
  ['socko', 14],
  ['socks', 1026],
  ['socle', 20],
  ['sodas', 103],
  ['soddy', 59],
  ['sodic', 33],
  ['sodom', 355],
  ['sofar', 39],
  ['sofas', 81],
  ['softa', 16],
  ['softs', 8],
  ['softy', 9],
  ['soggy', 61],
  ['soils', 5149],
  ['sojas', 91],
  ['sokes', 2],
  ['sokol', 723],
  ['solan', 195],
  ['solar', 20740],
  ['soldi', 69],
  ['soldo', 38],
  ['soled', 6],
  ['solei', 3],
  ['soles', 425],
  ['solid', 13017],
  ['solon', 534],
  ['solos', 1165],
  ['solum', 109],
  ['solus', 124],
  ['solve', 3934],
  ['soman', 288],
  ['somas', 8],
  ['sonar', 917],
  ['sonde', 26],
  ['sones', 84],
  ['songs', 103320],
  ['sonic', 3855],
  ['sonly', 0],
  ['sonny', 3390],
  ['sonsy', 0],
  ['sooey', 8],
  ['sooks', 0],
  ['sooth', 22],
  ['soots', 13],
  ['sooty', 567],
  ['sophs', 2],
  ['sophy', 81],
  ['sopor', 52],
  ['soppy', 2],
  ['soras', 17],
  ['sorbs', 90],
  ['sords', 0],
  ['sored', 2],
  ['sorel', 357],
  ['sorer', 0],
  ['sores', 181],
  ['sorgo', 18],
  ['sorns', 0],
  ['sorry', 1438],
  ['sorta', 30],
  ['sorts', 1368],
  ['sorus', 22],
  ['soths', 0],
  ['sotol', 28],
  ['sough', 32],
  ['souks', 88],
  ['souls', 3447],
  ['sound', 56497],
  ['soups', 514],
  ['soupy', 75],
  ['sours', 40],
  ['souse', 12],
  ['south', 554390],
  ['sowar', 14],
  ['sowed', 55],
  ['sower', 78],
  ['soyas', 0],
  ['soyuz', 1772],
  ['sozin', 6],
  ['space', 74381],
  ['spacy', 24],
  ['spade', 594],
  ['spado', 4],
  ['spaed', 0],
  ['spaes', 0],
  ['spahi', 29],
  ['spail', 0],
  ['spait', 0],
  ['spake', 57],
  ['spale', 0],
  ['spall', 150],
  ['spams', 9],
  ['spang', 57],
  ['spank', 129],
  ['spans', 6513],
  ['spare', 2682],
  ['spark', 2087],
  ['spars', 189],
  ['spasm', 217],
  ['spate', 219],
  ['spats', 83],
  ['spawn', 809],
  ['spays', 2],
  ['spazz', 17],
  ['speak', 9853],
  ['spean', 95],
  ['spear', 1828],
  ['speck', 416],
  ['specs', 196],
  ['speed', 32373],
  ['speel', 9],
  ['speer', 530],
  ['speil', 6],
  ['speir', 62],
  ['spell', 6180],
  ['spelt', 2847],
  ['spend', 4739],
  ['spent', 50283],
  ['sperm', 1587],
  ['spews', 15],
  ['spica', 178],
  ['spice', 2238],
  ['spick', 13],
  ['spics', 3],
  ['spicy', 979],
  ['spied', 126],
  ['spiel', 306],
  ['spier', 129],
  ['spies', 1534],
  ['spiff', 35],
  ['spike', 3540],
  ['spiks', 0],
  ['spiky', 129],
  ['spile', 7],
  ['spill', 1649],
  ['spilt', 81],
  ['spine', 2260],
  ['spins', 635],
  ['spiny', 1965],
  ['spire', 1621],
  ['spirt', 9],
  ['spiry', 0],
  ['spite', 3111],
  ['spits', 131],
  ['spitz', 430],
  ['spivs', 8],
  ['splat', 90],
  ['splay', 73],
  ['split', 28787],
  ['spode', 74],
  ['spoil', 364],
  ['spoke', 5050],
  ['spoof', 806],
  ['spook', 226],
  ['spool', 156],
  ['spoon', 1242],
  ['spoor', 93],
  ['spore', 635],
  ['sport', 42041],
  ['spots', 9204],
  ['spout', 225],
  ['sprag', 6],
  ['sprat', 136],
  ['spray', 1576],
  ['spree', 999],
  ['sprig', 83],
  ['sprit', 45],
  ['sprue', 29],
  ['sprug', 0],
  ['spuds', 50],
  ['spued', 0],
  ['spues', 0],
  ['spume', 9],
  ['spumy', 0],
  ['spunk', 77],
  ['spurn', 44],
  ['spurs', 2250],
  ['spurt', 97],
  ['sputa', 3],
  ['squab', 37],
  ['squad', 19516],
  ['squat', 692],
  ['squaw', 587],
  ['squeg', 0],
  ['squib', 61],
  ['squid', 1540],
  ['stabs', 84],
  ['stack', 3148],
  ['stade', 5285],
  ['staff', 43716],
  ['stage', 79269],
  ['stags', 555],
  ['stagy', 3],
  ['staid', 59],
  ['staig', 7],
  ['stain', 1138],
  ['stair', 723],
  ['stake', 4452],
  ['stale', 174],
  ['stalk', 928],
  ['stall', 774],
  ['stamp', 4731],
  ['stand', 16177],
  ['stane', 153],
  ['stang', 262],
  ['stank', 34],
  ['staph', 22],
  ['stare', 1549],
  ['stark', 3035],
  ['stars', 67332],
  ['start', 35901],
  ['stash', 185],
  ['state', 592508],
  ['stats', 824],
  ['stave', 719],
  ['stays', 1507],
  ['stead', 670],
  ['steak', 867],
  ['steal', 1719],
  ['steam', 16120],
  ['steed', 264],
  ['steek', 10],
  ['steel', 25601],
  ['steep', 6243],
  ['steer', 903],
  ['stein', 3384],
  ['stela', 502],
  ['stele', 755],
  ['stems', 6441],
  ['steno', 154],
  ['stent', 283],
  ['steps', 7974],
  ['stere', 43],
  ['stern', 4337],
  ['stets', 18],
  ['stews', 169],
  ['stewy', 2],
  ['stich', 160],
  ['stick', 4774],
  ['stied', 0],
  ['sties', 7],
  ['stiff', 1854],
  ['stile', 191],
  ['still', 100278],
  ['stilt', 222],
  ['stime', 0],
  ['stimy', 0],
  ['sting', 2219],
  ['stink', 481],
  ['stint', 4826],
  ['stipe', 817],
  ['stirk', 59],
  ['stirp', 0],
  ['stirs', 61],
  ['stoae', 8],
  ['stoai', 0],
  ['stoas', 13],
  ['stoat', 45],
  ['stobs', 23],
  ['stock', 26980],
  ['stogy', 0],
  ['stoic', 392],
  ['stoke', 4785],
  ['stole', 1275],
  ['stoma', 96],
  ['stomp', 465],
  ['stone', 49677],
  ['stony', 3987],
  ['stood', 16391],
  ['stook', 3],
  ['stool', 528],
  ['stoop', 206],
  ['stope', 17],
  ['stops', 4435],
  ['stopt', 2],
  ['store', 32233],
  ['stork', 693],
  ['storm', 22393],
  ['story', 98557],
  ['stoss', 56],
  ['stots', 5],
  ['stott', 515],
  ['stoup', 22],
  ['stour', 602],
  ['stout', 1770],
  ['stove', 850],
  ['stowp', 0],
  ['stows', 8],
  ['strap', 669],
  ['straw', 1866],
  ['stray', 1083],
  ['strep', 70],
  ['strew', 7],
  ['stria', 94],
  ['strip', 13312],
  ['strop', 38],
  ['strow', 3],
  ['stroy', 5],
  ['strum', 115],
  ['strut', 322],
  ['stubs', 94],
  ['stuck', 2097],
  ['studs', 326],
  ['study', 61212],
  ['stuff', 2178],
  ['stull', 96],
  ['stump', 923],
  ['stums', 0],
  ['stung', 206],
  ['stunk', 4],
  ['stuns', 13],
  ['stunt', 2256],
  ['stupa', 724],
  ['stupe', 3],
  ['sturt', 1071],
  ['styed', 0],
  ['styes', 6],
  ['style', 85669],
  ['styli', 13],
  ['stymy', 0],
  ['suave', 226],
  ['subah', 130],
  ['subas', 14],
  ['suber', 93],
  ['sucks', 354],
  ['sucky', 7],
  ['sucre', 825],
  ['sudds', 4],
  ['sudor', 18],
  ['sudsy', 18],
  ['suede', 353],
  ['suers', 0],
  ['suets', 0],
  ['suety', 0],
  ['sugar', 15323],
  ['sughs', 0],
  ['suing', 224],
  ['suint', 0],
  ['suite', 6918],
  ['suits', 1960],
  ['sulci', 133],
  ['sulfa', 29],
  ['sulfo', 9],
  ['sulks', 4],
  ['sulky', 39],
  ['sully', 587],
  ['sulus', 8],
  ['sumac', 255],
  ['summa', 813],
  ['sumos', 8],
  ['sumps', 22],
  ['sunna', 99],
  ['sunns', 0],
  ['sunny', 3212],
  ['sunup', 17],
  ['super', 49001],
  ['supes', 3],
  ['supra', 263],
  ['surah', 282],
  ['sural', 39],
  ['suras', 37],
  ['surds', 2],
  ['surer', 39],
  ['surfs', 24],
  ['surfy', 0],
  ['surge', 1662],
  ['surgy', 4],
  ['surly', 71],
  ['surra', 52],
  ['sushi', 598],
  ['sutra', 951],
  ['sutta', 332],
  ['swabs', 69],
  ['swage', 28],
  ['swags', 34],
  ['swail', 40],
  ['swain', 827],
  ['swale', 387],
  ['swami', 2582],
  ['swamp', 4637],
  ['swamy', 932],
  ['swang', 31],
  ['swank', 232],
  ['swans', 1722],
  ['swaps', 353],
  ['sward', 53],
  ['sware', 0],
  ['swarf', 20],
  ['swarm', 965],
  ['swart', 270],
  ['swash', 113],
  ['swath', 282],
  ['swats', 11],
  ['sways', 25],
  ['swear', 606],
  ['sweat', 1650],
  ['swede', 536],
  ['sweep', 1930],
  ['sweer', 0],
  ['sweet', 14218],
  ['swell', 669],
  ['swept', 2849],
  ['swift', 5881],
  ['swigs', 0],
  ['swill', 47],
  ['swims', 253],
  ['swine', 775],
  ['swing', 6578],
  ['swink', 63],
  ['swipe', 127],
  ['swirl', 193],
  ['swish', 148],
  ['swiss', 32282],
  ['swith', 0],
  ['swive', 2],
  ['swobs', 0],
  ['swoon', 97],
  ['swoop', 119],
  ['swops', 0],
  ['sword', 7354],
  ['swore', 442],
  ['sworn', 4390],
  ['swots', 4],
  ['swoun', 0],
  ['swung', 304],
  ['sycee', 4],
  ['syces', 6],
  ['sykes', 1505],
  ['sylis', 3],
  ['sylph', 173],
  ['sylva', 309],
  ['synch', 50],
  ['syncs', 30],
  ['synod', 3704],
  ['synth', 753],
  ['syphs', 0],
  ['syren', 83],
  ['syrup', 1260],
  ['sysop', 27],
  ['tabby', 225],
  ['taber', 255],
  ['tabes', 30],
  ['tabid', 3],
  ['tabla', 545],
  ['table', 26668],
  ['taboo', 928],
  ['tabor', 1077],
  ['tabun', 46],
  ['tabus', 0],
  ['taces', 0],
  ['tacet', 19],
  ['tache', 69],
  ['tachs', 4],
  ['tacit', 239],
  ['tacks', 88],
  ['tacky', 85],
  ['tacos', 197],
  ['tacts', 3],
  ['taels', 20],
  ['taffy', 229],
  ['tafia', 7],
  ['tahrs', 4],
  ['taiga', 543],
  ['tails', 1596],
  ['tains', 2],
  ['taint', 119],
  ['tajes', 6],
  ['takas', 7],
  ['taken', 55013],
  ['taker', 339],
  ['takes', 35356],
  ['takin', 179],
  ['talar', 73],
  ['talas', 268],
  ['talcs', 0],
  ['taler', 35],
  ['tales', 11745],
  ['talks', 4940],
  ['talky', 15],
  ['talls', 3],
  ['tally', 2630],
  ['talon', 398],
  ['taluk', 6434],
  ['talus', 356],
  ['tamal', 31],
  ['tamed', 149],
  ['tamer', 415],
  ['tames', 30],
  ['tamis', 18],
  ['tammy', 1042],
  ['tamps', 8],
  ['tanga', 313],
  ['tango', 2751],
  ['tangs', 27],
  ['tangy', 108],
  ['tanka', 259],
  ['tanks', 5027],
  ['tansy', 94],
  ['tanto', 220],
  ['tapas', 193],
  ['taped', 1022],
  ['taper', 338],
  ['tapes', 2999],
  ['tapir', 176],
  ['tapis', 50],
  ['tardo', 16],
  ['tardy', 126],
  ['tared', 2],
  ['tares', 12],
  ['targe', 15],
  ['tarns', 78],
  ['taroc', 0],
  ['tarok', 23],
  ['taros', 4],
  ['tarot', 781],
  ['tarps', 24],
  ['tarre', 6],
  ['tarry', 110],
  ['tarsi', 116],
  ['tarts', 76],
  ['tarty', 3],
  ['tasks', 5683],
  ['tasse', 42],
  ['taste', 5922],
  ['tasty', 372],
  ['tatar', 2315],
  ['tater', 49],
  ['tates', 51],
  ['tatty', 21],
  ['taunt', 100],
  ['tauon', 7],
  ['taupe', 41],
  ['tauts', 0],
  ['tawed', 0],
  ['tawer', 0],
  ['tawie', 0],
  ['tawny', 657],
  ['tawse', 24],
  ['taxed', 401],
  ['taxer', 4],
  ['taxes', 5595],
  ['taxis', 975],
  ['taxol', 90],
  ['taxon', 2717],
  ['taxus', 164],
  ['tazza', 49],
  ['tazze', 9],
  ['teach', 6494],
  ['teaks', 3],
  ['teals', 39],
  ['teams', 107103],
  ['tears', 3651],
  ['teary', 11],
  ['tease', 211],
  ['teats', 40],
  ['techs', 52],
  ['techy', 4],
  ['tecta', 62],
  ['teddy', 2975],
  ['teels', 2],
  ['teems', 28],
  ['teens', 2523],
  ['teeny', 70],
  ['teeth', 6327],
  ['teffs', 0],
  ['teggs', 2],
  ['tegua', 15],
  ['teiid', 11],
  ['teind', 3],
  ['telae', 3],
  ['telco', 158],
  ['teles', 163],
  ['telex', 141],
  ['telia', 149],
  ['telic', 51],
  ['tells', 11188],
  ['telly', 378],
  ['teloi', 0],
  ['telos', 131],
  ['tempi', 104],
  ['tempo', 2119],
  ['temps', 760],
  ['tempt', 64],
  ['tench', 227],
  ['tends', 2957],
  ['tendu', 21],
  ['tenet', 308],
  ['tenge', 57],
  ['tenia', 12],
  ['tenon', 97],
  ['tenor', 5735],
  ['tense', 1302],
  ['tenth', 14821],
  ['tents', 880],
  ['tenty', 0],
  ['tepal', 23],
  ['tepas', 0],
  ['tepee', 97],
  ['tepid', 78],
  ['tepoy', 0],
  ['terai', 359],
  ['terce', 17],
  ['terga', 18],
  ['terms', 44906],
  ['terne', 32],
  ['terns', 272],
  ['terra', 3718],
  ['terry', 12723],
  ['terse', 125],
  ['tesla', 1076],
  ['testa', 392],
  ['tests', 12517],
  ['testy', 16],
  ['teths', 0],
  ['tetra', 543],
  ['tetri', 42],
  ['teuch', 6],
  ['teugh', 0],
  ['tewed', 0],
  ['texas', 104643],
  ['texts', 10820],
  ['thack', 0],
  ['thane', 1072],
  ['thank', 1859],
  ['tharm', 0],
  ['thaws', 38],
  ['thebe', 79],
  ['theca', 72],
  ['theft', 3314],
  ['thegn', 49],
  ['thein', 274],
  ['their', 655785],
  ['theme', 27875],
  ['thens', 4],
  ['there', 343668],
  ['therm', 25],
  ['these', 215479],
  ['thesp', 0],
  ['theta', 2760],
  ['thews', 2],
  ['thewy', 0],
  ['thick', 7335],
  ['thief', 2497],
  ['thigh', 653],
  ['thill', 61],
  ['thine', 139],
  ['thing', 9137],
  ['think', 11424],
  ['thins', 71],
  ['thiol', 185],
  ['third', 179643],
  ['thirl', 2],
  ['thole', 35],
  ['thong', 719],
  ['thorn', 1833],
  ['thoro', 9],
  ['thorp', 591],
  ['those', 100339],
  ['thous', 13],
  ['thraw', 0],
  ['three', 355818],
  ['threw', 6355],
  ['thrip', 28],
  ['throb', 78],
  ['throe', 3],
  ['throw', 10640],
  ['thrum', 35],
  ['thuds', 6],
  ['thugs', 375],
  ['thuja', 125],
  ['thumb', 1998],
  ['thump', 107],
  ['thunk', 11],
  ['thurl', 31],
  ['thuya', 23],
  ['thyme', 350],
  ['thymi', 8],
  ['thymy', 0],
  ['tiara', 362],
  ['tibia', 466],
  ['tical', 35],
  ['ticks', 408],
  ['tidal', 3855],
  ['tided', 0],
  ['tides', 1424],
  ['tiers', 1019],
  ['tiffs', 6],
  ['tiger', 10207],
  ['tight', 4727],
  ['tigon', 41],
  ['tikes', 12],
  ['tikis', 10],
  ['tikka', 110],
  ['tilak', 603],
  ['tilde', 294],
  ['tiled', 442],
  ['tiler', 37],
  ['tiles', 1957],
  ['tills', 46],
  ['tilth', 34],
  ['tilts', 110],
  ['timed', 877],
  ['timer', 529],
  ['times', 133215],
  ['timid', 236],
  ['tinct', 3],
  ['tinea', 755],
  ['tined', 0],
  ['tines', 84],
  ['tinge', 769],
  ['tings', 75],
  ['tinny', 41],
  ['tints', 114],
  ['tipis', 25],
  ['tippy', 99],
  ['tipsy', 94],
  ['tired', 1245],
  ['tires', 1346],
  ['tirls', 0],
  ['tiros', 44],
  ['titan', 2331],
  ['titer', 37],
  ['tithe', 405],
  ['titis', 23],
  ['title', 180980],
  ['titre', 38],
  ['titty', 30],
  ['tizzy', 13],
  ['toads', 587],
  ['toady', 13],
  ['toast', 800],
  ['today', 68718],
  ['toddy', 126],
  ['toeas', 0],
  ['toffs', 11],
  ['toffy', 2],
  ['tofts', 26],
  ['tofus', 8],
  ['togae', 2],
  ['togas', 17],
  ['togue', 12],
  ['toile', 30],
  ['toils', 25],
  ['toits', 25],
  ['tokay', 51],
  ['toked', 0],
  ['token', 1055],
  ['toker', 34],
  ['tokes', 18],
  ['tolan', 153],
  ['tolar', 67],
  ['tolas', 16],
  ['toled', 2],
  ['toles', 50],
  ['tolls', 735],
  ['tolus', 8],
  ['tolyl', 5],
  ['toman', 131],
  ['tombs', 2656],
  ['tomes', 127],
  ['tommy', 10835],
  ['tonal', 892],
  ['tondi', 42],
  ['tondo', 293],
  ['toned', 202],
  ['toner', 282],
  ['tones', 2122],
  ['toney', 270],
  ['tonga', 3889],
  ['tongs', 155],
  ['tonic', 906],
  ['tonne', 342],
  ['tonus', 61],
  ['tools', 14108],
  ['toons', 183],
  ['tooth', 3588],
  ['toots', 272],
  ['topaz', 381],
  ['toped', 0],
  ['topee', 7],
  ['toper', 9],
  ['topes', 7],
  ['tophe', 0],
  ['tophi', 12],
  ['tophs', 0],
  ['topic', 6154],
  ['topis', 4],
  ['topoi', 32],
  ['topos', 143],
  ['toque', 83],
  ['torah', 2513],
  ['toras', 36],
  ['torch', 2144],
  ['torcs', 39],
  ['tores', 8],
  ['toric', 93],
  ['torii', 347],
  ['toros', 912],
  ['torot', 0],
  ['torrs', 22],
  ['torse', 26],
  ['torsi', 2],
  ['torsk', 14],
  ['torso', 715],
  ['torta', 152],
  ['torte', 57],
  ['torts', 277],
  ['torus', 659],
  ['total', 115817],
  ['toted', 2],
  ['totem', 580],
  ['toter', 19],
  ['totes', 64],
  ['touch', 8283],
  ['tough', 3329],
  ['tours', 13356],
  ['touse', 0],
  ['touts', 59],
  ['towed', 1225],
  ['towel', 282],
  ['tower', 46919],
  ['towie', 43],
  ['towns', 31059],
  ['towny', 3],
  ['toxic', 4108],
  ['toxin', 1066],
  ['toyed', 34],
  ['toyer', 2],
  ['toyon', 41],
  ['toyos', 4],
  ['trace', 5533],
  ['track', 90294],
  ['tract', 4680],
  ['trade', 62745],
  ['tragi', 5],
  ['traik', 0],
  ['trail', 32438],
  ['train', 42247],
  ['trait', 1569],
  ['tramp', 658],
  ['trams', 2170],
  ['trank', 12],
  ['tranq', 5],
  ['trans', 3688],
  ['traps', 1076],
  ['trapt', 55],
  ['trash', 1459],
  ['trass', 14],
  ['trave', 77],
  ['trawl', 147],
  ['trays', 201],
  ['tread', 340],
  ['treat', 6282],
  ['treed', 51],
  ['treen', 114],
  ['trees', 22450],
  ['treks', 151],
  ['trend', 4665],
  ['tress', 87],
  ['trets', 18],
  ['trews', 62],
  ['treys', 4],
  ['triac', 31],
  ['triad', 1478],
  ['trial', 24568],
  ['tribe', 22079],
  ['trice', 185],
  ['trick', 2892],
  ['tried', 12640],
  ['trier', 1526],
  ['tries', 5294],
  ['trigo', 118],
  ['trigs', 10],
  ['trike', 329],
  ['trill', 310],
  ['trims', 98],
  ['trine', 230],
  ['triol', 13],
  ['trios', 693],
  ['tripe', 149],
  ['trips', 4828],
  ['trite', 75],
  ['troak', 0],
  ['trock', 4],
  ['trode', 4],
  ['trogs', 0],
  ['trois', 802],
  ['troke', 11],
  ['troll', 675],
  ['tromp', 172],
  ['trona', 78],
  ['trone', 24],
  ['troop', 3605],
  ['trooz', 24],
  ['trope', 293],
  ['troth', 57],
  ['trots', 42],
  ['trout', 5130],
  ['trove', 219],
  ['trows', 9],
  ['troys', 6],
  ['truce', 1207],
  ['truck', 8957],
  ['trued', 3],
  ['truer', 54],
  ['trues', 6],
  ['trugs', 3],
  ['trull', 77],
  ['truly', 3052],
  ['trump', 5717],
  ['trunk', 6544],
  ['truss', 2889],
  ['trust', 32096],
  ['truth', 10274],
  ['tryma', 0],
  ['tryst', 75],
  ['tsade', 6],
  ['tsadi', 4],
  ['tsars', 158],
  ['tsked', 0],
  ['tsuba', 17],
  ['tubae', 13],
  ['tubal', 129],
  ['tubas', 90],
  ['tubby', 327],
  ['tubed', 16],
  ['tuber', 394],
  ['tubes', 3293],
  ['tucks', 32],
  ['tufas', 0],
  ['tuffs', 75],
  ['tufts', 1939],
  ['tufty', 15],
  ['tules', 15],
  ['tulip', 1488],
  ['tulle', 197],
  ['tumid', 2],
  ['tummy', 62],
  ['tumor', 3513],
  ['tumps', 23],
  ['tunas', 150],
  ['tuned', 1409],
  ['tuner', 408],
  ['tunes', 4310],
  ['tungs', 3],
  ['tunic', 335],
  ['tunny', 86],
  ['tupik', 14],
  ['tuque', 240],
  ['turbo', 2242],
  ['turds', 8],
  ['turfs', 22],
  ['turfy', 0],
  ['turks', 3876],
  ['turns', 7266],
  ['turps', 6],
  ['tushy', 5],
  ['tusks', 226],
  ['tutee', 2],
  ['tutor', 2753],
  ['tutti', 350],
  ['tutty', 31],
  ['tutus', 21],
  ['tuxes', 2],
  ['tuyer', 0],
  ['twaes', 0],
  ['twain', 1450],
  ['twang', 195],
  ['twats', 3],
  ['tweak', 89],
  ['tweed', 1141],
  ['tween', 129],
  ['tweet', 353],
  ['twerp', 7],
  ['twice', 31422],
  ['twier', 0],
  ['twigs', 778],
  ['twill', 80],
  ['twine', 234],
  ['twins', 6306],
  ['twiny', 0],
  ['twirl', 49],
  ['twirp', 0],
  ['twist', 2850],
  ['twits', 13],
  ['twixt', 49],
  ['twyer', 0],
  ['tyees', 4],
  ['tyers', 119],
  ['tying', 1558],
  ['tyiyn', 3],
  ['tykes', 137],
  ['tyned', 0],
  ['tynes', 61],
  ['typal', 0],
  ['typed', 520],
  ['types', 30564],
  ['typey', 0],
  ['typic', 13],
  ['typos', 28],
  ['typps', 0],
  ['tyred', 12],
  ['tyres', 786],
  ['tyros', 38],
  ['tythe', 10],
  ['tzars', 4],
  ['udder', 55],
  ['udons', 0],
  ['uhlan', 161],
  ['ukase', 61],
  ['ulama', 332],
  ['ulans', 4],
  ['ulcer', 438],
  ['ulema', 145],
  ['ulnad', 0],
  ['ulnae', 9],
  ['ulnar', 321],
  ['ulnas', 0],
  ['ulpan', 25],
  ['ultra', 3633],
  ['ulvas', 0],
  ['umami', 80],
  ['umbel', 325],
  ['umber', 130],
  ['umbos', 5],
  ['umbra', 254],
  ['umiac', 2],
  ['umiak', 17],
  ['umiaq', 13],
  ['umped', 2],
  ['unais', 22],
  ['unapt', 0],
  ['unarm', 0],
  ['unary', 127],
  ['unaus', 0],
  ['unban', 0],
  ['unbar', 0],
  ['unbid', 17],
  ['unbox', 13],
  ['uncap', 0],
  ['uncia', 34],
  ['uncle', 13125],
  ['uncos', 0],
  ['uncoy', 0],
  ['uncus', 47],
  ['uncut', 677],
  ['undee', 0],
  ['under', 335475],
  ['undid', 14],
  ['undue', 354],
  ['unfed', 7],
  ['unfit', 589],
  ['unfix', 2],
  ['ungot', 0],
  ['unhat', 0],
  ['unhip', 6],
  ['unify', 661],
  ['union', 176153],
  ['unite', 2120],
  ['units', 39020],
  ['unity', 9209],
  ['unjam', 0],
  ['unlay', 0],
  ['unled', 0],
  ['unlet', 0],
  ['unlit', 54],
  ['unman', 10],
  ['unmet', 70],
  ['unmew', 0],
  ['unmix', 2],
  ['unpeg', 0],
  ['unpen', 0],
  ['unpin', 2],
  ['unrig', 0],
  ['unrip', 0],
  ['unsay', 12],
  ['unset', 11],
  ['unsew', 0],
  ['unsex', 0],
  ['untie', 50],
  ['until', 305872],
  ['unwed', 108],
  ['unwet', 0],
  ['unwit', 0],
  ['unwon', 0],
  ['unzip', 13],
  ['upbow', 2],
  ['upbye', 0],
  ['updos', 2],
  ['updry', 0],
  ['upend', 16],
  ['uplit', 0],
  ['upped', 45],
  ['upper', 53979],
  ['upset', 2987],
  ['uraei', 6],
  ['urare', 0],
  ['urari', 0],
  ['urase', 0],
  ['urate', 40],
  ['urban', 47550],
  ['urbia', 9],
  ['ureal', 0],
  ['ureas', 10],
  ['uredo', 42],
  ['ureic', 0],
  ['urged', 2009],
  ['urger', 6],
  ['urges', 314],
  ['urial', 11],
  ['urine', 1672],
  ['urped', 0],
  ['ursae', 385],
  ['ursid', 11],
  ['usage', 9740],
  ['users', 18736],
  ['usher', 1695],
  ['using', 85730],
  ['usnea', 57],
  ['usque', 71],
  ['usual', 7273],
  ['usurp', 155],
  ['usury', 121],
  ['uteri', 34],
  ['utile', 73],
  ['utter', 411],
  ['uveal', 29],
  ['uveas', 0],
  ['uvula', 50],
  ['vacua', 21],
  ['vagal', 80],
  ['vague', 1417],
  ['vagus', 234],
  ['vails', 25],
  ['vairs', 0],
  ['vakil', 133],
  ['vales', 144],
  ['valet', 525],
  ['valid', 4987],
  ['valor', 1426],
  ['valse', 152],
  ['value', 27640],
  ['valve', 4176],
  ['vamps', 184],
  ['vampy', 11],
  ['vanda', 370],
  ['vaned', 13],
  ['vanes', 117],
  ['vangs', 7],
  ['vapid', 50],
  ['vapor', 1528],
  ['varas', 96],
  ['varia', 248],
  ['varix', 24],
  ['varna', 1555],
  ['varus', 218],
  ['varve', 24],
  ['vasal', 22],
  ['vases', 762],
  ['vasts', 2],
  ['vasty', 0],
  ['vatic', 2],
  ['vatus', 5],
  ['vault', 5189],
  ['vaunt', 3],
  ['veals', 3],
  ['vealy', 0],
  ['veena', 509],
  ['veeps', 0],
  ['veers', 114],
  ['veery', 6],
  ['vegan', 918],
  ['veges', 0],
  ['vegie', 0],
  ['veils', 183],
  ['veins', 4277],
  ['veiny', 33],
  ['velar', 325],
  ['velds', 0],
  ['veldt', 65],
  ['velum', 92],
  ['venae', 142],
  ['venal', 51],
  ['vends', 13],
  ['venge', 13],
  ['venin', 10],
  ['venom', 1531],
  ['vents', 670],
  ['venue', 17329],
  ['venus', 5273],
  ['verbs', 1207],
  ['verge', 929],
  ['verse', 7694],
  ['verso', 317],
  ['verst', 6],
  ['verts', 82],
  ['vertu', 75],
  ['verve', 1114],
  ['vesta', 483],
  ['vests', 294],
  ['vetch', 260],
  ['vexed', 55],
  ['vexer', 2],
  ['vexes', 2],
  ['vexil', 0],
  ['vials', 65],
  ['viand', 6],
  ['vibes', 394],
  ['vicar', 5226],
  ['viced', 0],
  ['vices', 304],
  ['vichy', 1060],
  ['video', 106796],
  ['viers', 12],
  ['views', 16545],
  ['viewy', 0],
  ['vigas', 45],
  ['vigia', 34],
  ['vigil', 667],
  ['vigor', 413],
  ['viler', 6],
  ['villa', 13590],
  ['villi', 99],
  ['vills', 15],
  ['vimen', 0],
  ['vinal', 43],
  ['vinas', 19],
  ['vinca', 98],
  ['vined', 0],
  ['vines', 1345],
  ['vinic', 0],
  ['vinos', 36],
  ['vinyl', 10303],
  ['viola', 4280],
  ['viols', 89],
  ['viper', 1843],
  ['viral', 3844],
  ['vireo', 295],
  ['vires', 79],
  ['virga', 91],
  ['virid', 0],
  ['virls', 0],
  ['virtu', 75],
  ['virus', 9764],
  ['visas', 649],
  ['vised', 2],
  ['vises', 13],
  ['visit', 13263],
  ['visor', 194],
  ['vista', 5737],
  ['vitae', 373],
  ['vital', 5174],
  ['vitta', 42],
  ['vivas', 164],
  ['vivid', 1565],
  ['vixen', 412],
  ['vizir', 21],
  ['vizor', 3],
  ['vocab', 10],
  ['vocal', 15678],
  ['voces', 116],
  ['vodka', 1307],
  ['vodou', 271],
  ['vodun', 53],
  ['vogie', 0],
  ['vogue', 2365],
  ['voice', 39528],
  ['voids', 241],
  ['voila', 29],
  ['voile', 52],
  ['volar', 110],
  ['voled', 0],
  ['voles', 191],
  ['volta', 2137],
  ['volte', 71],
  ['volti', 30],
  ['volts', 471],
  ['volva', 60],
  ['vomer', 46],
  ['vomit', 210],
  ['voted', 16138],
  ['voter', 3622],
  ['votes', 22080],
  ['vouch', 21],
  ['vowed', 524],
  ['vowel', 2091],
  ['vower', 0],
  ['vroom', 136],
  ['vrouw', 55],
  ['vrows', 0],
  ['vuggs', 0],
  ['vuggy', 3],
  ['vughs', 0],
  ['vulgo', 17],
  ['vulva', 195],
  ['vying', 274],
  ['wacke', 4],
  ['wacko', 45],
  ['wacks', 22],
  ['wacky', 335],
  ['waddy', 170],
  ['waded', 27],
  ['wader', 174],
  ['wades', 31],
  ['wadis', 127],
  ['wafer', 525],
  ['waffs', 0],
  ['wafts', 9],
  ['waged', 731],
  ['wager', 430],
  ['wages', 2177],
  ['wagon', 3609],
  ['wahoo', 200],
  ['waifs', 77],
  ['wails', 21],
  ['wains', 14],
  ['wairs', 0],
  ['waist', 1010],
  ['waits', 904],
  ['waive', 144],
  ['waked', 34],
  ['waken', 10],
  ['waker', 30],
  ['wakes', 398],
  ['waled', 5],
  ['waler', 5],
  ['wales', 88520],
  ['walks', 3404],
  ['walla', 1196],
  ['walls', 16499],
  ['wally', 2544],
  ['waltz', 2316],
  ['wames', 0],
  ['wamus', 3],
  ['wands', 159],
  ['waned', 540],
  ['wanes', 19],
  ['waney', 4],
  ['wanks', 5],
  ['wanly', 10],
  ['wants', 4031],
  ['wards', 5365],
  ['wared', 0],
  ['wares', 625],
  ['warks', 15],
  ['warms', 88],
  ['warns', 341],
  ['warps', 49],
  ['warts', 312],
  ['warty', 400],
  ['washy', 4],
  ['wasps', 2474],
  ['waspy', 4],
  ['waste', 8909],
  ['wasts', 0],
  ['watap', 4],
  ['watch', 10395],
  ['water', 126693],
  ['watts', 6792],
  ['waugh', 909],
  ['wauks', 0],
  ['wauls', 0],
  ['waved', 557],
  ['waver', 71],
  ['waves', 7858],
  ['wavey', 8],
  ['wawls', 0],
  ['waxed', 134],
  ['waxen', 14],
  ['waxer', 2],
  ['waxes', 154],
  ['wazoo', 42],
  ['weald', 519],
  ['weals', 4],
  ['weans', 7],
  ['wears', 1889],
  ['weary', 363],
  ['weave', 640],
  ['webby', 375],
  ['weber', 4611],
  ['wecht', 50],
  ['wedel', 230],
  ['wedge', 1552],
  ['wedgy', 3],
  ['weeds', 933],
  ['weedy', 204],
  ['weeks', 32749],
  ['weens', 0],
  ['weeny', 29],
  ['weeps', 95],
  ['weepy', 4],
  ['weest', 3],
  ['weets', 7],
  ['wefts', 11],
  ['weigh', 1124],
  ['weird', 2274],
  ['weirs', 230],
  ['wekas', 0],
  ['welch', 2587],
  ['welds', 85],
  ['wells', 13217],
  ['welly', 25],
  ['welsh', 30660],
  ['welts', 32],
  ['wench', 47],
  ['wends', 102],
  ['wenny', 3],
  ['wests', 456],
  ['wetly', 0],
  ['whack', 65],
  ['whale', 3731],
  ['whamo', 0],
  ['whams', 3],
  ['whang', 75],
  ['whaps', 0],
  ['wharf', 2852],
  ['whats', 58],
  ['whaup', 4],
  ['wheal', 149],
  ['wheat', 5577],
  ['wheel', 9769],
  ['wheen', 26],
  ['wheep', 0],
  ['whelk', 155],
  ['whelm', 6],
  ['whelp', 28],
  ['whens', 0],
  ['where', 361546],
  ['whets', 0],
  ['whews', 0],
  ['wheys', 0],
  ['which', 1220752],
  ['whids', 0],
  ['whiff', 54],
  ['whigs', 649],
  ['while', 243441],
  ['whims', 79],
  ['whine', 58],
  ['whins', 14],
  ['whiny', 9],
  ['whips', 281],
  ['whipt', 2],
  ['whirl', 125],
  ['whirr', 16],
  ['whirs', 0],
  ['whish', 30],
  ['whisk', 80],
  ['whist', 193],
  ['white', 123108],
  ['whits', 0],
  ['whity', 5],
  ['whizz', 78],
  ['whole', 26382],
  ['whomp', 16],
  ['whoof', 0],
  ['whoop', 59],
  ['whops', 0],
  ['whorl', 525],
  ['whort', 0],
  ['whose', 65717],
  ['whoso', 14],
  ['whump', 0],
  ['whups', 0],
  ['wicca', 458],
  ['wicks', 553],
  ['widdy', 6],
  ['widen', 394],
  ['wider', 9443],
  ['wides', 58],
  ['widow', 7652],
  ['width', 5711],
  ['wield', 213],
  ['wifed', 0],
  ['wifes', 7],
  ['wifey', 12],
  ['wifty', 0],
  ['wigan', 3458],
  ['wiggy', 24],
  ['wight', 3551],
  ['wilco', 368],
  ['wilds', 280],
  ['wiled', 0],
  ['wiles', 284],
  ['wills', 2475],
  ['willy', 3096],
  ['wilts', 227],
  ['wimps', 62],
  ['wimpy', 251],
  ['wince', 30],
  ['winch', 444],
  ['winds', 6616],
  ['windy', 1059],
  ['wined', 4],
  ['wines', 3959],
  ['winey', 17],
  ['wings', 15866],
  ['wingy', 45],
  ['winks', 102],
  ['winos', 31],
  ['winze', 5],
  ['wiped', 738],
  ['wiper', 155],
  ['wipes', 94],
  ['wired', 1538],
  ['wirer', 3],
  ['wires', 1522],
  ['wirra', 45],
  ['wised', 0],
  ['wiser', 186],
  ['wises', 8],
  ['wisha', 2],
  ['wisps', 22],
  ['wispy', 78],
  ['wists', 0],
  ['witan', 26],
  ['witch', 4915],
  ['wited', 0],
  ['wites', 0],
  ['withe', 35],
  ['withy', 79],
  ['witty', 762],
  ['wived', 0],
  ['wiver', 0],
  ['wives', 3645],
  ['wizen', 10],
  ['wizes', 0],
  ['woads', 2],
  ['woald', 0],
  ['wodge', 0],
  ['woful', 0],
  ['woken', 71],
  ['wolds', 371],
  ['wolfs', 48],
  ['woman', 49656],
  ['wombs', 21],
  ['womby', 0],
  ['women', 87576],
  ['womyn', 27],
  ['wonks', 2],
  ['wonky', 40],
  ['wonts', 0],
  ['woods', 12674],
  ['woody', 4659],
  ['wooed', 41],
  ['wooer', 2],
  ['woofs', 3],
  ['wools', 30],
  ['wooly', 154],
  ['woops', 6],
  ['woosh', 6],
  ['woozy', 20],
  ['words', 28624],
  ['wordy', 39],
  ['works', 130340],
  ['world', 595283],
  ['worms', 2817],
  ['wormy', 19],
  ['worry', 882],
  ['worse', 2000],
  ['worst', 7556],
  ['worth', 13511],
  ['worts', 50],
  ['would', 186292],
  ['wound', 3334],
  ['woven', 1450],
  ['wowed', 18],
  ['wrack', 71],
  ['wrang', 7],
  ['wraps', 548],
  ['wrapt', 6],
  ['wrath', 1243],
  ['wreak', 95],
  ['wreck', 2902],
  ['wrens', 189],
  ['wrest', 171],
  ['wrick', 0],
  ['wried', 0],
  ['wrier', 0],
  ['wries', 0],
  ['wring', 27],
  ['wrist', 1165],
  ['write', 14209],
  ['writs', 328],
  ['wrong', 5383],
  ['wrote', 69110],
  ['wroth', 107],
  ['wrung', 11],
  ['wryer', 0],
  ['wryly', 39],
  ['wurst', 80],
  ['wushu', 641],
  ['wussy', 14],
  ['wyled', 0],
  ['wyles', 19],
  ['wynds', 7],
  ['wynns', 25],
  ['wyted', 0],
  ['wytes', 0],
  ['xebec', 110],
  ['xenia', 560],
  ['xenic', 7],
  ['xenon', 402],
  ['xeric', 255],
  ['xerox', 731],
  ['xerus', 24],
  ['xylan', 44],
  ['xylem', 206],
  ['xylol', 5],
  ['xylyl', 9],
  ['xysti', 3],
  ['xysts', 0],
  ['yabby', 28],
  ['yacht', 6450],
  ['yacks', 2],
  ['yaffs', 11],
  ['yager', 147],
  ['yagis', 2],
  ['yahoo', 1966],
  ['yaird', 0],
  ['yamen', 62],
  ['yamun', 4],
  ['yangs', 30],
  ['yanks', 344],
  ['yapok', 2],
  ['yapon', 0],
  ['yards', 15826],
  ['yarer', 0],
  ['yarns', 365],
  ['yauds', 0],
  ['yauld', 0],
  ['yaups', 0],
  ['yawed', 2],
  ['yawey', 0],
  ['yawls', 14],
  ['yawns', 10],
  ['yawps', 0],
  ['yclad', 0],
  ['yeahs', 142],
  ['yeans', 0],
  ['yearn', 30],
  ['years', 374514],
  ['yeast', 2025],
  ['yecch', 0],
  ['yechs', 0],
  ['yechy', 0],
  ['yeggs', 15],
  ['yelks', 0],
  ['yells', 59],
  ['yelps', 10],
  ['yenta', 11],
  ['yente', 8],
  ['yerba', 373],
  ['yerks', 2],
  ['yeses', 3],
  ['yetis', 12],
  ['yetts', 9],
  ['yeuks', 0],
  ['yeuky', 0],
  ['yield', 3855],
  ['yikes', 19],
  ['yills', 0],
  ['yince', 0],
  ['yipes', 6],
  ['yirds', 0],
  ['yirrs', 0],
  ['yirth', 0],
  ['ylems', 0],
  ['yobbo', 12],
  ['yocks', 0],
  ['yodel', 85],
  ['yodhs', 0],
  ['yodle', 2],
  ['yogas', 103],
  ['yogee', 0],
  ['yoghs', 0],
  ['yogic', 113],
  ['yogin', 20],
  ['yogis', 76],
  ['yoked', 42],
  ['yokel', 30],
  ['yokes', 37],
  ['yolks', 110],
  ['yolky', 3],
  ['yomim', 0],
  ['yonic', 4],
  ['yonis', 9],
  ['yores', 0],
  ['young', 99891],
  ['yourn', 0],
  ['yours', 1551],
  ['youse', 14],
  ['youth', 57860],
  ['yowed', 0],
  ['yowes', 7],
  ['yowie', 33],
  ['yowls', 0],
  ['yuans', 9],
  ['yucas', 0],
  ['yucca', 700],
  ['yucch', 0],
  ['yucks', 0],
  ['yucky', 5],
  ['yugas', 17],
  ['yukky', 0],
  ['yulan', 42],
  ['yules', 3],
  ['yummy', 187],
  ['yupon', 0],
  ['yuppy', 6],
  ['yurta', 0],
  ['yurts', 41],
  ['zaire', 999],
  ['zamia', 219],
  ['zanza', 5],
  ['zappy', 6],
  ['zarfs', 2],
  ['zaxes', 0],
  ['zayin', 15],
  ['zazen', 98],
  ['zeals', 16],
  ['zebec', 10],
  ['zebra', 1258],
  ['zebus', 3],
  ['zeins', 0],
  ['zerks', 2],
  ['zeros', 435],
  ['zests', 0],
  ['zesty', 24],
  ['zetas', 192],
  ['zibet', 6],
  ['zilch', 56],
  ['zills', 7],
  ['zincs', 6],
  ['zincy', 0],
  ['zineb', 30],
  ['zines', 176],
  ['zings', 0],
  ['zingy', 6],
  ['zinky', 9],
  ['zippy', 103],
  ['ziram', 2],
  ['zitis', 0],
  ['zizit', 0],
  ['zlote', 5],
  ['zloty', 31],
  ['zoeae', 4],
  ['zoeal', 0],
  ['zoeas', 0],
  ['zombi', 80],
  ['zonae', 4],
  ['zonal', 1007],
  ['zoned', 541],
  ['zoner', 5],
  ['zones', 6296],
  ['zonks', 2],
  ['zooey', 115],
  ['zooid', 46],
  ['zooks', 2],
  ['zooms', 43],
  ['zoons', 0],
  ['zooty', 3],
  ['zoril', 2],
  ['zoris', 0],
  ['zouks', 10],
  ['zowie', 28],
  ['zuzim', 9],
  ['zymes', 0],
]

const WORD_MAP = new Map(WORD_LIST);
