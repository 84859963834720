import React from 'react';
import {Stack} from '@mui/material';
import LetterTile from './LetterTile';
import AppLogo from './AppLogo.js';

export default function Instructions(props) {
  return (
    <Stack direction="column" alignItems="center">

      <AppLogo text="Instructions" />

      <span style={{ height: 16}} />

      <Stack direction="column" textAlign="start">

        You have 6 tries to guess the secret word!

        <span style={{ height: 32}} />

        Type in your guess and then hit ENTER to see what letters you got right.

        <span style={{ height: 32}} />

        <Stack direction="row" alignItems="center">
          <LetterTile
            key={"lt_correct"}
            sample={{
              letter: "G",
              letterClass: 'Letter-correct',
            }} />

            <span style={{ width: 16}} />

            Green means you got the letter in the right spot!
        </Stack>

        <span style={{ height: 32}} />

        <Stack direction="row" alignItems="center">
          <LetterTile
            key={"lt_elsewhere"}
            sample={{
              letter: "Y",
              letterClass: 'Letter-elsewhere',
            }} />

            <span style={{ width: 16}} />

            Yellow means the letter is in the word, but in a different spot.
        </Stack>

        <span style={{ height: 32}} />

        <Stack direction="row" alignItems="center">
          <LetterTile
            key={"lt_incorrect"}
            sample={{
              letter: "R",
              letterClass: 'Letter-incorrect',
            }} />

            <span style={{ width: 16}} />

            Red means the letter is not in the word.
        </Stack>

        <span style={{ height: 24}} />

        <div style={{ height: 2, background: '#938F99'}} />

        <span style={{ height: 24}} />

        Once you finish the game you can share your results,
        make your own secret word, or play a random word.

        <span style={{ height: 16}} />
      </Stack>
    </Stack>
  );
}
